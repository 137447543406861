import './teacher.css';
// import { MdNotifications, MdMessage, MdHelpOutline, MdOndemandVideo, MdBarChart, MdExpandMore, MdSearch } from "react-icons/md";
// import { useSelector } from 'react-redux'
// import { FiTool } from "react-icons/fi";
// import { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { UseSelector, useDispatch } from 'react-redux';
// import { logout } from '../appSlices/authSlice';
// import CourseCard from '../components/CourseCard';
import TeacherTopNavBar from '../components/navbar/TeacherTopNavBar';
import TeacherSideNavBar from '../components/sidebar/TeacherSideNavBar';
import Footer from '../components/footer/Footer';
import { Link,Outlet } from 'react-router-dom';
const TeacherLayout = () => {
    return (

        <div className='ud-main-content-wrapper'>
            <TeacherTopNavBar />

            <div className="ud-main-content" aria-hidden="false">
                <div className="ud-app-loader ud-component--instructor--app ud-app-loaded"
                    data-module-id="instructor">

                    <div className="instructor-page-frame">
                        <TeacherSideNavBar />
                        {/* instructor main content */}
                        <div className="instructor-main-container">
                            <div className="instructor-responsive-container">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default TeacherLayout