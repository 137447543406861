import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import NewPageForm from '../form/page/NewPageForm';

// code refactor later
const CurriculamItemWrapper = ({chapterId}) => {
    const [isCurriculamItemButtonClick, setCurriculamItemButton] = useState(false);
    const [createNewPage, setCreateNewPage] = useState(false);

    const [newPageButtonClick, setNewPageButton] = useState(false);

    const handleNewPageButton = () => {
        setNewPageButton(!newPageButtonClick)
    }
    const handleCreateNewPage = () => {
        setCreateNewPage(!createNewPage)
    }


    const handleCurriculamItemButton = () => { 
        setCurriculamItemButton((prev)=>!prev)
    }
    return (
        <>
            <div className='curriculum-list--inline-insert curriculum-list--inline-insert-non-section-item--W25kH curriculum-list--last-item--KY5qA'>

                <div className={`curriculum-list--inline-insert-button-row ${createNewPage ? 'curriculum-list--open--Vm868' : 'curriculum-list--inline-insert-last-item--KaCks'}`}>

                    <button onClick={handleCreateNewPage} type='button' className={`ud-btn ud-btn-small ud-heading-sm add-item--add-item-toggler-last-item ${createNewPage ? 'ud-btn-ghost add-item--open--NpnAX' : 'ud-btn-secondary'}`} data-role='add-item-inline-last'>
                        <MdAdd className={`ud-icon ud-icon-small add-item--add-item-toggler-last-item add-item--expand-icon--9X-PA ${createNewPage ? "" : "add-item--open--NpnAX"}`} focusable='false' role='img' aria-label='New Curriculam item' />
                        {!createNewPage && <span>Currriculam item</span>}
                    </button>
                </div>

                {createNewPage &&
                    <div className="curriculum-list--inline-insert-form--f-pCL">

                        {!newPageButtonClick &&
                            <div className="add-item-forms--inline-btns-row--bPyUM">
                                <button onClick={handleNewPageButton} type="button" data-role="add-lecture-btn" aria-label="Add Lecture"
                                    className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md">
                                    <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />

                                    <span className="add-item-forms--ellipsis--m6Ho-">page</span>
                                </button>

                                <button disabled type="button" data-role="add-quiz-btn" aria-label="Add Quiz"
                                    className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md">
                                    <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
                                    <span className="add-item-forms--ellipsis--m6Ho-">Quiz</span>
                                </button>

                                {/* <button type="button" data-role="add-coding-exercise-btn" aria-label="Add Coding Exercise"
className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md">
<MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
<span className="add-item-forms--ellipsis--m6Ho-">Coding Exercise</span>
</button> */}

                                {/* <div className="popper-module--popper--mM5Ie">
<button type="button" aria-disabled="true" data-role="add-practice-test-btn"
aria-label="Add Practice Test" id="popper-trigger--40" aria-describedby="popper-content--41"
tabindex="0" className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md ud-btn-disabled">
<MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
<span className="add-item-forms--ellipsis--m6Ho-">Practice Test</span>
</button>
<div id="popper-content--41" className="popper-module--popper-content--XE9z5" style={{
bottom: '100%',
left: '50%', transform: 'translateX(-50%)'
}}>
<div className="popper-module--animation-wrapper--tM0UK">
    <div
        className="popover-module--popover--HrSFz popover-module--popover-top--d0TP1 popover-module--popover-padding--3j0NT">
        <div className="popover-module--inner--yQvGI">Please set a price on your course to create a
            practice
            test.</div>
    </div>
</div>
</div>
</div> */}

                                {/* <button type="button" data-role="add-assignment-btn" aria-label="Add Assignment"
className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md">
<MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
<span className="add-item-forms--ellipsis--m6Ho-">Assignment</span>
</button> */}
                            </div>
                        }
                        {newPageButtonClick && <NewPageForm
                            chapterId={chapterId} 
                            onClose={handleNewPageButton} />
                        }
                    </div>


                }

            </div>

        </>
    )
}

export default CurriculamItemWrapper