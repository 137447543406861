import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import authService from '../apiServices/auth';
const initialState = {
    loading: false,
    currentUser: null,
    error: null,
    success: false,
    userInfo: {},
    userToken: null
}


// async actions definitions for backend communication 
export const loginUserAction = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue }) => {
    try {
        const response = await authService.signin({ email, password });
        return response;

    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message)
        } else {
            return rejectWithValue(error.message)
        }

    }


})


export const registerUserAction = createAsyncThunk('auth/register', async ({ fullname, email, password }, { rejectWithValue }) => {
    try {
        const response = await authService.signup({ fullname, email, password })
        return response;
    } catch (error) {
        return rejectWithValue(error.message)
    }
})

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, { payload }) => {
            state.userInfo = payload

        },
        logout: (state) => {
            state.currentUser = null;
            localStorage.clear()
        }
    },
    extraReducers: (builder) => {
        // login process
        builder.addCase(loginUserAction.pending, (state) => {
            state.loading = true;
            state.error = null;

        })
        builder.addCase(loginUserAction.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.userInfo = payload;
            state.userToken = payload.userToken;
            state.currentUser = payload;
            state.success = true;
        })
        builder.addCase(loginUserAction.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        })


        // registration process



        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(registerUserAction.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;



        })
        builder.addCase(registerUserAction.rejected, (state, { payload }) => {
            state.loading = false;

            state.error = payload
        })
        builder.addCase(registerUserAction.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
    },

})
export const { setCredentials, logout } = authSlice.actions
export default authSlice.reducer;


