import { Link, useLocation, useNavigate } from 'react-router-dom';

import Button from '../Button'
// import { getGoogleOAuthURL } from '../../utils/getGoogleUrl';
import { useDispatch, useSelector } from 'react-redux'
// import { loginUserAction } from '../../appSlices/authSlice';
import { useEffect, useState } from 'react';
// import {FaFacebook, FaApple } from "react-icons/fa";
// import { FcGoogle } from "react-icons/fc";
// import { VscLoading } from "react-icons/vsc";

import useFocus from '../../hooks/useFocus';
import useField from '../../hooks/useField';
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { login } from '../../API/auth';
import axios from '../../API/axios';
import useAuth from '../../hooks/useAuth';

const LoginForm = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth()


  const [errorMessage, setErrorMessage] = useState('')
  const email = useField('email');
  const password = useField('password');
  const [passVisible, setPasswordVisibility] = useState(false);
  const [isEmailInputFocused, handleEmailOnFocus, handleEmailOnBlur] = useFocus(false);
  const [isPasswordInputFocused, handlePasswordOnFocus, handlePasswordOnBlur] = useFocus(false);
  const handleSubmitLoginForm = async (e) => {
    e.preventDefault();

    const credentials = {
      email: email.value, password: password.value
    }
    try {
      const response = await login(credentials);
      setAuth({ accessToken: response?.accessToken });
      navigate('/instructor/courses');


    } catch (error) {
      if (!error?.response) {

        setErrorMessage('No server response')

      }
      else if (error.response?.status === '400') {
        // some message MESSGAE missing username or password
        console.log(error);
        setErrorMessage('missing username or password')


      }
      else if (error.response?.status === '401') {
        // set error message  MESSAGE:'unauthorized';

        setErrorMessage('unAuthorized')
      }
      else {
        setErrorMessage('Login failed!')
      }
      // here possible errors
      // 1.no response from server
      // missing usrnmae or password
      // 3.aunauthorized 
      console.log(error);

    }

    // dispatch(loginUserAction(data))

  }



  return (
    <>

      <div className='ud-app-loader ud-component--auth--app ud-app-loaded'>



        <div className="ud-container helpers--auth-container">
          {errorMessage}
          <h2 id="auth-to-udemy-title" className="lp-heading-md helpers--auth-title---6vwg">Log in to your ilm account</h2>
          {/* <button type="button" className="lp-btn lp-btn-large lp-btn-secondary lp-heading-md lp-btn-disabled auth-social--social-btn"
            data-role="google-login" disabled="" tabIndex="-1">
            <FcGoogle aria-hidden="true" focusable="false" className="lp-icon lp-icon-large auth-social--social-icon--9qrwp" />
            <span>Continue with Google</span>
            <VscLoading aria-label="Loading" role="img" focusable="false" className="lp-icon lp-icon-medium ud-loader" />
          </button>
          <button type="button" className="lp-btn lp-btn-large lp-btn-secondary lp-heading-md auth-social--social-btn"
            data-role="facebook-login">
            <FaFacebook aria-hidden='true' focusable='false' className="lp-icon lp-icon-large auth-social--social-icon--9qrwp auth-social--facebook-icon--jo5Vb" />

            <span>Continue with Facebook</span>
          </button>
          <button type="button" className="lp-btn lp-btn-large lp-btn-secondary lp-heading-md auth-social--social-btn"
            data-role="apple-login">
            <FaApple aria-hidden="true" focusable="false" className="lp-icon lp-icon-large auth-social--social-icon--9qrwp" />

            <span>Continue with Apple</span>
          </button> */}
          <form data-disable-loader="true" onSubmit={handleSubmitLoginForm}>
            <input type="hidden" name="locale" value="en_US" />
            <div className="helpers--auth-form-row">
              <div>
                <div className="lp-compact-form-group lp-form-group">

                  <div data-testid="lp-compact-form-control-container"
                    className={`lp-compact-form-control-container ${isEmailInputFocused ? '' : 'lp-compact-form-control-container-active lp-compact-form-control-container-focus'}`}
                  >

                    <input aria-invalid="false"
                      name="email"
                      minLength="7"
                      maxLength="64"
                      required=""
                      id="form-group--1"
                      type="email"
                      onFocus={handleEmailOnFocus}
                      onBlur={handleEmailOnBlur}
                      className="lp-text-input lp-text-input-large lp-text-md lp-compact-form-control"
                      value={email.value}
                      onChange={email.onChange}
                    // {...register('email')}
                    />

                    <label htmlFor="form-group--1" className="lp-form-label lp-heading-sm">
                      <span className="lp-compact-form-label-content">
                        <span className="lp-compact-form-label-text">Email</span>
                      </span>
                    </label>

                  </div>
                </div>
              </div>
            </div>


            <div className="helpers--auth-form-row">
              <div className="lp-compact-form-group lp-form-group">

                <div data-testid="lp-compact-form-control-container"
                  className={`lp-compact-form-control-container ${isPasswordInputFocused ? '' : 'lp-compact-form-control-container-active lp-compact-form-control-container-focus'}`}

                >
                  <input
                    onFocus={handlePasswordOnFocus}
                    onBlur={handlePasswordOnBlur}

                    aria-invalid="false" minLength="5" maxLength="64" name="password" required=""
                    id="form-group--3"
                    type={passVisible ? 'text' : 'password'}
                    className="ud-text-input ud-text-input-large ud-text-md ud-compact-form-control password-form-group--password-input--yB71S "
                    value={password.value}
                    onChange={password.onChange}

                  />
                  {password.length > 0 ? <Button type='button' onClick={() => setPasswordVisibility(!passVisible)} classname='lp-btn lp-btn-large lp-btn-link lp-heading-md password-form-group--icon--tZsyr'>
                    {passVisible ? <FaEyeSlash className='lp-icon lp-icon-small lp-icon-color-neutral' /> : <FaEye className='lp-icon lp-icon-small lp-icon-color-neutral' />}
                  </Button> : ''}

                  <label htmlFor="form-group--3" className="lp-form-label lp-heading-sm">
                    <span className="lp-compact-form-label-content">
                      <span className="lp-compact-form-label-text">Password</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>



            <button type="submit"
              className="lp-btn lp-btn-large lp-btn-brand lp-heading-md helpers--auth-submit-button">
              <span>Log in</span>
            </button>
          </form>
          <div className="helpers--auth-footer">
            <div className="lp-text-md">
              <span> or
                <Link className="lp-text-bold lp-link-underline" to="/forgot-password">Forgot Password</Link>
              </span>
            </div>
            <div className="helpers--auth-separator--oswiI"></div>
            <div className="ud-text-sm">
              <span>Don't have an account?
                <Link className="ud-text-bold ud-link-underline"
                  to="/join/signup-popup/?locale=en_US&amp;next=https%3A%2F%2Fwww.udemy.com%2Flogout%2F&amp;response_type=html&amp;skip_suggest=1">Sign
                  up</Link>
              </span>
            </div>
            <div className="lp-text-sm">
              <Link className="ud-text-bold ud-link-underline" to="/organization/global-login/"
                data-disable-loader="true">Log in with your organization</Link>
            </div>
          </div>
        </div>




      </div>


      {/* <div className=''>
        <div className=''>
          <img className='' src={ilm} alt='logo' />
        </div>
        <div className=''>
          <div className=''>
            <form onSubmit={handleSubmit(submitLoginForm)}>
              <p className=''>Login with learningtool</p>

              <input
                {...register('email')}
                type="email"
                placeholder="email"
                className=''
              />
              <input
                {...register('password')}
                type="password"
                placeholder="password"
                className=''
              />
              <button type="submit" className=''>Login</button>
              <Link to='/forgetpassword'>forget password</Link>
              <div style={{ textAlign: 'center' }} className='' >
                OR
              </div>
              <div className=''>
                <Link to={getGoogleOAuthURL}>
                  <img height={84} src={google} alt='google-logo' />
                </Link>

              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  )
}


export default LoginForm