
import SubHeader from '../../SubHeader'
const Promotions = () => {
  return (
    <div>
      <SubHeader pageTitle='Promotions' />
      <div className='main-content--content--1Myl4'></div>
    </div>
  )
}

export default Promotions