import { useState } from "react";
import { MdOutlineTextFormat, MdClose, MdAudioFile, MdImage } from "react-icons/md";
import { FaPlayCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import MediaContent from "./MediaContent";
const ContentSelectorHeader = () => {

}
const ContentOptions = ({ handleContentSelection }) => {
    const contentTypes = [
        { type: 'video', label: 'Video', icon: <FaPlayCircle aria-hidden="true" focusable="false" className="ud-icon ud-icon-large" /> },
        { type: 'text', label: 'Text only', icon: <MdOutlineTextFormat aria-hidden="true" focusable="false" className="ud-icon ud-icon-large" /> },
        { type: 'image', label: 'Image only', icon: <MdImage aria-hidden="true" focusable="false" className="ud-icon ud-icon-large" /> },
        { type: 'audio', label: 'Audio only', icon: <MdAudioFile aria-hidden="true" focusable="false" className="ud-icon ud-icon-large" /> },
        { type: 'imageAndAudio', label: 'Image & Audio', icon: <MdAudioFile aria-hidden="true" focusable="false" className="ud-icon ud-icon-large" /> },
        { type: 'imageAndAudioAndText', label: 'Image Audio Text', icon: <MdImage aria-hidden="true" focusable="false" className="ud-icon ud-icon-large" /> }
    ];
    return (
        <div>
            <p className="lp-text-sm lecture-editor--select-content-type--1BK9j">Select the main type of content. Files and links can be added as resources.
                <Link className="ud-link-underline" to="https://www.ilm.com/support/229606188/" target="_blank" rel="noreferrer noopener">Learn about content types.</Link>
            </p>
            <ul className="ud-unstyled-list content-type-selector--list--jesAx">
                {contentTypes.map(({ type, label, icon }) => (
                    <li key={type}>
                        <button onClick={() => handleContentSelection(type)} type="button" data-role="select-video" className="ud-btn ud-btn-large ud-btn-link ud-heading-md content-type-selector--asset">
                            <span className="content-type-selector--box">
                                <div className="content-type-selector--icon content-type-selector--icon-before">
                                    {/* <FaPlayCircle aria-hidden="true" focusable="false" className="ud-icon ud-icon-large" /> */}
                                    {icon}
                                </div>
                                <div className="content-type-selector--icon content-type-selector--icon-after">
                                    {/* <FaPlayCircle aria-hidden="true" focusable="false" className="ud-icon ud-icon-large" /> */}
                                    {icon}
                                </div>

                                <span className="ud-text-xs content-type-selector--label">{label}</span>
                            </span>
                        </button>
                    </li>
                ))}
            </ul>

        </div>
    )
}




const ContentSelector = ({item, handleContentTabClose, isContentTabOpen }) => {

    // here get asset data from backend 


    const [selectedContentType, setSelectedContentType] = useState(null);
    const handleContentSelection = (type) => {
        setSelectedContentType(type)

    }
    return (
        <div data-role="add-content-wrapper" className={`${isContentTabOpen ? 'default-item-editor--add-content--1wZ-X' : 'default-item-editor--hidden--ta67x'} `}>

            <div className="content-tab--tab-container--1KRkM" data-role="add-content">
                <div className="ud-heading-sm content-tab--tab--1KHTI">
                    <div className="content-tab--title--1WltY">
                        {selectedContentType === null ? 'Select Content Type' : selectedContentType}
                        <button onClick={() => { handleContentTabClose(); setSelectedContentType(null) }} type="button" data-role="content-tab-close" className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall ud-link-neutral content-tab--close--3QxOF">
                            <MdClose aria-label="Close" role="img" focusable="false" className="lp-icon lp-icon-small" />
                        </button>
                    </div>
                </div>

                <div className="content-tab--tab-content--3l4Vv">
                    {/* dynamic ui for video/audio/text content */}
                    {selectedContentType && <MediaContent item={item} contentType={selectedContentType} />}

                    {/* initial UI when content button click */}
                    {selectedContentType === null && <ContentOptions handleContentSelection={handleContentSelection} />}


                </div>
            </div>
        </div>

    )
}

export default ContentSelector