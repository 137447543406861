import React, { useState } from 'react'
import { FaCheckCircle, FaPlayCircle } from "react-icons/fa";
import { MdAdd, MdOutlineFormatItalic, MdFormatBold, MdFormatListNumbered, MdFormatListBulleted, MdLink, MdCode, MdDelete, MdEdit, MdMenu, MdNote, MdClose, MdSearch, MdImage, MdOutlineTextFormat, MdAudioFile, MdPublish } from "react-icons/md";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import FileSelector from './FileSelector';
import TextEditor from './TextEditor';
import MediaContent from './MediaContent';
import MultiPurposeMediaContent from './MultiPurposeMediaContent';
import NewChapterForm from '../form/chapter/NewChapterForm';
import NewPageForm from '../form/page/NewPageForm';
import EditPageForm from '../form/page/EditPageForm';
import ContentSelector from './ContentSelector';
import EditPageContent from './EditPageContent';
import useToggle from '../../hooks/useToggle';
const Page = ({ item }) => {
    console.log('the item in page :', item.asset)

    const [isPageExpended, setPageExpended] = useState(false);
    

    const [isPageCollapse, setPageCollapse] = useToggle(false);
    console.log('the value of isPageExpended', isPageExpended);



    // on content button click this state handle that 
    const [isContentTabOpen, setContentTab] = useState(false);

    const [toggle, setToggle] = useState(false);
    const [isPageEdit, setPageEdit] = useState(false);
    const [createNewPage, setCreateNewPage] = useState(false);
    const [newPageButtonClick, setNewPageButton] = useState(false);


    // these are clickers for content button its clsoing ui
    const handleContentTabOpen = () => {
        setContentTab(!isContentTabOpen)
    }
    const handleContentTabClose = () => {

        setContentTab(!isContentTabOpen)

    }
    const handleNewPageButton = () => {
        setNewPageButton(!newPageButtonClick)
    }
    const handleCreateNewPage = () => {
        setCreateNewPage(!createNewPage)
    }
    const handlePageEdit = () => {
        setPageEdit(!isPageEdit)
    }
    const handleToggle = () => {
        setToggle(!toggle);
    }
    // STATE FOR SELECTING CONTENT TYPE
    const [selectedContentType, setSelectedContentType] = useState(null);
    const handlePageCollapse = () => {
        setPageExpended((isPageExpended) => !isPageExpended)
    }
    const handleContent = () => {
        setContentTab(!isContentTabOpen)
    }
    const handleContentSelection = (contentType) => {
        console.log('the content type is :', contentType);
        setSelectedContentType(contentType);

    }
    return (
        <>
            <div id={`${item._class}${item.id}`} className="js-curriculum-item-draggable curriculum-list--list-item curriculum-list--non-section-item">
                <div data-role="lecture-editor" className="default-item-editor--item-editor--3GhNq">
                    {isPageEdit && <EditPageForm onClose={() => setPageEdit(!isPageEdit)} />}
                    <div className={isPageEdit ? "default-item-editor--hidden" : ""}>



                        <div className="popper-module--popper--2BpLn">
                            <div className="item-icon-button-trigger js-curriculum-item-handle item-bar--item-bar--1qWKU item-bar--row--3ZgKF" id="popper-trigger--32"
                                aria-describedby="popper-content--33"
                                tabIndex="0">
                                {/* content */}
                                <div className="item-bar--flex--AcNdN item-bar--main--3LgFa item-bar--row--3ZgKF">
                                    <div className="item-bar--flex--AcNdN item-bar--left--6blR1">
                                        <div className="item-bar--left-content--af69I item-bar--row--3ZgKF" data-role="item-full-title">

                                            <FaCheckCircle aria-hidden="true" focusable="false" data-role="published-icon" className='lp-icon lp-icon-xsmall lp-icon-color-neutral item-bar--icon--1R2Ix' />
                                            <span className="item-bar--label--1yiC3" data-role="item-object-index">Page {item.object_index}:</span>
                                            <span className="item-bar--title-desktop--305o_ item-bar--row--3ZgKF">

                                                <FaPlayCircle aria-hidden="true" focusable="false" data-role="video-icon" className='lp-icon lp-icon-xsmall lp-icon-color-neutral item-bar--icon--1R2Ix ' />
                                                <span className="item-bar--ellipsis--Lbep0">{item.title}</span>
                                            </span>

                                            <button onClick={handlePageEdit} type="button" data-role="lecture-edit-btn" className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall ud-link-neutral item-icon-button--icon-button--1IKG6">

                                                <MdEdit className='lp-icon lp-icon-xsmall lp-icon-color-neutral' />
                                            </button>
                                            <button type="button" data-role="page-delete-btn" className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall ud-link-neutral item-icon-button--icon-button--1IKG6">

                                                <MdDelete aria-label="Delete" role="img" focusable="false" className='lp-icon lp-icon-xsmall lp-icon-color-neutral' />
                                            </button>
                                        </div>

                                        <div className="item-bar--title-mobile--2qLqw item-bar--left-content--af69I item-bar--row--3ZgKF">

                                            <FaPlayCircle className='lp-icon lp-icon-xsmall lp-icon-color-neutral' />
                                            <span className="item-bar--ellipsis--Lbep0">Introduction</span>
                                        </div>


                                    </div>

                                    {/* content button */}
                                    {item.asset===null && <div className="item-bar--button-container--11FBT">
                                        <button onClick={handleContent} type="button" data-role="page-add-content-btn" aria-label="Add Content" className="ud-btn ud-btn-small ud-btn-secondary ud-heading-sm">

                                            <MdAdd aria-hidden='true' focusable='false' className='lp-icon lp-icon-small' />
                                            <span>Content</span>
                                        </button>
                                    </div>}



                                </div>


                                {/* collapser */}
                                <div className="item-bar--right--4qb_w item-bar--row--3ZgKF">
                                    <button onClick={handlePageCollapse} type="button" data-role="page-collapse-btn" className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall ud-link-neutral item-icon-button--icon-button--1IKG6 item-icon-button--icon-button-always-show--2-12f">
                                        {isPageExpended ?
                                            <FaAngleUp focusable='false' role='img' className='lp-icon lp-icon-medium' aria-label={isPageExpended ? 'Collapse' : 'Expand'} />
                                            :
                                            <FaAngleDown focusable='false' role='img' className='lp-icon lp-icon-medium' aria-label={isPageExpended ? 'Collapse' : 'Expand'} />

                                        }
                                    </button>

                                    <div className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall item-bar--drag-handle--2q7kQ ud-link-neutral item-icon-button--icon-button--1IKG6">

                                        <MdMenu aria-label='Move' role='img' focusable='false' className='lp-icon lp-icon-small' />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    {/* add content wrapper */}
                    <ContentSelector item={item} handleContentTabClose={handleContentTabClose} isContentTabOpen={isContentTabOpen} />

                    <EditPageContent item={item} isPageExpended={isPageExpended} />
                </div>

                {/* new curiiculam basically for page new addition  */}
                <div className='curriculum-list--inline-insert curriculum-list--inline-insert-non-section-item--W25kH curriculum-list--last-item--KY5qA'>

                    <div className={`curriculum-list--inline-insert-button-row ${createNewPage ? 'curriculum-list--open--Vm868' : 'curriculum-list--inline-insert-last-item--KaCks'}`}>

                        <button onClick={handleCreateNewPage} type='button' className={`ud-btn ud-btn-small ud-heading-sm add-item--add-item-toggler-last-item ${createNewPage ? 'ud-btn-ghost add-item--open--NpnAX' : 'ud-btn-secondary'}`} data-role='add-item-inline-last'>
                            <MdAdd className={`ud-icon ud-icon-small add-item--add-item-toggler-last-item add-item--expand-icon--9X-PA ${createNewPage ? "" : "add-item--open--NpnAX"}`} focusable='false' role='img' aria-label='New Curriculam item' />
                            {!createNewPage && <span>Currriculam item</span>}
                        </button>
                    </div>

                    {createNewPage &&
                        <div className="curriculum-list--inline-insert-form--f-pCL">

                            {!newPageButtonClick &&
                                <div className="add-item-forms--inline-btns-row--bPyUM">
                                    <button onClick={handleNewPageButton} type="button" data-role="add-lecture-btn" aria-label="Add Lecture"
                                        className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md">
                                        <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />

                                        <span className="add-item-forms--ellipsis--m6Ho-">page</span>
                                    </button>

                                    <button disabled type="button" data-role="add-quiz-btn" aria-label="Add Quiz"
                                        className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md">
                                        <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
                                        <span className="add-item-forms--ellipsis--m6Ho-">Quiz</span>
                                    </button>

                                    {/* <button type="button" data-role="add-coding-exercise-btn" aria-label="Add Coding Exercise"
                className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md">
                <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
                <span className="add-item-forms--ellipsis--m6Ho-">Coding Exercise</span>
            </button> */}

                                    {/* <div className="popper-module--popper--mM5Ie">
                <button type="button" aria-disabled="true" data-role="add-practice-test-btn"
                    aria-label="Add Practice Test" id="popper-trigger--40" aria-describedby="popper-content--41"
                    tabindex="0" className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md ud-btn-disabled">
                    <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
                    <span className="add-item-forms--ellipsis--m6Ho-">Practice Test</span>
                </button>
                <div id="popper-content--41" className="popper-module--popper-content--XE9z5" style={{
                    bottom: '100%',
                    left: '50%', transform: 'translateX(-50%)'
                }}>
                    <div className="popper-module--animation-wrapper--tM0UK">
                        <div
                            className="popover-module--popover--HrSFz popover-module--popover-top--d0TP1 popover-module--popover-padding--3j0NT">
                            <div className="popover-module--inner--yQvGI">Please set a price on your course to create a
                                practice
                                test.</div>
                        </div>
                    </div>
                </div>
            </div> */}

                                    {/* <button type="button" data-role="add-assignment-btn" aria-label="Add Assignment"
                className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-md">
                <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
                <span className="add-item-forms--ellipsis--m6Ho-">Assignment</span>
            </button> */}
                                </div>
                            }
                            {newPageButtonClick && <NewPageForm chapterId={item.chapterId} onClose={handleNewPageButton} />
                            }
                        </div>


                    }

                </div>

                {/* new section or chapter addition button  only on last page iteem */}
                <div className="curriculum-list--inline-insert curriculum-list--inline-insert-section">
                    <div className="curriculum-list--inline-insert-button-row">
                        <button onClick={handleToggle} type="button"
                            data-role="add-item-inline-last"
                            className={`ud-btn ud-btn-small ud-heading-sm add-item--add-item-toggler-last-item add-item--section--J21gT ${toggle ? 'ud-btn-ghost add-item--open--NpnAX' : 'ud-btn-secondary'}`}>
                            <MdAdd aria-label="New curriculum item" role="img" focusable="false" className={`ud-icon ud-icon-small add-item--add-item-toggler-last-item add-item--expand-icon--9X-PA add-item--section--J21gT ${toggle ? '' : 'add-item--open--NpnAX'}`} />
                            {!toggle && <span>Chapter</span>}
                        </button>
                    </div>
                    {/* new section created will appear here */}
                    {toggle && <NewChapterForm onClose={handleToggle} />}
                </div>
            </div>

        </>
    )
}



export default Page