import { useEffect, useState } from 'react';
import FileSelector from './FileSelector';
import { useForm } from 'react-hook-form'
import { MdDelete, MdSearch } from 'react-icons/md';
import { UploadTemplate } from '..';
const MediaContent = ({ item,contentType }) => {
    // Initial active tab index
    const [activeTabIndex, setActiveTabIndex] = useState(0);


    const { register, handleSubmit, watch, formState: { errors } } = useForm();


    const searchTerm = watch('searchTerm', ''); // Access search term value

    const handleClickTabIndex = (index) => {
        console.log('add library clicked');
        setActiveTabIndex(index);
    }

    useEffect(() => { 

    }, [])

    return (
        <div className="lecture-editor--tabs-container--Ritzv">




            <div className="tabs-module--tabs-container---clC6">


                <div
                    className="ud-tabs-nav-buttons tabs-module--tabs-nav-buttons--R48bp"
                    role="tablist"
                    data-purpose="tab-nav-buttons">

                    <section aria-label="Tab Navigation" className="carousel-module--container--l71Ek">

                        <div className="carousel-module--scroll-port--ViaiR carousel-module--grid--nU76k carousel-module--scroll-lock--UdXQa"
                            data-purpose="scroll-port" id="scroll-port--265" aria-live="off">
                            <div data-index="0" className="carousel-module--scroll-item--QZoY7">
                                <div
                                    className={`ud-nav-button-container tabs-module--nav-button-container--UQiPm ${activeTabIndex === 0 ? 'ud-nav-button-container-active tabs-module--active--WZUDQ' : ''}`}>
                                    <button
                                        onClick={() => handleClickTabIndex(0)}
                                        type="button" id="tabs--264-tab-0"
                                        aria-selected={activeTabIndex === 0}
                                        role="tab"
                                        className={`ud-btn ud-btn-large ud-btn-ghost ud-heading-md ud-nav-button tabs-module--nav-button--DtB8V
                                        ${activeTabIndex === 0 ? 'ud-nav-button-active tabs-module--active--WZUDQ' : ''}
                                         `}
                                        tabIndex={activeTabIndex === 0 ? 0 : -1}>
                                        <span>Upload {contentType}</span>
                                    </button>
                                </div>
                            </div>
                            <div data-index="1" className="carousel-module--scroll-item--QZoY7">
                                <div className={`ud-nav-button-container tabs-module--nav-button-container--UQiPm ${activeTabIndex === 1 ? 'ud-nav-button-container-active tabs-module--active--WZUDQ' : ''} `}>
                                    <button onClick={() => handleClickTabIndex(1)}

                                        type="button" id="tabs--264-tab-1" aria-selected={activeTabIndex === 1} role="tab"
                                        className={`ud-btn ud-btn-large ud-btn-ghost ud-heading-md ud-nav-button tabs-module--nav-button--DtB8V ${activeTabIndex === 1 ? 'ud-nav-button-active tabs-module--active--WZUDQ' : ''}`}
                                        tabIndex={activeTabIndex === 1 ? 0 : -1}>
                                        <span>Add from library</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* tabs */}
                <div id="tabs--264-content-0" role="tabpanel"
                    className={`ud-tab-content tabs-module--tab-content--y-r7m ${activeTabIndex === 0 ? 'tabs-module--active--WZUDQ' : ''}`}
                    tabIndex={activeTabIndex === 0 ? 0 : -1}
                    data-purpose="tab-container" aria-labelledby="tabs--264-tab-0">
                    <div className='border-2 border-red-800'>
                        <FileSelector item={item} contentType={contentType} />
                        <UploadTemplate/>
                    </div>
                </div>


                <div id="tabs--264-content-1" role="tabpanel"
                    className={`ud-tab-content tabs-module--tab-content--y-r7m ${activeTabIndex === 1 ? 'tabs-module--active--WZUDQ' : ''}`}
                    tabIndex={activeTabIndex === 1 ? 0 : -1}
                    data-purpose="tab-container" aria-labelledby="tabs--264-tab-1">
                    <div>
                        <div className="asset-library--search-row--LzlsE">
                            <div className="ud-form-group">
                                <label className="ud-sr-only ud-form-label ud-heading-sm"
                                    htmlFor="form-group--268">Search files by
                                    name</label>
                                <form onSubmit={handleSubmit(() => console.log('the'))}
                                    className="text-input-form-module--text-input-form--tITHD">
                                    <input
                                        {...register('searchTerm')}
                                        aria-invalid="false"
                                        placeholder="Search files by name"
                                        id="form-group--268"
                                        type="text"
                                        className="ud-text-input ud-text-input-small ud-text-sm"
                                        value={searchTerm} />

                                    <button type="submit" className="ud-btn ud-btn-small ud-btn-primary ud-heading-sm">
                                        <MdSearch aria-label="Submit search" role="img" focusable="false"
                                            className="ud-icon ud-icon-small" />

                                    </button>
                                </form>



                            </div>
                        </div>

                        <div className="asset-table--asset-table-container--PnNQv" data-purpose="asset-table">
                            <div role="section" aria-labelledby="table--270-caption"
                                className="table-module--container--Xs9D2 table-module--padding-xs--heh0Z table-module--no-border--H0Vps table-module--no-background-color--leOkN"
                                tabIndex="0">
                                <table>
                                    <caption className="ud-sr-only" id="table--270-caption">Library Column headers with buttons
                                        are sortable.</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" data-initial-sort="ascending">
                                                <span id="table--270-header-0"
                                                    className="table-module--sortable-header-text--j2UYR">Filename<button
                                                        type="button" aria-labelledby="table--270-header-0"
                                                        className="ud-btn ud-btn-large ud-btn-link ud-heading-md ud-link-neutral table-module--sortable-header-icon--TapDf">
                                                        <MdDelete aria-hidden="true" focusable="false"
                                                            className="ud-icon ud-icon-small" />

                                                    </button>
                                                </span>
                                            </th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" aria-sort="descending" data-initial-sort="descending"><span
                                                id="table--270-header-3"
                                                className="table-module--sortable-header-text--j2UYR">Date<button
                                                    type="button" aria-labelledby="table--270-header-3"
                                                    className="ud-btn ud-btn-large ud-btn-link ud-heading-md ud-link-neutral table-module--sortable-header-icon--TapDf">
                                                    <MdDelete aria-hidden="true" focusable="false"
                                                        className="ud-icon ud-icon-small" />

                                                </button>
                                            </span>
                                            </th>
                                            <th scope="col">
                                                <span className="ud-sr-only">Actions</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-sortable="true">tst.mp4</td>
                                            <td>Video</td>
                                            <td>
                                                <div className="asset-table--asset-status--tWydu asset-status--positive--QP62q">
                                                    Success</div>
                                            </td>
                                            <td data-sortable="true">02/22/2024</td>
                                            <td>
                                                <div className="asset-table--action-buttons--4w2Jp"><button type="button"
                                                    data-purpose="select-asset-button"
                                                    className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm">
                                                    <span>Select</span>
                                                </button>
                                                    <button type="button" data-purpose="delete-asset-button"
                                                        className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall">
                                                        <MdDelete aria-label="Delete" role="img" focusable="false"
                                                            className="ud-icon ud-icon-xsmall" />

                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-sortable="true">Screencast 2023-10-16 15:16:07.mp4</td>
                                            <td>Video</td>
                                            <td>
                                                <div className="asset-table--asset-status--tWydu asset-status--positive--QP62q">
                                                    Success</div>
                                            </td>
                                            <td data-sortable="true">11/23/2023</td>
                                            <td>
                                                <div className="asset-table--action-buttons--4w2Jp"><button type="button"
                                                    data-purpose="select-asset-button"
                                                    className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm">
                                                    <span>Select</span>
                                                </button>
                                                    <button type="button" data-purpose="delete-asset-button"
                                                        className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall">
                                                        <MdDelete
                                                            aria-label="Delete" role="img" focusable="false"
                                                            className="ud-icon ud-icon-xsmall" />

                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MediaContent;