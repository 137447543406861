import axios from "./axios";
import { axiosPrivate } from "./axios";


const createCourse = async (courseData,acc) => {
    console.log(courseData)
    console.log(acc)
    
    const response = await axios.post(`/courses`,courseData,{
        headers:{
            'Content-Type':'application/json',
            'authorization':`Bearer ${acc}`
        }
    });
    console.log('the data from server is :',response.data)
    return response.data;


}



const getAllCourse=async(options)=>{
    const response=await axiosPrivate.get('http://localhost:3001/api/users/me/taught-courses',options);
    console.log(response.data.course)
    return response.data.courses
}


const getCourseById=async(id)=>{
    const response=await axios.get(`http:localhost:3001/api/courses/${id}?fields[course]=content_length_video,is_paid,price_updated_date,can_toggle_curriculum_published_state`);
    return response.data;

}








const courseAPI={
    getAllCourse,createCourse
}

export default courseAPI;