import { useState } from "react"
import useAuth from "../../hooks/useAuth";
import { createAssetByUserAction } from "../../appSlices/assetSlice";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { addAssetToPageInCourseCurriculam } from "../../appSlices/courseSlice";
const FileSelector = ({ item, contentType }) => {
    const courseId = useSelector((state) => state.course.selectedCourse._id);
    const pageId = item.id
    const newAsset = useSelector((state) => state.assets.newAsset);
    console.log(courseId, pageId, newAsset)

    const dispatch = useDispatch();
    const { auth } = useAuth();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const files = e.target.files;
        console.log('the files are:', files)
        // const file = e.target.files[0];
        // setSelectedFile(file);
        const assetData = new FormData();
        for (let i = 0; i < files.length; i++) {
            assetData.append('files', files[i]); // Append each file individually
          }
     
        // assetData.append('file', files)
        dispatch(createAssetByUserAction({assetData,type:contentType,accessToken:auth.accessToken}))
    }
    useEffect(() => {

        if (newAsset!==null&& courseId!==undefined && pageId!==undefined) {
            const assetData={
                pageId,
                id:newAsset._id
            }
            console.log('the asset id is:',assetData.id)
            dispatch(addAssetToPageInCourseCurriculam({assetData,accessToken:auth.accessToken,courseId}))

        }



    }, [newAsset])


    return (
        <div>
            <div className='ud-form-group'>
                <label className='ud-sr-only ud-form-label ud-heading-sm' >select {contentType}</label>
                <div className="file-uploader-module--file-uploader-large--yFMua" data-purpose="asset-uploader-input">
                    <input onChange={handleFileChange} name="asset"
                        // accept=".avi,.mpg,.mpeg,.flv,.mov,.m2v,.m4v,.mp4,.rm,.ram,.vob,.ogv,.webm,.wmv"
                        id="form-group--77"
                        type="file"
                        className="ud-sr-only" multiple/>

                    <label htmlFor="form-group--77" className="file-uploader-module--input-group--yMJlp file-uploader-module--uploader-label--88Rvq">
                        <span className="file-uploader-module--fake-input--YBzeo">
                            <span className="file-uploader-module--fake-input-text--fCkyE">No file selected</span>
                        </span>
                        <span className="ud-btn ud-btn-large ud-btn-secondary ud-heading-md file-uploader-module--btn--dLFMW">
                            <span>Select {contentType}</span>
                        </span>
                    </label>
                </div>

                <div className='ud-form-note ud-text-xs'>
                    <div>
                        <b>Note</b> : All files should be at least 720p and less than 4.0 GB.
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FileSelector