import FullPageHeader from "../components/courseManager/FullPageHeader";
import Sidebar from '../components/courseManager/sidebar/Sidebar'
import Footer from "../components/footer/Footer";
import { Outlet } from "react-router-dom";
import './teacher.css'
const CourseManagerLayout = () => {

    return (
        <div className="ud-main-content-wrapper">

            <div className="ud-main-content">
                <div id='course-manage-v2' className="ud-app-loader ud-component--course-manage-v2--app ud-app-loaded">

                    <FullPageHeader />


                    
                    <div className='ud-container app--container--7qJMh'>

                        <Sidebar />
                        
                        <div className='app--content--3vcMt'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default CourseManagerLayout