import React, { useEffect, useState } from 'react';

import EditPageContentBody from './EditPageContentBody';
import EditPageContentHeader from './EditPageContentHeader';
import EditPageContentFooter from './EditPageContentFooter';




const EditPageContent = ({ item, isPageExpended }) => {
    const [isPreviewClick, setPreviewClick] = useState(false);

    const handlePrevieButton = () => {
        setPreviewClick(!isPreviewClick)
    }
    const { pageId } = item
    useEffect(() => {
        // here api call lets do this home work
        const url = `/users/me/taught-courses/courseId/pages/${pageId}/`
    }, []);





    return (
        <div data-role="edit-content-wrapper" className={`default-item-editor--edit-content--HLXOq ${isPageExpended ? '' : 'default-item-editor--hidden--ta67x'} `}>
            <div data-role="edit-content">
                {item.asset === null ? <>no asset add here</> : <EditPageContentHeader onPreviewClick={handlePrevieButton} item={item} />}
                {isPreviewClick && <EditPageContentFooter type={'combo'} />}
                <EditPageContentBody />

            </div>

        </div>
    )
}

export default EditPageContent