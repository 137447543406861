import React from 'react'
import { Vortex } from 'react-loader-spinner'
const Loader = () => {
    const loaderStyle={
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding:'2.5rem'

    }
    return (
        <div  style={loaderStyle}>
            <Vortex
                visible={true}
                height="80"
                width="80"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
            />

        </div>
    )
}

export default Loader