import React from 'react';
import { MdEdit } from 'react-icons/md';
import { FaArrowDown } from 'react-icons/fa';
import { FaSun } from "react-icons/fa";
import { Link } from 'react-router-dom';
const EditPageContentHeader = (props) => {
    console.log('asset details are in EditPageContentHeader ,',props.item.asset)
    return (
        <div className="lecture-editor--edit-content-header--DoEeI lecture-editor--edit-content-row--Is-09">

            <div className="lecture-editor--edit-content-header-left--NaDNB">
                <div data-role="asset-info">
                    <div className="selected-asset-cards--asset-card--Vb-LN" data-role="selected-asset">

                        <div className="selected-asset-cards--thumbnail-container--LlyWT"
                            style={{ backgroundImage: `url('http://localhost:3001/uploads/users/65f022852d4d000bde2f0f3f/image/ilm.png')` }}

                            
                            >
                        </div>

                        <div className="selected-asset-cards--flex--qvKkT">
                            <div className="ud-heading-md selected-asset-cards--ellipsis--8Lbve">tst.mp4</div>
                            <time className="selected-asset-cards--content-summary--ZF4Ni">00:38</time>
                            <div>
                                <button type="button" data-role="edit-content-link"
                                    className="ud-btn ud-btn-large ud-btn-link ud-text-md">
                                    <span>
                                        <MdEdit aria-hidden="true" focusable="false"
                                            className="ud-icon ud-icon-small selected-asset-cards--btn-icon--MHnNp"/>
                                        Edit Content
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="lecture-editor--lecture-settings--I5Ylg" data-role="lecture-settings">
                <div className="lecture-editor--lecture-settings-btns--uRgL0">
                    <div className="popper-module--popper--mM5Ie">

                        <button onClick={props.onPreviewClick} type="button" id="dropdown-trigger--34" aria-expanded="false" tabIndex="0"
                            className="ud-btn ud-btn-small ud-btn-primary ud-heading-sm">
                            <span>Preview</span>
                            <FaArrowDown aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall"/>
                            
                        </button>

                        <div id="popper-content--36" aria-labelledby="dropdown-trigger--34"
                            className="popper-module--popper-content--XE9z5"
                            //  style="top: 100%; width: 20rem; right: 0px;"
                            >
                            <div className="popper-module--animation-wrapper--tM0UK">
                                <div className="dropdown-module--menu---dCM1 dropdown-module--dropdown-menu--7Qc2I"
                                    // style="max-height: 28rem;"
                                    >
                                    <ul className="ud-unstyled-list ud-block-list">
                                        <li>
                                            <a data-role="preview-as-instructor"
                                                href="https://www.udemy.com/course/draft/5848040/learn/lecture/42598238/?instructorPreviewMode=instructor_v4"
                                                target="_blank" rel="noopener noreferrer"
                                                className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-link"
                                                draggable="false">
                                                <div className="ud-block-list-item-content">As Instructor</div>
                                            </a>
                                        </li>
                                        <li>
                                            <a data-role="preview-as-student"
                                                href="https://www.udemy.com/course/draft/5848040/learn/lecture/42598238/?instructorPreviewMode=student_v4"
                                                target="_blank" rel="noopener noreferrer"
                                                className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-link"
                                                draggable="false">
                                                <div className="ud-block-list-item-content">As Student</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
                <div>
                    <label className="switch-module--ud-switch-container--ycUx2 ud-text-md" htmlFor="switch--37">
                        <input data-role="is-downloadable-toggle" className="ud-sr-only switch-module--ud-real-toggle-input--9uKx3" id="switch--37" type="checkbox" />
                        Downloadable:
                        <span className="switch-module--ud-switch--fK-lz switch-module--ud-switch-large--gHEyw"></span>
                    </label>
                </div>
            </div>

        </div>
    )
}

export default EditPageContentHeader