// import React, { useState } from 'react';
// import { FaAngleDown } from 'react-icons/fa';
// import { MdFormatBold, MdOutlineFormatItalic, MdFormatListNumbered, MdFormatListBulleted, MdLink, MdImage, MdCode } from 'react-icons/md';

// const TextEditor = () => {
//   const [showStylesDropdown, setShowStylesDropdown] = useState(false);
//   const [isHtmlMode, setIsHtmlMode] = useState(false); // Toggle between WYSIWYG and HTML

//   const toggleDropdown = () => {
//     setShowStylesDropdown(prev => !prev);
//   };

//   const toggleHtmlMode = () => {
//     setIsHtmlMode(prev => !prev);
//   };

//   return (
//     <form className="border-2 border-red-800 mx-auto form-asset-creator--form--GkSS0">
//       <div className="ud-form-group mx-auto">
//         <label htmlFor="form-group--289" className="ud-form-label ud-heading-sm">Text</label>

//         <div className="udlite-in-udheavy rt-editor-container">
//           <div className="rt-menu-bar-container rt-menu-bar-container-sticky" data-purpose="menu-bar-container">
//             <div className="rt-menu-bar" data-purpose="menu-bar">
//               <div className="popper-module--popper--mM5Ie">
//                 <button
//                   type="button"
//                   className="ud-btn ud-btn-medium ud-btn-ghost ud-text-sm rt-menu-btn ud-link-neutral"
//                   onClick={toggleDropdown}
//                 >
//                   <span>Styles</span>
//                   <FaAngleDown aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall" />
//                 </button>

//                 {showStylesDropdown && (
//                   <div className="popper-module--popper-content--XE9z5" style={{ top: '100%', left: '0px', width: '20rem' }}>
//                     <div className="popper-module--animation-wrapper--tM0UK">
//                       <div className="dropdown-module--menu---dCM1 dropdown-module--dropdown-menu--7Qc2I" style={{ maxHeight: '28rem' }}>
//                         <ul className="ud-unstyled-list ud-block-list">
//                           <li>
//                             <button type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item-neutral">Normal text</button>
//                           </li>
//                           <li>
//                             <button type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item-neutral">Quote</button>
//                           </li>
//                           <li>
//                             <button type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item-neutral">Heading 4</button>
//                           </li>
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>

//               {/* Toolbar Icons */}
//               <button type="button" className="ud-btn ud-btn-ghost" title="Bold"><MdFormatBold /></button>
//               <button type="button" className="ud-btn ud-btn-ghost" title="Italic"><MdOutlineFormatItalic /></button>
//               <button type="button" className="ud-btn ud-btn-ghost" title="Numbers"><MdFormatListNumbered /></button>
//               <button type="button" className="ud-btn ud-btn-ghost" title="Bullets"><MdFormatListBulleted /></button>
//               <button type="button" className="ud-btn ud-btn-ghost" title="Insert link"><MdLink /></button>
//               <button type="button" className="ud-btn ud-btn-ghost" title="Image"><MdImage /></button>
//               <button type="button" className="ud-btn ud-btn-ghost" title="Code"><MdCode /></button>

//               {/* HTML Mode Toggle */}
//               <div className="rt-menu-bar-right">
//                 <button type="button" className="ud-btn ud-btn-ghost" onClick={toggleHtmlMode}>
//                   <span>{isHtmlMode ? 'Live Preview' : 'Edit HTML'}</span>
//                 </button>
//               </div>
//             </div>
//           </div>

//           {/* Editor */}
//           {!isHtmlMode ? (
//             <div className="border-2  p-2 rt-editor ud-text-md rt-editor--wysiwyg-mode" style={{ minHeight: '70px' }} contentEditable={true}>
//               <p><br /></p>
//             </div>
//           ) : (
//             <div className="border-2  p-2 rt-editor ud-text-md rt-editor--html-mode" style={{ minHeight: '70px' }} contentEditable={true}>
//               <p><br /></p>
//             </div>
//           )}
//         </div>
//       </div>

//       <div className="form-asset-creator--submit-row--GobdZ">
//         <button type="submit" className="ud-btn ud-btn-small ud-btn-primary ud-heading-sm">
//           <span>Save</span>
//         </button>
//       </div>
//     </form>
//   );
// };

// export default TextEditor;



// import React, { useRef, useState, useEffect } from 'react';
// import { EditorState } from 'prosemirror-state';
// import { EditorView } from 'prosemirror-view';
// import { Schema } from 'prosemirror-model';
// import { keymap } from 'prosemirror-keymap';
// import { toggleMark } from 'prosemirror-commands';
// import { schema as basicSchema } from 'prosemirror-schema-basic';
// import { addListNodes } from 'prosemirror-schema-list';
// import { MdFormatBold, MdOutlineFormatItalic } from 'react-icons/md';

// // Extend the schema to include bold and italic
// const extendedSchema = new Schema({
//   nodes: addListNodes(basicSchema.spec.nodes, 'paragraph block*', 'block'),
//   marks: {
//     ...basicSchema.spec.marks,
//     bold: {
//       parseDOM: [{ tag: 'strong' }],
//       toDOM() { return ['strong', 0]; },
//     },
//     italic: {
//       parseDOM: [{ tag: 'em' }],
//       toDOM() { return ['em', 0]; },
//     },
//   },
// });

// // Define your own keymap
// const customKeymap = {
//   'Mod-b': toggleMark(extendedSchema.marks.bold),
//   'Mod-i': toggleMark(extendedSchema.marks.italic),
// };

// // Custom Menu Bar Component
// const MenuBar = ({ editorView }) => {
//   if (!editorView) return null;

//   const handleBold = () => {
//     if (editorView) {
//       const { state, dispatch } = editorView;
//       toggleMark(extendedSchema.marks.bold)(state, dispatch);
//       editorView.focus();
//     }
//   };

//   const handleItalic = () => {
//     if (editorView) {
//       const { state, dispatch } = editorView;
//       toggleMark(extendedSchema.marks.italic)(state, dispatch);
//       editorView.focus();
//     }
//   };

//   return (
//     <div className="menu-bar">
//       <button type="button" className='w-10 h-10' onClick={handleBold} title="Bold">
//         <MdFormatBold />
//       </button>
//       <button className='w-10 h-10' type="button" onClick={handleItalic} title="Italic">
//         <MdOutlineFormatItalic />
//       </button>
//     </div>
//   );
// };

// // Main Editor Component
// const TextEditor = () => {
//   const editorRef = useRef(null);
//   const [editorView, setEditorView] = useState(null);

//   useEffect(() => {
//     if (!editorView && editorRef.current) {
//       const state = EditorState.create({
//         schema: extendedSchema,
//         plugins: [
//           keymap(customKeymap), // Use custom keymap here
//         ],
//       });

//       const view = new EditorView(editorRef.current, {
//         state,
//         dispatchTransaction(transaction) {
//           const newState = view.state.apply(transaction);
//           view.updateState(newState);
//         },
//       });

//       setEditorView(view);
//     }

//     return () => {
//       if (editorView) {
//         editorView.destroy();
//       }
//     };
//   }, [editorView]);

//   return (
//     <div>
//       <MenuBar editorView={editorView} />
//       <div
//         ref={editorRef}
//         className="editor"
//         style={{ border: '1px solid black', minHeight: '150px' }}
//       ></div>
//     </div>
//   );
// };

// export default TextEditor;

import { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = () => {
  const [isHtmlMode, setIsHtmlMode] = useState(false); // State to toggle HTML mode
  const [content, setContent] = useState(''); // Keep track of the current content
  const editorRef = useRef(null);

  // Function to toggle between WYSIWYG and HTML mode
  const toggleHtmlMode = () => {
    if (editorRef.current) {
      if (!isHtmlMode) {
        // Switch to HTML mode: display raw HTML content
        const currentContent = editorRef.current.getContent({ format: 'html' });
        setContent(currentContent); // Update content with the current HTML
        editorRef.current.setContent(escapeHtml(currentContent), { format: 'raw' });
      } else {
        // Before switching back to WYSIWYG mode, update content with edited HTML
        const rawHtml = editorRef.current.getContent({ format: 'raw' }); // Get edited HTML as raw text
        setContent(unescapeHtml(rawHtml)); // Update state with unescaped content
        editorRef.current.setContent(unescapeHtml(rawHtml), { format: 'html' });
      }
    }
    setIsHtmlMode((prev) => !prev); // Toggle mode
  };

  // Function to escape HTML characters (for HTML mode)
  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  };

  // Function to unescape HTML back to normal (for switching back to text mode)
  const unescapeHtml = (safe) => {
    return safe
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");
  };

  // Function to handle save button click
  const handleSave = () => {
    if (editorRef.current) {
      const updatedContent = editorRef.current.getContent({ format: 'html' });
      console.log(updatedContent); // Log or send the content to your backend
    }
  };

  return (
    <form className="border-2 border-red-800 mx-auto form-asset-creator--form--GkSS0">
      <div className="ud-form-group mx-auto">
        <label htmlFor="form-group--289" className="ud-form-label ud-heading-sm">Text</label>

        <div className="udlite-in-udheavy rt-editor-container">
          <div className="rt-menu-bar-container rt-menu-bar-container-sticky" data-purpose="menu-bar-container">
            <div className="rt-menu-bar" data-purpose="menu-bar">
              <div className="rt-menu-bar-right">
                <button type="button" className="ud-btn ud-btn-ghost" onClick={toggleHtmlMode}>
                  <span>{isHtmlMode ? 'Live Preview' : 'Edit HTML'}</span>
                </button>
              </div>
            </div>
          </div>

          {/* TinyMCE Editor */}
          <Editor className='border-2 border-red-900'
            apiKey="bv1djmc3yswl00ki30odys6nwt4wqclgzb2w4mx9o3wp951s" // Replace with your TinyMCE API key
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue="<p></p>"
            init={{
              menubar: false,
              plugins: 'lists link image', // Removed 'code' plugin to avoid modal popup
              toolbar: 'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              forced_root_block: false, // Disable forced paragraph tags in raw HTML
              valid_elements: '*[*]', // Allow any HTML elements
            }}
          />
        </div>
      </div>

      <div className="form-asset-creator--submit-row--GobdZ">
        <button onClick={handleSave} type="button" className="ud-btn ud-btn-small ud-btn-primary ud-heading-sm">
          <span>Save</span>
        </button>
      </div>
    </form>
  );
};

export default TextEditor;




