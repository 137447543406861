
import SubHeader from '../../SubHeader'
const IntendedLearners = () => {
    return (
        <div>
            <SubHeader pageTitle='Intended learners'/>
            <div className='main-content--content--1Myl4'></div>
        </div>
    )
}

export default IntendedLearners