import React, { useState, useRef, useEffect } from 'react';
import { sculptureList } from '../constants';
import { FcNext, FcPrevious } from "react-icons/fc";
import { CgPlayTrackNext, CgPlayTrackPrev, CgPlayButtonO, CgPlayPauseO } from "react-icons/cg";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { formatTime } from '../utils/util';
const PageTemplate = () => {
    const audioRef = useRef(null);
    const [index, setIndex] = useState(0);
    const [isStarted, setIsStarted] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMute, setIsMute] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime,setCurrentTime]=useState(0);
    const hasNext = index < sculptureList.length - 1;
    const hasPrevious = index > 0;
    const handleLoadedMetaData=(e)=>{
        setDuration(audioRef.current.duration)
    }
    useEffect(() => {
        const handleTimeUpdate = () => {
            setCurrentTime(audioRef.current.currentTime);
        };

        const handleLoadedMetadata = () => {
            setDuration(audioRef.current.duration);
        };

        const audioElement = audioRef.current;
        audioElement.addEventListener('timeupdate', handleTimeUpdate);
        audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);

        return () => {
            audioElement.removeEventListener('timeupdate', handleTimeUpdate);
            audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);
    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error('Failed to play audio:', error);
            });
        }
    };

    const handleNextClick = () => {
        if (hasNext) {
            setIndex(index + 1);
        } else {
            setIndex(0);
        }
    };

    const handlePrevClick = () => {
        if (hasPrevious) {
            setIndex(index - 1);
        }
    };

  
    useEffect(() => {
        if (isPlaying && audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error('Failed to play audio:', error);
            });
        }
    }, [index, isPlaying]);
    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };
    const handleAudioEnded = () => {
        if (hasNext) {
            handleNextClick();
        }
        else {
            setIsStarted(false);
            setIndex(0);
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }

        }
    };
    const handleMute = () => {
        setIsMute((prev) => !prev);
        audioRef.current.muted = !isMute;
    }
    const handleStart = () => {
        setIsStarted(true);
        playAudio();
    };
    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play().catch(error => {
                console.error('Failed to play audio:', error);
            });
        }
        setIsPlaying(!isPlaying);

    }
    const handleProgressChange=(event)=>{
        const newTime = event.target.value;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    }
    const sculpture = sculptureList[index];

    return (
        <div className='relative flex border-2 border-green-700 p-16 mt-8'>
            {!isStarted && (
                <button
                    onClick={handleStart}
                    className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-75 text-white text-2xl flex justify-center items-center"
                >
                    Start Narration
                </button>
            )}
            <button
                onClick={handlePrevClick}
                className={`${hasPrevious ? '' : 'cursor-not-allowed'} absolute top-[180px] left-2 bg-green-400 p-2 inline-block border-2 border-gray-950 hover:bg-slate-200`}
                disabled={!isStarted} // Disable button until the narration has started
            >
                <FcPrevious className='w-8 h-8 shrink-0' />
            </button>
            <div className='relative flex flex-col lg:flex-row '>
                <div className='border-2 border-red-500 shadow-xl'>
                    <div className='w-[640px] h-[360px] bg-green-800'>
                        <img className='w-[640px] h-[360px]' src={sculpture.url} alt={sculpture.alt} />
                    </div>
                    <div className='bg-black'>
                        <input
                            type="range"
                            min="0"
                            max={duration}
                            value={currentTime}
                            onChange={handleProgressChange}
                            className="w-full h-2 bg-gray-200 rounded-lg"
                        />
                    </div>
                    <div className='bg-black flex justify-start items-center gap-8 p-2'>
                        {
                            hasPrevious ? (<button onClick={handlePrevClick}>
                                <CgPlayTrackPrev className='h-12 w-12 text-white' />
                            </button>) : null
                        }
                        <button onClick={handlePlayPause} className=''>
                            {isPlaying ? <CgPlayPauseO className='w-12 h-12 text-white' /> : <CgPlayButtonO className='w-12 h-12 text-white' />}
                        </button>
                        <button onClick={handleNextClick} className=''>
                            <CgPlayTrackNext className='w-12 h-12 text-white' />
                        </button>
                        <button onClick={handleMute} className=''>
                            {isMute ? <GiSpeakerOff className='w-12 h-12 text-white' /> : <GiSpeaker className=' w-12 h-12 text-white' />}
                        </button>
                        <span className="text-white ml-4">{formatTime(currentTime)}/ {formatTime(duration)}</span>
                    </div>

                </div>
                <div className='flex-col p-8 border-2 border-blue-700'>
                    <h3>
                        ({index + 1} of {sculptureList.length})
                    </h3>
                    <p className='font-thin text-slate-800 font-sans'>{sculpture.description}</p>
                </div>
            </div>
            <button
                onClick={handleNextClick}
                className={`${hasNext ? '' : 'cursor-not-allowed'} absolute top-[180px] right-2 bg-green-400 p-2 inline-block border-2 border-gray-950 hover:bg-slate-200`}
                disabled={!isStarted} // Disable button until the narration has started
            >
                <FcNext className='w-8 h-8 shrink-0' />
            </button>
            {/* Hidden audio player */}
            <audio
                ref={audioRef}
                src={sculpture.audio_url}
                onEnded={handleAudioEnded}
                className="hidden"
            />
        </div>
    );
};

export default PageTemplate;
