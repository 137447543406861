import React from 'react'
import cn from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import { MdMenuBook } from "react-icons/md";

const Sidebar = () => {
  const [isToggle, setToggle] = useState(false)
  const [isActiveId, setIsActiveId] = useState('Intended learners');
  const [iseMenuOpen, setMenuOpen] = useState(false)
  const handleItemClick = (id) => {
    setIsActiveId(id);
  };
  return (
    <div className={cn('side-nav--side-nav--h8FTL', iseMenuOpen && 'side-nav--open--22jsc')} data-purpose="side-menu">

      <button onClick={() => setMenuOpen(!iseMenuOpen)} type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md ud-btn-icon ud-btn-icon-large side-nav--toggle-menu-btn--UZeDa">
        <MdMenuBook aria-label='Open menu' role='img' focusable='false' className='ud-icon ud-icon-large' />
      </button>

      <ul className="ud-unstyled-list side-nav--nav--6iscr side-nav--nav-sections--1OE5j">
        <li className="side-nav--nav-section--2jHwe">
          <div className="ud-heading-md side-nav--nav-section-title--Hq2Jb">Plan your course</div>
          <ul className="ud-unstyled-list">

            <li onClick={() => handleItemClick('Intended learners')} className={cn('side-nav--nav-item--3XyR4 ', isActiveId === 'Intended learners' && 'side-nav--nav-item-active--10cLf')}>

              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-goals" to="/instructor/course/5808754/manage/goals">
                <span className="side-nav--completion--25WQ7" aria-label="Incomplete"></span>
                <span>Intended learners</span>
              </NavLink>

            </li>

            <li onClick={() => handleItemClick('Course structure')} className={cn('side-nav--nav-item--3XyR4 ', isActiveId === 'Course structure' && 'side-nav--nav-item-active--10cLf')}>
              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-course-structure" to="/instructor/course/5808754/manage/course-structure">
                <span className="side-nav--completion--25WQ7" aria-label="Incomplete">
                </span>
                <span>Course structure</span>
              </NavLink>
            </li>

            <li className="side-nav--nav-item--3XyR4">
              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-setup" to="/instructor/course/5808754/manage/setup">
                <span className="side-nav--completion--25WQ7" aria-label="Incomplete"></span>
                <span>Setup &amp; test video</span>
              </NavLink>
            </li>
          </ul>
        </li>



        <li className="side-nav--nav-section--2jHwe">
          <div className="ud-heading-md side-nav--nav-section-title--Hq2Jb">Create your content</div>
          <ul className="ud-unstyled-list">
            <li className="side-nav--nav-item--3XyR4">
              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-film" to="/instructor/course/5808754/manage/film">
                <span className="side-nav--completion--25WQ7" aria-label="Incomplete"></span>
                <span>Film &amp; edit</span>
              </NavLink>
            </li>
            <li className="side-nav--nav-item--3XyR4 side-nav--nav-item-active--10cLf">
              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-curriculum" to="/instructor/course/5808754/manage/curriculum">
                <span className="side-nav--completion--25WQ7" aria-label="Incomplete"></span>
                <span>Curriculum</span>
              </NavLink>
            </li>
            <li className="side-nav--nav-item--3XyR4">
              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-captions" to="/instructor/course/5808754/manage/captions">
                <span className="side-nav--completion--25WQ7" aria-label="Incomplete"></span>
                <span>Captions (optional)</span>
              </NavLink>
            </li>
            <li className="side-nav--nav-item--3XyR4">
              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-accessibility" to="/instructor/course/5808754/manage/accessibility"><span className="side-nav--completion--25WQ7" aria-label="Incomplete"></span>
                <span>Accessibility (optional)</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="side-nav--nav-section--2jHwe">
          <div className="ud-heading-md side-nav--nav-section-title--Hq2Jb">Publish your course</div><ul className="ud-unstyled-list"><li className="side-nav--nav-item--3XyR4">
            <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-basics" to="/instructor/course/5808754/manage/basics"><span className="side-nav--completion--25WQ7" aria-label="Incomplete"></span>
              <span>Course landing page</span>
            </NavLink>
          </li>
            <li className="side-nav--nav-item--3XyR4">
              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-pricing" to="/instructor/course/5808754/manage/pricing"><span className="side-nav--completion--25WQ7" aria-label="Incomplete"></span><span>Pricing</span></NavLink>
            </li>
            <li className="side-nav--nav-item--3XyR4">
              <NavLink className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-promotions" to="/instructor/course/5808754/manage/promotions">
                <span className="side-nav--completion--25WQ7" aria-label="Completed">
                  {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-icon-color-neutral"><use xlink:to="#icon-tick"></use></svg> */}
                </span>
                <span>Promotions</span>
              </NavLink>
            </li>
            <li className="side-nav--nav-item--3XyR4">
              <Link className="ud-link-neutral side-nav--nav-item-link--2qfvl" data-purpose="react-nav-link-communications" to="/instructor/course/5808754/manage/communications/messages">
                <span className="side-nav--completion--25WQ7" aria-label="Incomplete">
                </span>
                <span>
                  Course messages</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>

      <div className="side-nav--publish-button--yW0v1">
        <button type="button" className="ud-btn ud-btn-large ud-btn-brand ud-heading-md">
          <span>Submit for Review</span>
        </button>
      </div>


    </div>
  )
}

export default Sidebar