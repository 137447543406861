import axios from 'axios'

const baseURL = 'http://localhost:3001/api';

// Create an instance of Axios with the base URL
export const API = axios.create({
  baseURL,
});



export const setToken = (newToken) => {
  const token = `Bearer ${newToken}`
  return {
    headers: {
      Authorization: token
    }
  }
}



export const formatTime=(time)=>{
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}