import React, { useState } from 'react'
import authService from '../../apiServices/auth';
import { fp } from '../../assets/index';
import { Link } from 'react-router-dom'
// import styles from './ForgetPassword.module.css';
import './form.css';
const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    console.log(email)
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await authService.forgetpassword({ email });
            console.log('in component', response)

        } catch (error) {
            console.log(error);

        }



    }
    return (
        <div className='lp-app-loader lp-component--forgot-password--app lp-app-loaded'>
            <div className='lp-container helpers--auth-container'>
                <h2 id='auth-to-lp-title' className='lp-heading-md helpers--auth-title'>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className='ud-compact-form-group ud-form-group'>
                            <div data-testid='ud-compact-form-control-container' className='ud-compact-form-control-container'>
                                <input aria-invalid='false' name='email'
                                    id=''
                                    minLength='6'
                                    maxLength='23'
                                    className='ud-text-input ud-text-input-large ud-text-md ud-compact-form-control'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type='email'
                                />
                                <label htmlFor='form-group--1' className='ud-form label ud-heading-sm'>
                                    <span className='ud-compact-form-content'>
                                        <span className='ud-compact-form-label-text'>Email</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <button type='submit' className='lp-btn lp-btn-large lp-btn-brand lp-heading-md helpers--auth-submit-button'>
                        <span>Reset Password</span>
                    </button>
                    <div className='helpers--auth-footer'>
                        <div className='lp-text-md'>
                            <span>or <Link to='/login' className='lp-text-bold lp-link-underline'>Log in</Link></span>
                        </div>
                    </div>

                    <div></div>
                </form>
            </div>
        </div>

    )
}

export default ForgetPassword