import React from 'react'
const VideoPreview = ({ src, title, description }) => (
  // <div className="w-full max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden">
  <div className="border-2 border-blue-500 w-full max-w-full mx-auto bg-white rounded-lg shadow-md overflow-hidden">
    <video className="w-full h-48 object-cover" controls>
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className="p-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);
const AudioPreview = ({ src, title, description }) => (
  <div className="w-full max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden">
    <audio className="w-full" controls>
      <source src={src} type="audio/mp3" />
      Your browser does not support the audio element.
    </audio>
    <div className="p-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const TextPreview = ({ title, content }) => (
  <div className="w-full max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden p-4">
    <h2 className="text-xl font-semibold">{title}</h2>
    <p className="text-gray-600">{content}</p>
  </div>
);


const ComboPreview = ({ imageSrc, videoSrc, audioSrc, title, description }) => (
  // <div className="border-2 border-blue-500 w-full max-w-lg mx-auto bg-white rounded-lg shadow-md overflow-hidden">
  <div className="border-2 border-blue-500 w-full max-w-full mx-auto bg-white rounded-lg shadow-md overflow-hidden">
  
    <div className="p-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      <p className="text-gray-600">{description}</p>
    </div>
    {imageSrc && <img className="w-full h-48 object-cover" src={imageSrc} alt="Imag" />}
    {videoSrc && (
      <video className="w-full h-48 object-cover" controls>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )}
    {audioSrc && (
      <audio className="w-full" controls>
        <source src={audioSrc} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    )}
  </div>
);
const ImagePreview = ({ images, title, description }) => (
  // <div className="border-2 w-full max-w-lg mx-auto bg-white rounded-lg shadow-md overflow-hidden">
  <div className="border-2 border-blue-500 w-full max-w-full mx-auto bg-white rounded-lg shadow-md overflow-hidden">
    <div className="p-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      <p className="text-gray-600">{description}</p>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
      {images.map((src, index) => (
        <img key={index} className="w-full h-48 object-cover rounded-lg" src={src} alt={`Imag ${index}`} />
      ))}
    </div>
  </div>
);

const EditPageContentFooter = ({ type }) => {
  // Dummy data
  const imageData = {
    images: [
      'https://via.placeholder.com/600x400?text=Image+1',
      'https://via.placeholder.com/600x400?text=Image+2',
      'https://via.placeholder.com/600x400?text=Image+3',
    ],
    title: 'Image Gallery',
    description: 'A collection of placeholder images.',
  };

  const videoData = {
    videoSrc: 'https://www.w3schools.com/html/mov_bbb.mp4', // Sample video
    title: 'Sample Video',
    description: 'A sample video for testing purposes.',
  };

  const audioData = {
    audioSrc: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3', // Sample audio
    title: 'Sample Audio',
    description: 'A sample audio for testing purposes.',
  };

  const textData = {
    title: 'Sample Text',
    content: 'This is a sample text content for testing purposes.',
  };

  const comboData = {
    images: ['https://via.placeholder.com/600x400?text=Image+1'],
    videoSrc: 'https://www.w3schools.com/html/mov_bbb.mp4',
    audioSrc: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    title: 'Combo Preview',
    description: 'A combination of image, video, and audio for testing.',
  };

  return (
    <div className='mt-4 mb-4'>
      {type === 'image' && <ImagePreview {...imageData} />}
      
      {type === 'video' && <VideoPreview {...videoData} />}
      
      {type === 'audio' && <AudioPreview {...audioData} />}
      
      {type === 'text' && <TextPreview {...textData} />}
      
      {type === 'combo' && <ComboPreview {...comboData} />}
    </div>
  );
};

export default EditPageContentFooter