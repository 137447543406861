import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import courseService from '../apiServices/course';
import chapterService from '../apiServices/chapter';
import pageService from '../apiServices/page';
import assetService from '../apiServices/asset';

// add page to coursecurriculam items

export const addAssetToPageInCourseCurriculam=createAsyncThunk('assets/addAssetToPage',async({assetData,accessToken,courseId})=>{
    try {
        const response=await assetService.addAssetToPage(assetData,accessToken,courseId)
        response.pageId=assetData.pageId;
        return response;
    } catch (error) {
        console.log('the new asset adition error might be')
    }
})
export const addPageInCourseCurriculam=createAsyncThunk('pages/addPage',async({pageData,accessToken,courseId})=>{
    try {
        const response=await pageService.createPage(pageData,accessToken,courseId);
        return response;
        
    } catch (error) {
        
    }
});
// async action 
export const addChapterInCourseCurriculam=createAsyncThunk('chapters/addChapter', async ({chapterData,accessToken,courseId}) => {

    console.log('the course id is:',courseId)
    try {
        const response = await chapterService.createChapter(chapterData,accessToken,courseId);
        return response.data;
    } catch (error) {
        console.log(error)
    }

})


export const deleteChapterFromCourseCurriculam = createAsyncThunk('chapters/deleteChapter', async ({ accessToken, chapterId, courseId }) => {
    try {
        const response = await chapterService.deleteChapter(accessToken, chapterId, courseId);
        return response;

    } catch (error) {

    }
})




// course curriculam items chapter or pages
export const fetchUserCourseCurriculamItemsAction=createAsyncThunk('course/fetchUserCourseCurriculamItems',async({accessToken,courseId})=>{
    try {
        const response=await courseService.getCourseCurriculamItems({accessToken,courseId});
        console.log('the response in course slice is :',response)
        return response;
    } catch (error) {
        console.log(error)
    }
})

// update items 
export const updateUserCourseCurriculamItemAction = createAsyncThunk('course/updateUserCourseCurriculamItems', async ({ accessToken, courseId, updateCourseCurriculamItems }) => {
    console.log('in action update items', updateCourseCurriculamItems)
    try {
        const response = await courseService.updateCourseCurriculamItems(accessToken, courseId, updateCourseCurriculamItems)
        return response;

    } catch (error) {
        console.log(error)
    }
})

// courses by a user
export const fetchUserCoursesAction = createAsyncThunk('course/fetchUserCourses', async (accessToken) => {
    try {
     
        const response = await courseService.getAllCourse(accessToken);
        return response;
    } catch (error) {
        console.log('error in action :', error);
    }
})


export const deleteUserCourseAction=createAsyncThunk('course/deleteCourse',async({accessToken,courseId})=>{

  

    try {
        const response=await courseService.deleteCourse(accessToken,courseId);
        return response
    } catch (error) {
        console.log(error)
    }



})


const courseSlice = createSlice({
    name: 'course',
    initialState: {
        userCourses: [],
        pageAsset:null,
        selectedCourse: null,
        newChapter:null,
        newPage:null,
        courseCurriculamItems:null,
        updatedCourseCurriculamItems: '',
        status: 'idle',
        error: null
    },
    reducers: {
        setSelectedCourse: (state, action) => {
            state.selectedCourse = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteUserCourseAction.pending,(state)=>{
                state.status='pending'
            }).addCase(deleteUserCourseAction.fulfilled,(state,action)=>{
                state.status='succeeded';
                const {courseId}=action.payload
                state.userCourses=state.userCourses.filter(course=>course._id!==courseId)
            }).addCase(deleteUserCourseAction.rejected,(state,action)=>{
                state.status='failed';
                state.error=action.payload
            })
            .addCase(fetchUserCoursesAction.pending, (state) => { state.status = 'loading' })
            .addCase(fetchUserCoursesAction.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userCourses = action.payload

            })
            .addCase(fetchUserCoursesAction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message
            })

            
            .addCase(fetchUserCourseCurriculamItemsAction.pending, (state) => { state.status = 'loading' })
            .addCase(fetchUserCourseCurriculamItemsAction.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.courseCurriculamItems = action.payload

            })
            .addCase(fetchUserCourseCurriculamItemsAction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message
            })

            .addCase(updateUserCourseCurriculamItemAction.pending, (state) => {
                state.status = 'loading';

            })
            .addCase(updateUserCourseCurriculamItemAction.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.updatedCourseCurriculamItems = action.payload;


            })
            .addCase(updateUserCourseCurriculamItemAction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload

            })

            .addCase(deleteChapterFromCourseCurriculam.pending, (state) => {
                state.status = 'loading';

            })
            .addCase(deleteChapterFromCourseCurriculam.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { chapterId } = action.payload;
                state.courseCurriculamItems.results = state.courseCurriculamItems.results.filter(
                    item => item.id !== chapterId
                );
                state.courseCurriculamItems.count=state.courseCurriculamItems.count-1;


            })
            .addCase(deleteChapterFromCourseCurriculam.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload
            })

            .addCase(addChapterInCourseCurriculam.pending,(state)=>{
                state.status='loading'
            })
            .addCase(addChapterInCourseCurriculam.fulfilled,(state,action)=>{
                state.status='succeeded'
                state.newChapter=action.payload;
                state.courseCurriculamItems.count=state.courseCurriculamItems.count+1;
                state.courseCurriculamItems.results.push(action.payload)

                // increase curriculum count as well and add in array the new chapter


            })
            .addCase(addChapterInCourseCurriculam.rejected,(state,action)=>{
                state.status = 'failed';
                state.error = action.payload
            })
            .addCase(addPageInCourseCurriculam.pending,(state)=>{
                state.status='loading';
            })
            .addCase(addPageInCourseCurriculam.fulfilled,(state,action)=>{
                console.log('Action Payload:', state);
                state.status='succeeded'
                state.newPage=action.payload;
                state.courseCurriculamItems.results.push(action.payload)
                state.courseCurriculamItems.count=state.courseCurriculamItems.count+1; 
            })
            .addCase(addPageInCourseCurriculam.rejected,(state,action)=>{
                state.status='failed';
                state.error=action.payload
            })

            .addCase(addAssetToPageInCourseCurriculam.pending,(state)=>{

                state.status='loading';


            }).addCase(addAssetToPageInCourseCurriculam.fulfilled,(state,action)=>{
                // const {pageId,...rest}=action.payload;
                // console.log('the page id is:',pageId)
                // // find in currculam 
                // state.status='succeeded'
                // state.pageAsset=rest
                // const findPage=state.courseCurriculamItems.results.map(page=>page.id===pageId)
                // // lot of work to do  here buddy
                // console.log('find page dataa is :',findPage)
                // findPage.asset=state.pageAsset

                const { pageId, ...rest } = action.payload;
                console.log('the page id is:', pageId);
            
                // Set status and pageAsset
                state.status = 'succeeded';
                state.pageAsset = rest;
            
                // Find and update the specific page
                const pageIndex = state.courseCurriculamItems.results.findIndex(page => page.id === pageId);
                if (pageIndex !== -1) {
                    state.courseCurriculamItems.results[pageIndex].asset = state.pageAsset;
                }



            })
            .addCase(addAssetToPageInCourseCurriculam.rejected,(state,action)=>{
                state.status='failed';
                state.error=action.payload

            })
    }
})


export const { setSelectedCourse } = courseSlice.actions;
export default courseSlice.reducer;