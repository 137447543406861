import s1 from './s1.jpg';
import s2 from './s2.jpg';
import s3 from './s3.jpg';
import fp from './fp.jpg'
import rp from './rp (1).jpg';
import a from './a.svg'
import b from './b.svg'
import c from './c.svg'
import d from './d.svg'
import registerLogo from './registerLogo.jpg';
import bgIlm from './bgIlm.jpg';
import sa from './sa.mp4'
import tst from './tst.mp4'
import mewat from './mewat.mp4';
import one from './one.mp3'
import two from './two.mp3'
import three from './three.mp3'
import four from './four.mp3'
import five from './five.mp3'
// import thumbnail1 from './sakhee/thumbnail/00_Trans_intro_v06.png'
import thumbnail2 from './sakhee/thumbnail/01_OPV0_v08.PNG'
// import thumbnail3 from './sakhee/thumbnail/02_Story_BCG_v08.png';
// import thumbnail4 from './sakhee/thumbnail/03_Story_HepB_v05_SND.png';
// import thumbnail5 from './sakhee/thumbnail/04_Story_Pentavalent_v10.png';
// import thumbnail6 from './sakhee/thumbnail/05_Story_Rotavirus_v05.png';
// import thumbnail7 from './sakhee/thumbnail/06_Story_PCV_v04.png';
// import thumbnail8 from './sakhee/thumbnail/07_Story_Measels_v06.png';
// import thumbnail9 from './sakhee/thumbnail/08_Story_IPV_v03.png';
// import thumbnail10 from './sakhee/thumbnail/09_Story_VITAMIN-A_v04.png';

// import story1 from './sakhee/video/00_Trans_intro_v06.mp4'
import story2 from './sakhee/video/01_OPV0_v08.mp4'
// import story3 from './sakhee/video/02_Story_BCG_v08.mp4'
// import story4 from './sakhee/video/03_Story_HepB_v05_SND.mp4'
// import story5 from './sakhee/video/04_Story_Pentavalent_v10.mp4'
// import story6 from './sakhee/video/05_Story_Rotavirus_v05.mp4'
// import story7 from './sakhee/video/06_Story_PCV_v04.mp4'
// import story8 from './sakhee/video/07_Story_Measels_v06.mp4'
// import story9 from './sakhee/video/08_Story_IPV_v03.mp4'
// import story10 from './sakhee/video/09_Story_VITAMIN-A_v04.mp4'

export const story=[{video:story2,thumbnail:thumbnail2}]
export {s1,s2,s3,fp,rp,a,b,c,d,registerLogo,bgIlm,sa,tst,mewat,one,two,three,four,five};