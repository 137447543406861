import React, { useRef, useState,useEffect } from 'react'
import Button from '../../Button';
import { MdFormatBold, MdFormatItalic, MdFormatListNumbered, MdFormatListBulleted } from 'react-icons/md';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { schema } from 'prosemirror-schema-basic';

import { Schema, DOMParser } from 'prosemirror-model';
import { exampleSetup } from 'prosemirror-example-setup';
import { addListNodes } from 'prosemirror-schema-list';

const PageDescriptionForm = ({ onClose }) => {
    const [editorState, setEditorState] = useState(null);
    const editorRef = useRef(null);

    useEffect(() => {
        // Define the schema
        const mySchema = new Schema({
            nodes: addListNodes(schema.spec.nodes, 'paragraph block*', 'block'),
            marks: schema.spec.marks
        });

        // Initialize the editor state
        const initialContent = '<p>Add a description. Include what students will be able to do after completing the lecture.</p>';
        const content = DOMParser.fromSchema(mySchema).parse(initialContent);
        const initialState = EditorState.create({
            doc: content,
            plugins: exampleSetup({ schema: mySchema })
        });
        setEditorState(initialState);

        // Create the editor view and menu bar once
        if (editorRef.current && initialState) {
            const view = new EditorView(editorRef.current, {
                state: initialState,
                dispatchTransaction(transaction) {
                    const newState = view.state.apply(transaction);
                    view.updateState(newState);
                    setEditorState(newState);
                }
            });
        }
    }, []);













    return (
        <form data-purpose="description-form">
            <div className="ud-form-group">
                <label htmlFor="form-group--28" className="ud-form-label ud-heading-sm">Lecture Description</label>
                <div className="udlite-in-udheavy rt-editor-container">

                     {/* <div className="rt-menu-bar-container rt-menu-bar-container-sticky" data-purpose="menu-bar-container">
                        <div className="rt-menu-bar" data-purpose="menu-bar">
                            <Button type="button" className="ud-btn ud-btn-medium ud-btn-ghost ud-text-sm ud-btn-icon ud-btn-icon-medium rt-menu-icon-btn ud-link-neutral"
                                aria-pressed="false" data-purpose="TOGGLE_STRONG" title="Bold" >
                                <MdFormatBold aria-label="Bold" role="img" focusable="false" className="ud-icon ud-icon-small" />
                            </Button>

                            <Button type="button" className="ud-btn ud-btn-medium ud-btn-ghost ud-text-sm ud-btn-icon ud-btn-icon-medium rt-menu-icon-btn ud-link-neutral"
                                aria-pressed="false" data-purpose="TOGGLE_EM" title="Italic">
                                <MdFormatItalic aria-label="Italic" role="img" focusable="false" className="ud-icon ud-icon-small" />
                            </Button>

                            <Button type="button"
                                className="ud-btn ud-btn-medium ud-btn-ghost ud-text-sm ud-btn-icon ud-btn-icon-medium rt-menu-icon-btn ud-link-neutral"
                                data-purpose="TOGGLE_ORDERED_LIST" title="Numbers">
                                <MdFormatListNumbered aria-label="Numbers" role="img" focusable="false" className="ud-icon ud-icon-small" />

                            </Button>

                            <Button type="button"
                                className="ud-btn ud-btn-medium ud-btn-ghost ud-text-sm ud-btn-icon ud-btn-icon-medium rt-menu-icon-btn ud-link-neutral"
                                data-purpose="TOGGLE_BULLET_LIST" title="Bullets">
                                <MdFormatListBulleted aria-label="Bullets" role="img" focusable="false" className="ud-icon ud-icon-small" />

                            </Button>
                            <div className="rt-menu-bar-right"></div>


                        </div>
                    </div> */}

                    <div className="rt-editor ud-text-md rt-editor--empty rt-editor--wysiwyg-mode" data-purpose="wysiwyg-mode">
                        {/* <div contentEditable="true"
                            placeholder="Add a description. Include what students will be able to do after completing the lecture."
                            role="textbox" aria-multiline="true"
                            aria-label="Add a description. Include what students will be able to do after completing the lecture."
                            className="ProseMirror rt-scaffolding"
                            style={{ minHeight: '70px' }}
                        >
                            <p>
                                <br />
                            </p>
                        </div> */}

                        <div ref={editorRef}/>
                    </div> 


                </div>
            </div>



            <div className="item-form-submit-row">
                <Button onClick={onClose} type="button" className="ud-btn ud-btn-small ud-btn-ghost ud-heading-sm ud-link-neutral">
                    <span>Cancel</span>
                </Button>
                <Button type="submit" className="ud-btn ud-btn-small ud-btn-primary ud-heading-sm">
                    <span>Save</span>
                </Button>
            </div>



        </form>
    )
}

export default PageDescriptionForm