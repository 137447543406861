
import { API } from '../utils/util'

const baseUrl='http://localhost:3001/api/auth'
 const signup = async (object) => {
    const response = await API.post(`${baseUrl}/signup`, object);
    return response.data
}

 const logout=()=>{
    localStorage.removeItem('userToken');
}

 const signin = async (object) => {
    const response = await API.post(`${baseUrl}/signin`, object);
    console.log('the api data ',response.data);
  
    return response.data;
}



 const forgetpassword = async (email) => {
    const response = await API.post('/forgetpassword', email);
    console.log(response.data)
    return response.data;
}

 const resetpassword = async (newpassword) => {
    const response = await API.post('/resetpassword', newpassword);
    console.log(response.data);


}


const authService={
    signup,signin,logout,forgetpassword,resetpassword,
}


export default authService;