

import axios from '../API/axios';


export const deleteChapter = async (accessToken, chapterId, courseId) => {
    try {
        const response = await axios.delete(`/users/me/taught-courses/${courseId}/chapters/${chapterId}`, {
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${accessToken}`
            }
        });
        if (response.status === 204) {
            return { chapterId };
        } else {
            throw new Error('Failed to delete chapter');
        }

    } catch (error) {

    }
}

export const createChapter=async(chapterdata,accessToken,courseId)=>{


    try {
        const response=await axios.post(`/users/me/taught-courses/${courseId}/chapters`,chapterdata,{
            headers:{
                "Content-Type":"application/json",
                'authorization':`Bearer ${accessToken}`
            }
        });
      
        return response
    } catch (error) {
        
    }
}


const chapterService={
    createChapter,deleteChapter
}

export default chapterService;