import React from 'react'
import { MdAdd } from 'react-icons/md';
import { MdFormatBold, MdFormatItalic, MdFormatListNumbered, MdFormatListBulleted } from 'react-icons/md';
import { useState } from 'react';
import Button from '../Button';
import PageDescriptionForm from '../form/page/PageDescriptionForm'
const EditPageContentBody = () => {
    const [showPageDescriptionForm, setPageDescriptionForm] = useState(false)
    const onOpenPageDescriptionForm = () => {
        setPageDescriptionForm(true)
    }
    const onClosePageDescriptionForm = () => {
        setPageDescriptionForm(false);
    }

    return (
        <>
            {showPageDescriptionForm && <div className="lecture-editor--edit-content-row">
                <PageDescriptionForm onClose={onClosePageDescriptionForm} />
            </div>}


            <div className="lecture-editor--edit-content-row">


                {!showPageDescriptionForm &&
                    <div className="lecture-editor--edit-content-button-row--33Lxr">
                        <Button onClick={onOpenPageDescriptionForm} type="button" data-role="add-desc-btn" aria-label="Add Description" className="ud-btn ud-btn-small ud-btn-secondary ud-heading-sm">
                            <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />
                            <span>Description</span>
                        </Button>
                    </div>}

                <div className="lecture-editor--edit-content-button-row--33Lxr">
                    <Button  type="button" data-role="add-resources-btn" aria-label="Add Resources" className="ud-btn ud-btn-small ud-btn-secondary ud-heading-sm">
                        <MdAdd aria-hidden="true" focusable="false" className="ud-icon ud-icon-small" />

                        <span>Resources</span>
                    </Button>
                </div>
            </div>


        </>
    )
}

export default EditPageContentBody