
import { useState, useEffect } from "react";
import { MdAdd, MdDelete, MdEdit, MdMenu, MdNote } from "react-icons/md";
import ChapterEditForm from './ChapterEditForm';
import EditChapterForm from "../form/chapter/EditChapterForm";
import { NavLink } from "react-router-dom";
import Page from "./Page";
import CurriculamItemWrapper from "./CurriculamItemWrapper";
import { fetchUserCourseCurriculamItemsAction, updateUserCourseCurriculamItemAction } from "../../appSlices/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import Modal from "../modals/Modal";
// import { deleteChapterAction } from "../../appSlices/chapterSlice";
import { deleteChapterFromCourseCurriculam } from "../../appSlices/courseSlice";
import NewChapterButton from "./NewChapterButton";
import { Fragment } from "react";
const MainContent = () => {

  const { auth } = useAuth();
  const dispatch = useDispatch();
  const [id, setId] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { courseId } = useParams();
  const curriculumItems = useSelector((state) => state.course.courseCurriculamItems);
  const status = useSelector((state) => state.course.status);
  const error = useSelector((state) => state.course.error);
  const newChapterCreated = useSelector((state) => state.chapters.newChapterCreated);
  const [newData, setNewData] = useState([]);

  // handlers 
  const handleConfirmDelete = id => {
    console.log('the cinfirm button and id is:', id)
    dispatch(deleteChapterFromCourseCurriculam({ accessToken: auth.accessToken, courseId, chapterId: id }))
    setIsModalOpen(false)
  }

  const handleDeleteChapter = (id) => {

    openModal()
    setId(id)
    // confirm the action to delete
    // press ok to delete
  }

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }





  useEffect(() => {
    dispatch(fetchUserCourseCurriculamItemsAction({ accessToken: auth.accessToken, courseId }))
  }, [dispatch, courseId, auth.accessToken]);
  const [isChapterEditFormOpen, setChapterEditForm] = useState(false)

  const handleOpenChapterEditForm = () => {
    setChapterEditForm(true)
  }
  const handleCloseChapterEditForm = () => {
    setChapterEditForm(false);
  }
  if (status === 'loading') {
    return (<p>loading...............................</p>)

  }

  if (!curriculumItems || !curriculumItems.results) {
    return <p>some thing went wrong</p>

  }

  return (
    <div className='main-content--content--1My14'>
      <Modal isOpen={isModalOpen} onConfirmDelete={() => handleConfirmDelete(id)} onClose={closeModal} />
      <div>
        <div className="curriculum-editor--alert-container">
          <p className="curriculum-editor--info--31DNc" data-purpose="free-course-message">
            <span>
              Start putting together your course by creating sections, lectures and practice activities (<NavLink className="ud-link-underline" to="/udemy-teach-hub/course_creation_practice_activities/" target="_blank" rel="noopener noreferrer">quizzes, coding exercises and assignments</NavLink>). Use your <a className="ud-link-underline" href="/udemy-teach-hub/course_creation_outline_course/" target="_blank" rel="noopener noreferrer">course outline</a> to structure your content and label your sections and lectures clearly.
            </span>
            If you’re intending to offer your course for free, the total length of video content must be less than 2 hours.
          </p>
        </div>


        <div className='curriculum-list--inline-insert-enabled'>

          <div className='curriculum-list--curriculum-list' data-purpose='curriculam-list'>
            {/* {curriculumItems.results.length===0 && } */}
            {curriculumItems.results.map((item) => (
              <Fragment key={item.id}>
                {item._class === 'CHAPTER' &&
                  <div id={`${item._class}${item.id}`} key={item.id} className="js-curriculum-item-draggable curriculum-list--list-item curriculum-list--section">
                    <div className="curriculum-list--inline-insert--vjbTx curriculum-list--inline-insert-section--2yFJE">
                      <div className="curriculum-list--inline-insert-button-row--1WWhb">


                        <button type="button" data-purpose="add-item-inline"
                          className="ud-btn ud-btn-xsmall ud-btn-link ud-heading-sm curriculum-list--inline-insert-button--1_HAG ud-custom-focus-visible add-item--add-item-toggler--1k-rh add-item--section--1_IVB">

                          <span className="ud-focus-visible-target add-item--icon-wrapper--GRi6k">
                            <MdAdd aria-label='New curriculam item' role='img' focusable='false' className='ud-icon ud-icon-small ud-icon-color-neutral' />

                          </span>


                        </button>
                      </div>
                    </div>
                    <div data-purpose="section-editor">

                      {isChapterEditFormOpen && <EditChapterForm onClose={handleCloseChapterEditForm} />}
                      <div className="popper-module--popper--2BpLn">
                        <div
                          className={`item-icon-button-trigger js-curriculum-item-handle item-bar--item-bar--qgtrI item-bar--row--Odlz8 item-bar--item-bar-section--V3966 ${isChapterEditFormOpen ? 'section-editor--hidden--z7njc' : ''}`}
                          id="popper-trigger--30"
                          aria-describedby="popper-content--31"
                          tabIndex="0">

                          <div className="item-bar--flex--AcNdN item-bar--main--3LgFa item-bar--row--3ZgKF">
                            <div className="item-bar--flex--AcNdN item-bar--left--6blR1">

                              <div className="item-bar--left-content--af69I item-bar--row--3ZgKF" data-purpose="item-full-title">

                                <span className="ud-text-bold item-bar--label--1yiC3" data-purpose="item-object-index">Chapter {item.object_index}:</span>

                                <span className="item-bar--title-desktop--305o_ item-bar--row--3ZgKF">
                                  <MdNote aria-hidden='true' focusable='false' data-purpose='section-icon' className='lp-icon lp-icon-xsmall lp-icon-color-neutral item-bar--icon--1R2Ix' />
                                  <span className="item-bar--ellipsis--Lbep0">{item.title}</span>

                                </span>


                                <button onClick={handleOpenChapterEditForm} type="button" data-purpose="section-edit-btn" className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall ud-link-neutral item-icon-button--icon-button--1IKG6">
                                  <MdEdit aria-label='Edit' role='img' focusable='false' className='lp-icon lp-icon-xsmall lp-icon-color-neutral' />

                                </button>
                                <button onClick={() => handleDeleteChapter(item.id)} type="button" data-purpose="section-delete-btn" className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall ud-link-neutral item-icon-button--icon-button--1IKG6">
                                  <MdDelete aria-label='Delete' role='img' focusable='false' className='lp-icon lp-icon-xsmall lp-icon-color-neutral' />
                                </button>
                              </div>

                              <div className="item-bar--title-mobile--2qLqw item-bar--left-content--af69I item-bar--row--3ZgKF">
                                {/* <svg aria-hidden="true" focusable="false" data-purpose="section-icon" class="ud-icon ud-icon-xsmall ud-icon-color-neutral item-bar--icon--1R2Ix"><use xlink:href="#icon-article"></use></svg> */}
                                <span className="item-bar--ellipsis--Lbep0">{item.title}</span>
                              </div>

                            </div>
                          </div>

                          <div className="item-bar--right--4qb_w item-bar--row--3ZgKF">

                            <div className="ud-btn ud-btn-xsmall ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-xsmall item-bar--drag-handle--2q7kQ ud-link-neutral item-icon-button--icon-button--1IKG6">

                              <MdMenu aria-label='Move' role='img' focusable='false' className='lp-icon lp-icon-small lp-icon-color-neutral' />

                            </div>

                          </div>


                        </div>
                      </div>


                    </div>

                    {/* {item.pages.length===0 &&  */}
                    <> <CurriculamItemWrapper chapterId={item.id} />
                  <NewChapterButton  />
                  </>
                  {/* } */}

                  </div>}
                {item._class === 'PAGE' && <Page item={item} />}
              </Fragment>
            ))}
          </div>
        </div>                                                                                                                                    

        
      </div>


    </div>
  )
}
export default MainContent