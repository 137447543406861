
import axios from "../API/axios";
// standard response from mmust be 
/*

{
    "_class": "lecture",
    "id": 44452306,
    "title": "this is lecture",
    "description": "",
    "is_published": true,
    "is_downloadable": true,
    "is_free": false,
    "asset": null,
    "object_index": 0,
    "supplementary_assets": []
}
*/
export const createPage=async(pageData,accessToken,courseId)=>{

    try {
        const response=await axios.post(`/users/me/taught-courses/${courseId}/pages`,pageData,{
            headers:{
                "Content-Type":"application/json",
                'authorization':`Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        
    }
}


const pageService={
    createPage
}

export default pageService