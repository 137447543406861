import { Link } from "react-router-dom";
import { MdSearch, MdExpandMore } from "react-icons/md";
import CourseCard from "./CourseCard";
import CourseCreator from "../course/CourseCreator";
import { useEffect, useState } from "react";
// import { axiosPrivate } from "../../API/axios";
import axios from "../../API/axios";
import useAuth from "../../hooks/useAuth";
import { Vortex } from 'react-loader-spinner'

// import useRefreshToken from "../../hooks/useRefreshToken";

import { useSelector, useDispatch } from "react-redux";
import { deleteUserCourseAction, fetchUserCoursesAction } from "../../appSlices/courseSlice";
import { setSelectedCourse } from "../../appSlices/courseSlice";
import Loader from "../Loader";
const Courses = () => {

  const dispatch = useDispatch();
  const courses = useSelector((state) => state.course.userCourses);

  const courseStatus = useSelector((state) => state.course.status);
  const error = useSelector((state) => state.course.error);
  const { auth } = useAuth();

  const handleDeleteCourse = (id) => {
    console.log('the course to delete id is:', id)
    dispatch(deleteUserCourseAction({ accessToken: auth.accessToken, courseId: id }))
  }




  useEffect(() => {
    if (auth.accessToken) {
      dispatch(fetchUserCoursesAction(auth.accessToken));
    }

  }, [dispatch, auth.accessToken]);



  // if (status === 'loading') {
  //   return <Vortex
  //     visible={true}
  //     height="80"
  //     width="80"
  //     ariaLabel="vortex-loading"
  //     wrapperStyle={{}}
  //     wrapperClass="vortex-wrapper"
  //     colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
  //   />
  // }

  // if (status === 'failed') {
  //   return <div>Error: {error}</div>;
  // }
  return (
    <div>
      <div className="instructor-main-heading ia-responsive-header--simple-container---JWkW">
        <h1 className="ud-heading-serif-xxl">Courses</h1>
        <button type="button" className="ud-btn ud-btn-large ud-btn-brand ud-heading-md courses--mobile-new-course-button--S253y" data-purpose="header-new-course"><span>New course</span></button>
      </div>

      <div className="instructor-alerts--instructor-alerts--wZ1YF"></div>

      {courses?.length === 0 &&
        <div className="instructor-dashboard--panel--Di7Ee instructor-dashboard--create-cta-panel--p-Qsl">
          <h1 className="instructor-dashboard--create-cta-title--Nt22l">
            Jump Into Course Creation
          </h1>
          <Link
            to="/course/create"
            className="ud-btn ud-btn-large ud-btn-brand ud-heading-md instructor-dashboard--create-cta-button--cn3Rp"
            data-purpose="click-new-course"
          >
            <span>Create Your Course</span>
          </Link>
        </div>
      }

      {courses?.length > 0 &&
        <>
          <div className="courses--search-row--t-6fi">
            <div className="courses--search-wrapper---39KR">



              <div className="ud-form-group">
                <label
                  className="ud-sr-only ud-form-label ud-heading-sm"
                  htmlFor="form-group--3"
                >
                  Search your courses
                </label>

                <form className="text-input-form-module--text-input-form--tITHD">
                  <input
                    aria-invalid="false"
                    data-purpose="search-my-courses"
                    placeholder="Search your courses"
                    id="form-group--3"
                    type="text"
                    className="ud-text-input ud-text-input-large ud-text-md"
                    value=""
                    onChange={() => { }}
                  />
                  <button
                    type="submit"
                    className="ud-btn ud-btn-large ud-btn-primary ud-heading-md
                             ud-btn-icon ud-btn-icon-large"
                  >
                    <MdSearch
                      aria-label="Search"
                      role="img"
                      focusable="false"
                      className="ud-icon ud-icon-medium"
                    />
                  </button>
                </form>
              </div>

              <div className="popper-module--popper--mM5Ie">
                <button
                  type="button"
                  aria-label="Change the ordering of the courses"
                  id="dropdown-trigger--5"
                  aria-expanded="false"
                  tabIndex="0"
                  className="ud-btn ud-btn-large ud-btn-secondary ud-heading-md"
                >
                  <span>Newest</span>
                  <MdExpandMore
                    aria-hidden="true"
                    focusable="false"
                    className="ud-icon ud-icon-small"
                  />
                </button>
                <div
                  id="popper-content--25"
                  aria-labelledby="dropdown-trigger--5"
                  className="popper-module--popper-content--XE9z5"
                  style={{ top: "100%", left: "0px", width: "20rem" }}
                >
                  <div className="popper-module--animation-wrapper--tM0UK">
                    <div
                      className="dropdown-module--menu---dCM1 dropdown-module--dropdown-menu--7Qc2I"
                      style={{ maxHeight: "28rem" }}
                    >
                      <ul className="ud-unstyled-list ud-block-list">
                        <li>
                          <button
                            type="button"
                            className="ud-btn
                               ud-btn-large ud-btn-ghost ud-text-sm
                               ud-block-list-item ud-block-list-item-small
                               ud-block-list-item-neutral"
                          >
                            <div className="ud-block-list-item-content">Newest</div>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm
                               ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral"
                          >
                            <div className="ud-block-list-item-content">Oldest</div>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item
                               ud-block-list-item-small ud-block-list-item-neutral"
                          >
                            <div className="ud-block-list-item-content">A-Z</div>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small
                                ud-block-list-item-neutral"
                          >
                            <div className="ud-block-list-item-content">Z-A</div>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item
                                ud-block-list-item-small ud-block-list-item-neutral"
                          >
                            <div className="ud-block-list-item-content">
                              Published first
                            </div>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm
                                ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral"
                          >
                            <div className="ud-block-list-item-content">
                              Unpublished first
                            </div>
                          </button>
                        </li>
                      </ul>
                    </div>


                  </div>


                </div>
              </div>


            </div>
            <Link
              to="/course/create"
              className="ud-btn ud-btn-large ud-btn-brand ud-heading-md instructor-dashboard--create-cta-button--cn3Rp"
              data-purpose="click-new-course"
            >
              <span> New Course</span>
            </Link>


          </div>

          <div>

            {courseStatus === 'loading' && <Loader />}
            <div className="view-type-light courses--courses-list--60K3v">
              {courses?.map((course) => (
                <CourseCard course={course} key={course._id} onDelete={handleDeleteCourse} />
              ))}
            </div>
          </div>
        </>
      }
      <div>
        <div>
          <h2 className="instructor-dashboard--resources-title--n6NJx">
            Based on your experience, we think these resources will be helpful.
          </h2>
          <div
            className="instructor-dashboard--new-instructor-resources--ZbPy8"
            data-purpose="new-instructor-resources"
          >
            <div
              className="instructor-dashboard--panel--Di7Ee
                        instructor-dashboard--resource-panel-primary---8R2M"
            >
              <div className="instructor-dashboard--resource-image-col--6i4wW">
                <img
                  src="https://s.udemycdn.com/instructor/dashboard/engaging-course.jpg"
                  srcSet="https://s.udemycdn.com/instructor/dashboard/engaging-course.jpg 1x,
                        https://s.udemycdn.com/instructor/dashboard/engaging-course-2x.jpg 2x"
                  alt=""
                  width="300"
                  height="300"
                  className="instructor-dashboard--resource-image--nuzov"
                  loading="lazy"
                />
              </div>
              <div className="instructor-dashboard--resource-description-col--lB2s4">
                <h3 className="ud-text-xl instructor-dashboard--resource-title--Y-AYW">
                  Create an Engaging Course
                </h3>
                <p className="instructor-dashboard--resource-text--0ehcK">
                  Whether you've been teaching for years or are teaching for the
                  first time, you can make an engaging course. We've compiled
                  resources and best practices to help you get to the next
                  level, no matter where you're starting.
                </p>
                <Link
                  className="ud-link-underline"
                  to="/instructor/resources/?page=teaching"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Started
                  <span className="ud-sr-only"> Create an Engaging Course</span>
                </Link>
              </div>
            </div>

            <div
              className="instructor-dashboard--panel--Di7Ee
                        instructor-dashboard--resource-panel--thWS9"
            >
              <div className="instructor-dashboard--resource-image-col--6i4wW">
                <img
                  src="https://s.udemycdn.com/instructor/dashboard/video-creation.jpg"
                  srcSet="https://s.udemycdn.com/instructor/dashboard/video-creation.jpg 1x,
                                https://s.udemycdn.com/instructor/dashboard/video-creation-2x.jpg 2x"
                  alt=""
                  width="300"
                  height="300"
                  className="instructor-dashboard--resource-image--nuzov"
                  loading="lazy"
                />
              </div>
              <div className="instructor-dashboard--resource-description-col--lB2s4">
                <h3 className="ud-text-lg instructor-dashboard--resource-title--Y-AYW">
                  Get Started with Video
                </h3>
                <p className="instructor-dashboard--resource-text--0ehcK">
                  Quality video lectures can set your course apart. Use our
                  resources to learn the basics.
                </p>
                <Link
                  className="ud-link-underline"
                  to="/instructor/resources/?page=video"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Started
                  <span className="ud-sr-only"> Get Started with Video</span>
                </Link>
              </div>
            </div>

            <div className="instructor-dashboard--panel--Di7Ee instructor-dashboard--resource-panel--thWS9">
              <div className="instructor-dashboard--resource-image-col--6i4wW">
                <img
                  src="https://s.udemycdn.com/instructor/dashboard/build-audience.jpg"
                  srcSet="https://s.udemycdn.com/instructor/dashboard/build-audience.jpg 1x,
                                    https://s.udemycdn.com/instructor/dashboard/build-audience-2x.jpg 2x"
                  alt=""
                  width="300"
                  height="300"
                  className="instructor-dashboard--resource-image--nuzov"
                  loading="lazy"
                />
              </div>
              <div className="instructor-dashboard--resource-description-col--lB2s4">
                <h3 className="ud-text-lg instructor-dashboard--resource-title--Y-AYW">
                  Build Your Audience
                </h3>
                <p className="instructor-dashboard--resource-text--0ehcK">
                  Set your course up for success by building your audience.
                </p>
                <Link
                  className="ud-link-underline"
                  to="/instructor/resources/?page=audience"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Started
                  <span className="ud-sr-only"> Build Your Audience</span>
                </Link>
              </div>
            </div>
            <div
              className="instructor-dashboard--panel--Di7Ee
                                    instructor-dashboard--resource-panel-primary---8R2M"
            >
              <div className="instructor-dashboard--resource-image-col--6i4wW">
                <img
                  src="https://s.udemycdn.com/instructor/dashboard/newcomer-challenge.jpg"
                  srcSet="https://s.udemycdn.com/instructor/dashboard/newcomer-challenge.jpg 1x,
                                         https://s.udemycdn.com/instructor/dashboard/newcomer-challenge-2x.jpg 2x"
                  alt=""
                  width="300"
                  height="300"
                  className="instructor-dashboard--resource-image--nuzov"
                  loading="lazy"
                />
              </div>
              <div className="instructor-dashboard--resource-description-col--lB2s4">
                <h3 className="ud-text-xl instructor-dashboard--resource-title--Y-AYW">
                  Join the New Instructor Challenge!
                </h3>
                <p className="instructor-dashboard--resource-text--0ehcK">
                  Get exclusive tips and resources designed to help you launch
                  your first course faster! Eligible instructors who publish
                  their first course on time will receive a special bonus to
                  celebrate. Start today!
                </p>
                <Link
                  rel="noopener noreferrer"
                  className="ud-link-underline"
                  data-purpose="start-newcomer-challenge"
                  id="start-newcomer-challenge"
                  to="/instructor/newcomer/"
                >
                  Get Started
                  <span className="ud-sr-only">
                    {" "}
                    Join the New Instructor Challenge!
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div>                                       
          <h2 className="instructor-dashboard--resources-title--n6NJx">
            Have questions? Here are our most popular instructor resources.
          </h2>

          <div className="instructor-dashboard--resource-units--csNRN">
            <Link
              to="test-video/"
              target="_blank"
              rel="noopener noreferrer"
              className="instructor-dashboard--resource-unit--n8Qzs"
            >
              {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xlarge ud-icon-color-neutral"><use xlink:to="#icon-video"></use></svg><div className="ud-text-bold ud-link-underline instructor-dashboard--resource-unit-title--felaN">Test Video</div><div className="ud-text-sm">Send us a sample video and get expert feedback.</div></Link><Link to="https://community.udemy.com" target="_blank" rel="noopener noreferrer" className="instructor-dashboard--resource-unit--n8Qzs"><svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xlarge ud-icon-color-neutral"><use xlink:to="#icon-question-answer"></use></svg> */}
              <div className="ud-text-bold ud-link-underline instructor-dashboard--resource-unit-title--felaN">
                Instructor Community
              </div>
              <div className="ud-text-sm">
                Connect with experienced instructors. Ask questions, browse
                discussions, and more.
              </div>
            </Link>
            <Link
              to="/udemy-teach-hub/"
              target="_blank"
              rel="noopener noreferrer"
              className="instructor-dashboard--resource-unit--n8Qzs"
            >
              {/* <svg aria-hidden="true" focusable="false"
                                className="ud-icon ud-icon-xlarge ud-icon-color-neutral">
                                <use xlink:to="#icon-teach">

                                </use>
                            </svg> */}
              <div className="ud-text-bold ud-link-underline instructor-dashboard--resource-unit-title--felaN">
                Teaching Center
              </div>
              <div className="ud-text-sm">
                Learn about best practices for teaching on Udemy.
              </div>
            </Link>
            <Link
              to="https://www.udemy.com/instructor/marketplace-insights/"
              target="_blank"
              rel="noopener noreferrer"
              className="instructor-dashboard--resource-unit--n8Qzs"
            >
              {/* <svg aria-hidden="true" focusable="false"
                             className="ud-icon ud-icon-xlarge ud-icon-color-neutral">
                                <use xlink:to="#icon-data-insight"></use>
                                </svg> */}
              <div className="ud-text-bold ud-link-underline instructor-dashboard--resource-unit-title--felaN">
                Marketplace Insights
              </div>
              <div className="ud-text-sm">
                Validate your course topic by exploring our marketplace supply
                and demand.
              </div>
            </Link>

            <Link
              to="/support?type=instructor"
              target="_blank"
              rel="noopener noreferrer"
              className="instructor-dashboard--resource-unit--n8Qzs"
            >
              {/* <svg aria-hidden="true"
                                focusable="false" className="ud-icon ud-icon-xlarge ud-icon-color-neutral">
                                <use xlink:to="#icon-help-support"></use></svg> */}
              <div
                className="ud-text-bold ud-link-underline
instructor-dashboard--resource-unit-title--felaN"
              >
                Help and Support
              </div>
              <div className="ud-text-sm">
                Browse our Help Center or contact our support team.
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
