import React, { useState } from "react";
import TextEditor from "./TextEditor";
const UploadForm = () => {
  const [files, setFiles] = useState([{ image: null, audio: null, text: "" }]);

  // Handle file change for images and audio
  const handleFileChange = (index, type, file) => {
    const newFiles = [...files];
    newFiles[index][type] = file;
    setFiles(newFiles);
  };

  // Handle text input change
  const handleTextChange = (index, text) => {
    const newFiles = [...files];
    newFiles[index].text = text;
    setFiles(newFiles);
  };

  // Add new upload field
  const addField = () => {
    setFiles([...files, { image: null, audio: null, text: "" }]);
  };

  // Remove an upload field
  const removeField = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Upload Images, Audios & Text</h1>
      
      {files.map((file, index) => (
        <div key={index} className="border p-4 rounded-lg mb-4 space-y-4 bg-gray-50">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Upload Image</label>
              <input
                type="file"
                accept="image/*"
                className="mt-2"
                onChange={(e) => handleFileChange(index, "image", e.target.files[0])}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Upload Audio</label>
              <input
                type="file"
                accept="audio/*"
                className="mt-2"
                onChange={(e) => handleFileChange(index, "audio", e.target.files[0])}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Description</label>
            {/* <textarea
              value={file.text}
              onChange={(e) => handleTextChange(index, e.target.value)}
              className="w-full border rounded-lg p-2 mt-2"
              placeholder="Enter description or text"
            /> */}
            <TextEditor/>
          </div>

          {index > 0 && (
            <button
              className="text-red-600 hover:text-red-800"
              onClick={() => removeField(index)}
            >
              Remove
            </button>
          )}
        </div>
      ))}

      <div className="space-x-4">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg"
          onClick={addField}
        >
          Add Another
        </button>
        <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg">
          Submit
        </button>
      </div>
    </div>
  );
};

export default UploadForm;
