import { useState } from "react"

function useFocus(initialValue=false) {

    const [isFocus,setFocus]=useState(initialValue);

    const handleOnFocus=()=>{
        setFocus(true);
    }
    const handleOnBlur=()=>{
        setFocus(false);
    }
    return [isFocus,handleOnBlur,handleOnFocus]
    
}
export default useFocus