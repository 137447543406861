import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './iconStyle.css';
import './buttonStyle.css'
import './textStyle.css'
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './appSlices/authSlice';
import courseReducer from './appSlices/courseSlice';
import chapterReducer from './appSlices/chapterSlice';
import assetReducer from './appSlices/assetSlice';
import { AuthProvider } from './context/authContext';
import { BrowserRouter } from 'react-router-dom';



const store = configureStore({
  reducer: {
    'auth': authReducer,
    'course': courseReducer,
    'chapters':chapterReducer,
    'assets':assetReducer,

  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>


    </Provider>

  </React.StrictMode>
);


