import './mode.css'
import { createPortal } from "react-dom";
const Modal = ({ isOpen, onClose ,onConfirmDelete}) => {
    if (!isOpen) return null;
    return createPortal(
        <div className="modal-dialog-container">
            <span></span>
            <div className="modal-desktop-center">
                <div className="full-page-overlay" role="presentation"></div>
                <div className=" modal-dialog modal-default-size">
                    <div className="modal-title">
                        <h2>Please confirm</h2>
                    </div>
                    you are about to remove a curriculam item be careful buddyy
                    <div className="ud-footer-btns" style={{ textAlign: "right" }}>
                        <button onClick={onClose} type="button" data-purpose="cancel-confirm-modal" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md">
                            <span>Cancel</span>
                        </button>
                        <button onClick={onConfirmDelete} type="button" data-purpose="submit-confirm-modal" className="ud-btn ud-btn-large ud-btn-primary ud-heading-md">
                            <span>OK</span>
                        </button>
                    </div>
                    <button className="modal-button" onClick={onClose}>
                        name
                    </button>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default Modal;
