



import axios from '../API/axios'


const updateCourseCurriculamItems = async (accessToken, courseId, updateCourseCurriculamItemsData) => {
   

    try {
        const response = await axios.put(`courses/${courseId}/instructor-curriculam-items`, { items: JSON.stringify(updateCourseCurriculamItemsData) }, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${accessToken}`
            }
        })
        console.log('the response in update action from server:', response)

        return response.data;

    } catch (error) {

    }
}
const getCourseCurriculamItems=async({accessToken,courseId})=>{
    try {
        const response=await  axios.get(`courses/${courseId}/instructor-curriculam-items`,{
            headers:{
                'Content-Type':'application/json',
                'authorization':`Bearer ${accessToken}`
            }
        })
     console.log('the response from server is :',response.data.response)
        return response.data.response;
        
    } catch (error) {
        
    }
}
const getAllCourse=async(accessToken)=>{
   try {
    const response=await axios.get('users/me/taught-courses',{
        headers:{
            'Content-Type':'application/json',
            'authorization':`Bearer ${accessToken}`
        }

    });
    return response.data.courses
   } catch (error) {
    console.log('the error while hit api ::',error)
    
   }
}

// create asset for lecture

const deleteCourse=async(accessToken,courseId)=>{
    
    try {
        const response=await axios.delete(`courses/${courseId}`,{
            headers:{
                'Content-Type':'application/json',
                'authorization':`Bearer ${accessToken}`
            }
        })
        console.log('the response from server is :',response)
        if (response.status===204) {
            return {courseId};
        }
        else{
            console.log('Failed to delete the course');
            throw new Error('Failed to delete the course');
        }
        
    } catch (error) {
        console.log('the error is :',error);
        
    }
}




const courseService = {
    // createCourse, 
    getCourseCurriculamItems,
    getAllCourse,
    updateCourseCurriculamItems,
    deleteCourse
}



export default courseService;