import { useState, useEffect, useRef } from 'react';
import '../form.css'
import useCharCounter from '../../../hooks/useCharCounter';
import Button from '../../Button';

const EditChapterForm = ({ onClose }) => {


    const inputChapterTitleRef = useRef(null);
    const inputChapterDescriptionRef = useRef(null);
    const maxTitleLength = inputChapterTitleRef.current?.maxLength || null;
    console.log('maxTitleRef', inputChapterTitleRef);
    const maxDescriptionLength = inputChapterDescriptionRef.current?.maxLength || null;
    const [chapterTitle, setChapterTitle] = useState('');
    const [chapterDescription, setChapterDescription] = useState('')


    const handleChapterDescription = (e) => {
        setChapterDescription(e.target.value);
    }
    const handleChapterTitle = (e) => {
        setChapterTitle(e.target.value);
    }
    const remainingTitleChars = useCharCounter(chapterTitle, maxTitleLength);
    const remainingDescriptionChars = useCharCounter(chapterDescription, maxDescriptionLength);

    return (
        <div className='section-editor--section-edit-form--3omY0'>
            <form data-purpose="chapter-form" className="item-form">
                <div className="lp-text-bold item-form-title">New Chapter:</div>
                <div className="item-form-flex">
                    <div data-purpose="chapter-form-group-title" className="lp-form-group">
                        <label className="lp-sr-only lp-form-label lp-heading-sm" htmlFor="form-group--226">Title</label>
                        <div className="ud-text-input-container">
                            <input aria-describedby="text-area-with-counter--228"
                                aria-invalid="false" maxLength="80"
                                placeholder="Enter a Title"
                                data-role="chapter-title" id="form-group--226" type="text" className="lp-text-input lp-text-input-small lp-text-sm"
                                onChange={handleChapterTitle}
                                ref={inputChapterTitleRef}
                                value={chapterTitle}
                            />
                            <span>
                                <span aria-hidden="true" className="text-input-char-counter">{remainingTitleChars.remainingChars}</span>
                                <span className="lp-sr-only" aria-live="polite" id="text-area-with-counter--228">You have 80 characters of 80 remaining.</span>
                            </span>
                            <div className="lp-text-input-box"></div>
                        </div>
                    </div>

                    <div data-purpose="section-form-group-description" className="lp-form-group">
                        <label htmlFor="form-group--229" className="lp-form-label lp-heading-sm">What will students be able to do at the end of this section?</label>
                        <div className="lp-text-input-container">
                            <input aria-describedby="text-area-with-counter--231" aria-invalid="false" maxLength="200" placeholder="Enter a Learning Objective" data-purpose="section-objective" id="form-group--229" type="text"
                                className="lp-text-input lp-text-input-small lp-text-sm"

                                onChange={handleChapterDescription}
                                ref={inputChapterDescriptionRef}
                                value={chapterDescription}
                            />
                            <span>
                                <span aria-hidden="true" className="text-input-char-counter">{remainingDescriptionChars.remainingChars}</span>
                                <span className="lp-sr-only" aria-live="polite" id="text-area-with-counter--231">You have 200 characters of 200 remaining.</span></span>
                            <div className="lp-text-input-box">
                            </div>
                        </div>
                    </div>
                    <div className="item-form-submit-row">
                        <Button onClick={onClose} type="button" className="lp-btn lp-btn-small lp-btn-ghost lp-heading-sm lp-link-neutral" data-role="cancel-chapter-form">
                            <span>Cancel</span>
                        </Button>
                        <Button type="submit" data-role="submit-chapter-form" className="lp-btn lp-btn-small lp-btn-primary lp-heading-sm">
                            <span>Add Chapter</span>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditChapterForm