
import Home from './components/home/Home';
import LoginForm from './components/form/LoginForm';
import ForgetPassword from './components/form/ForgetPassword';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import RegistrationForm from './components/form/RegistrationForm';
import ResetPassword from './components/form/ResetPassword';
import TeacherLayout from './layouts/TeacherLayout';

import { useEffect, useState } from 'react';
import { setCredentials } from './appSlices/authSlice';
import { useDispatch } from 'react-redux';


import {
  IntendedLearners,
  CourseLandingPage,
  CourseStructure, Film,
  Setup, Curriculam, Pricing, Promotions,
  Captions, Accessibility, CourseMessage
} from './components/courseManager';

import GuestLayout from './layouts/GuestLayout';
import Home2 from './components/home/Home2';
import { QA, Tools, Resources, Overview } from './components/teacher';
import Courses from './components/courseManager/Courses'
import CourseManagerLayout from './layouts/CourseManagerLayout';
import AuthWrapper from './components/AuthWrapper';
import PersistLogin from './components/PersistLogin';
import CourseCreator from './components/course/CourseCreator';
import OrgName from './components/organization/OrgHome';
import OrganizationLayout from './layouts/OrganizationLayout';
import Settings from './components/courseManager/Settings';
function App() {

  return (

    <>

      <Routes>
        <Route path='/organization' element={<OrganizationLayout/>}>
        
          <Route path='/organization/global-login/' element={<OrgName />} />
        </Route>
        <Route path='/' element={<GuestLayout />}>
          {/* public routes */}
          <Route index element={<Home2 />} />
          <Route path='/login' element={<LoginForm />} />
          <Route path='/register' element={<RegistrationForm />} />
          <Route path='/forgot-password' element={<ForgetPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />


        </Route>


        {/* these are protected routes for sure */}



        {/* <Route element={<PersistLogin />}> */}


        <Route element={<AuthWrapper />}>

          <Route path='/instructor' element={<TeacherLayout />} >
            <Route path='/instructor/courses' element={<Courses />} />
            <Route path='/instructor/communication/qa' element={<QA />} />
            <Route path='/instructor/tools' element={<Tools />} />
            <Route path='/instructor/help' element={<Resources />} />
          </Route>
          <Route path='/course/create' element={<CourseCreator />} />


          {/* course management layouts  */}
          <Route path='/instructor/course/:courseId/manage' element={<CourseManagerLayout />}>
            <Route path='/instructor/course/:courseId/manage/goals' element={<IntendedLearners />} />
            <Route path='/instructor/course/:courseId/manage/course-structure' element={<CourseStructure />} />
            <Route path='/instructor/course/:courseId/manage/setup' element={<Setup />} />
            <Route path='/instructor/course/:courseId/manage/film' element={<Film />} />
            <Route path='/instructor/course/:courseId/manage/curriculum' element={<Curriculam />} />
            <Route path='/instructor/course/:courseId/manage/captions' element={<Captions />} />
            <Route path='/instructor/course/:courseId/manage/accessibility' element={<Accessibility />} />
            <Route path='/instructor/course/:courseId/manage/settings' element={<Settings />} />
            <Route path='/instructor/course/:courseId/manage/pricing' element={<Pricing />} />
            <Route path='/instructor/course/:courseId/manage/promotions' element={<Promotions />} />
            <Route path='/instructor/course/:courseId/manage/basics' element={<CourseLandingPage />} />
            <Route path='/instructor/course/:courseId/manage/communications/messages' element={<CourseMessage />} />
          </Route>


        </Route>

        {/* </Route> */}
      </Routes>


    </>
  );
}

export default App;
