import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import { story } from '../assets';
import CustomControls from './CustomControls';
const VideoPlayer = () => {

  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showControls, setShowControls] = useState(false)
  const handleMouseEnter = () => setShowControls(true);
  const handleMouseLeave = () => setShowControls(false);

  const handleProgress = (state) => {

    setCurrentTime(Math.floor(state.playedSeconds));
    setProgress(Math.floor(state.played));
  };

  const handleDuration = (duration) => setDuration(duration);

  const handleSeek = (value) => {
    videoRef.current.seekTo(parseFloat(value));
  };
  const handlePlay = () => {
    setIsPlaying((prev) => !prev)

  }
  const handleMute = () => {
    setIsMute(prev => !prev)
  }


  return (
    <div className='flex border-2 border-green-700 p-12 gap-2' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className='relative shadow-xl'>
        <ReactPlayer
          url={story[0].video}
          onProgress={handleProgress}
          onDuration={handleDuration}
          playing={isPlaying}
          light={story[0].thumbnail}
          

          // controls='false'
          ref={videoRef}
        />
        {showControls && <CustomControls
          currentTime={currentTime}
          duration={duration}
          onSeek={handleSeek}
          progress={progress}


          isMute={isMute} isPlaying={isPlaying} onPlay={handlePlay} onMute={handleMute} />}
      </div>
      <div className='flex p-8 border-2 border-blue-700'>
        <p className='font-thin text-slate-800 font-sans'>lorem hfghgfhgfidfg dfjgdfg dfgdflgjdfgdf dfkgjdfg dfgkjfdgdf gdfkgjdfg dfkjgdfg                dfkjdfkjgndf dfgfdjkgdf gdfkjg dfgkjdfgnkdfjgdfkgndfkgndfkgf dgkdfjgndf gfkdjgndf gdfkjgndf gdfkjgdf gkjdfgfd gkdf gdfkg df gkdfjg fdgkj</p>
      </div>
    </div>
  )
}

export default VideoPlayer