import React from 'react'
import { Outlet } from 'react-router-dom'

const OrganizationLayout = () => {
  return (
    <div className='ud-main-content-wrapper'>
        <div className='ud-main-content'>
            <div className='ud-app-loader ud-component-organization-global-login--app up-app-loaded'>
                <Outlet/>

            </div>
        </div>

    </div>
  )
}

export default OrganizationLayout