
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../appSlices/authSlice";
import { MdNotifications } from "react-icons/md";
import { useState } from "react";

import useLogout from "../../hooks/useLogout";

const TeacherTopNavBar = () => {
    const [isHovered, setIsHovered] = useState(null);
    const handleMouseOver = (link) => setIsHovered(link);
    const handleMouseOut = () => setIsHovered(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentUser } = useSelector(state => state.auth);
    // const handleLogout = () => {
    //     console.log('in handle logout');
    //     dispatch(logout());
    //     navigate('/')
    // }

    const logout=useLogout()
    const handleLogout=async()=>{
        // here logout and then navigate to reuqired page
        await logout()
        navigate('/')

    }

    return (
        <div className="ud-app-loader ud-component--instructor-header--instructor-header
        ud-header ud-instructor-header ud-app-loaded" >
            {/* instructor header for small screen */}
            <div className="desktop-header-module--mobile-header--EaZjO desktop-instructor-header-module--mobile-header--Igkhw">

                <div className="mobile-header-module--header--NNgAJ mobile-instructor-header-module--header--9XSqY"
                    aria-hidden="false">
                    <div className="mobile-header-module--row--i-rau">


                        <button type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md ud-btn-icon ud-btn-icon-large ud-mobile-header-btn" data-purpose="side-menu-opener" data-css-toggle-id="header-toggle-side-nav">
                            {/* <svg aria-label="Open side drawer" role="img" focusable="false" className="ud-icon ud-icon-medium">
                    <use xlink:to="#icon-menu">

                    </use>
                </svg> */}
                        </button>
                    </div>



                </div>
                <div className="side-drawer-module--dialog-container--lYo2X">
                    <span id="header-toggle-side-nav"
                        className="ud-full-page-overlay-checkbox side-drawer-module--main-drawer-checkbox--IbTmn"
                        data-type="checkbox" data-checked=""
                        style={{ display: 'none' }}
                        aria-hidden="false">
                    </span>
                    <div role="presentation" className="ud-btn ud-btn-large ud-btn-link ud-heading-md full-page-overlay-module--full-page-overlay--LdwKq"
                        data-css-toggle-id="header-toggle-side-nav" aria-hidden="false">
                    </div>
                    <span id="header-toggle-side-nav-main" className="js-drawer-radio side-drawer-module--drawer-radio--Yvt1r"
                        data-type="radio" data-name="header-toggle-side-nav-radio-group"
                        data-checked=""
                        style={{ display: 'none' }}

                        aria-hidden="false">
                    </span>
                    <div className="js-drawer side-drawer-module--side-drawer--P2LMq side-drawer-module--side-left--ET1w8" role="dialog" tabIndex="-1" aria-labelledby="side-drawer-title--10" aria-modal="false">
                        <nav className="side-drawer-module--drawer-container--riV-1">
                            <div className="mobile-nav-module--nav--JtHq- side-drawer-module--drawer-content--Kws-9">
                                <h2 id="side-drawer-title--10" className="ud-heading-lg ud-sr-only">Site navigation</h2>

                                <ul className="ud-unstyled-list ud-block-list mobile-nav-module--welcome-section--vfSDI">

                                    <li>
                                        <button type="button" className="ud-btn
                            ud-btn-large ud-btn-ghost
                            ud-text-md mobile-nav-module--nav-item--y8jFC
                            mobile-nav-module--highlighted--IOLIz ud-block-list-item
                            ud-block-list-item-large
                            ud-block-list-item-loose ud-block-list-item-neutral"
                                            data-css-toggle-id="header-toggle-side-nav-instructor-profile">
                                            <div className="ud-block-list-item-content">
                                                <span className="mobile-nav-module--welcome-section-content--o39ZH">
                                                    <span
                                                        style={{ display: `inline-flex`, position: `relative` }}>
                                                        <img src="https://img-c.udemycdn.com/user/75x75/181456018_32b7.jpg" alt=""
                                                            className="ud-avatar ud-avatar-image" width="64" height="64" loading="eager"

                                                            style={{ width: '6.4rem', height: '6.4rem' }} />
                                                    </span>
                                                    <span
                                                        style={{ flex: '1 1 0%' }}


                                                    >
                                                        <span className="ud-heading-md mobile-nav-module--profile-name--qj8se">
                                                            Hi, Sarfaraz Hussain
                                                        </span>
                                                        <span className="ud-text-sm mobile-nav-module--profile-welcome----Q-U">
                                                            Welcome back
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                            {/* <svg aria-label="Expand" role="img" focusable="false"
                                    className="ud-icon ud-icon-small ud-block-list-item-icon">
                                    <use xlink:to="#icon-next">
                                    </use>
                                </svg> */}
                                        </button>
                                    </li>
                                </ul>
                                <ul
                                    className="ud-unstyled-list ud-block-list mobile-nav-module--nav-section--hUO4e">
                                    <li>
                                        <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                    mobile-nav-module--nav-item--y8jFC
                    ud-block-list-item ud-block-list-item-large
                    ud-block-list-item-link" to="/">
                                            <div className="ud-block-list-item-content">Switch to student view</div>
                                        </Link>
                                    </li>
                                </ul>

                                <ul className="ud-unstyled-list ud-block-list mobile-nav-module--nav-section--hUO4e">
                                    <li>
                                        <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                    mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large
                    ud-block-list-item-neutral" to="/instructor/courses/">
                                            <div className="ud-block-list-item-content">Courses</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <button type="button"
                                            className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC
                                ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                                            data-css-toggle-id="header-toggle-side-nav-instructor-communication">
                                            <div className="ud-block-list-item-content">Communication</div>
                                            {/* <svg aria-label="Expand" role="img" focusable="false" className="ud-icon
                                    ud-icon-small ud-block-list-item-icon">
                                    <use xlink:to="#icon-next">
                                    </use>
                                </svg> */}
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="ud-btn ud-btn-large
                            ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC
                            ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                                            data-css-toggle-id="header-toggle-side-nav-instructor-performance">
                                            <div className="ud-block-list-item-content">Performance</div>
                                            {/* <svg aria-label="Expand" role="img" focusable="false"
                                    className="ud-icon ud-icon-small ud-block-list-item-icon">
                                    <use xlink:to="#icon-next"></use>
                                </svg> */}
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="ud-btn ud-btn-large ud-btn-ghost
                            ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item
                            ud-block-list-item-large ud-block-list-item-neutral"
                                            data-css-toggle-id="header-toggle-side-nav-instructor-tools">
                                            <div className="ud-block-list-item-content">Tools</div>
                                            {/* <svg aria-label="Expand" role="img" focusable="false"
                                    className="ud-icon ud-icon-small ud-block-list-item-icon">
                                    <use xlink:to="#icon-next"></use>
                                </svg> */}
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" data-css-toggle-id="header-toggle-side-nav-instructor-help"><div className="ud-block-list-item-content">Resources</div>
                                            {/* <svg aria-label="Expand" role="img" focusable="false" className="ud-icon ud-icon-small ud-block-list-item-icon">
                                <use xlink:to="#icon-next"></use></svg> */}
                                        </button></li></ul><ul className="ud-unstyled-list ud-block-list mobile-nav-module--nav-section--hUO4e"><li><Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" to="/instructor/user/view-notifications/"><div className="ud-block-list-item-content">Notifications</div></Link></li><li><button type="button" className="ud-btn ud-btn-large ud-btn-ghost
                    ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large
                    ud-block-list-item-neutral" data-css-toggle-id="header-toggle-side-nav-instructor-account">
                                            <div className="ud-block-list-item-content">Account settings</div>
                                            {/* <svg aria-label="Expand" role="img" focusable="false" className="ud-icon
                                ud-icon-small ud-block-list-item-icon">
                                    <use xlink:to="#icon-next">

                                    </use>
                                </svg> */}
                                        </button>
                                        </li>
                                    <li>
                                        <Link className="ud-btn ud-btn-large ud-btn-ghost
                            ud-text-md mobile-nav-module--nav-item--y8jFC
                            ud-block-list-item ud-block-list-item-large
                            ud-block-list-item-neutral" to="/instructor/user/edit-instructor-info/">
                                            <div className="ud-block-list-item-content">Payout &amp; tax settings</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large
                                ud-block-list-item-neutral" to="/mobile/" target="_blank" rel="noopener noreferrer">
                                            <div className="ud-block-list-item-content">Get the app</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <button onClick={handleLogout} className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                        mobile-nav-module--nav-item--y8jFC ud-block-list-item
                                        ud-block-list-item-large ud-block-list-item-neutral"

                                        // to="/user/logout/?h=3%40g_oUyLYKVxxD6nA0oW-TvBcyNdqmC_ESOvUKWhppQAzT0pjSbQmRnyY4MO_rqJQ7WbVs"
                                        >
                                            <div className="ud-block-list-item-content">Log out</div>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <span id="header-toggle-side-nav-instructor-account"
                                className="js-drawer-radio side-drawer-module--drawer-radio--Yvt1r" data-type="radio"
                                data-name="header-toggle-side-nav-radio-group" data-checked=""
                                style={{ display: 'none' }}>
                            </span>
                            <div-l1 className="mobile-nav-module--nav--JtHq- js-drawer side-drawer-module--side-drawer--P2LMq"
                                style={{ zIndex: `1` }}>
                                <div className="side-drawer-module--drawer-content--Kws-9">
                                    <ul className="ud-unstyled-list ud-block-list">
                                        <li>
                                            <button type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC mobile-nav-module--highlighted--IOLIz ud-block-list-item ud-block-list-item-large ud-block-list-item-loose ud-block-list-item-neutral" data-css-toggle-id="header-toggle-side-nav-main">
                                                {/* <svg aria-label="Go back" role="img" focusable="false"
                                        className="ud-icon ud-icon-small ud-block-list-item-icon">
                                        <use xlink:to="#icon-previous">
                                        </use>
                                    </svg> */}
                                                <div className="ud-block-list-item-content">Menu</div>
                                            </button>
                                        </li>
                                    </ul>


                                    <ul className="ud-unstyled-list ud-block-list mobile-nav-module--nav-section--hUO4e">
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                            mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large
                            ud-block-list-item-neutral" to="/instructor/account/security/">
                                                <div className="ud-block-list-item-content">Account security</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" to="/instructor/account/notifications/">
                                                <div className="ud-block-list-item-content">Notification settings</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" to="/instructor/account/api/">
                                                <div className="ud-block-list-item-content">API clients</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                mobile-nav-module--nav-item--y8jFC
                                ud-block-list-item ud-block-list-item-large
                                ud-block-list-item-neutral" to="/instructor/account/close/">
                                                <div className="ud-block-list-item-content">Close account</div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div-l1>

                            <span id="header-toggle-side-nav-instructor-help" className="js-drawer-radio side-drawer-module--drawer-radio--Yvt1r" data-type="radio" data-name="header-toggle-side-nav-radio-group" data-checked=""
                                style={{ display: 'none' }}></span>
                            <div-l1 className="mobile-nav-module--nav--JtHq- js-drawer side-drawer-module--side-drawer--P2LMq"
                                style={{ zIndex: `1` }}


                            >
                                <div className="side-drawer-module--drawer-content--Kws-9">
                                    <ul className="ud-unstyled-list ud-block-list">
                                        <li>
                                            <button type="button" className="ud-btn
                                ud-btn-large ud-btn-ghost ud-text-md
                                mobile-nav-module--nav-item--y8jFC
                                mobile-nav-module--highlighted--IOLIz
                                ud-block-list-item ud-block-list-item-large
                                ud-block-list-item-loose ud-block-list-item-neutral"
                                                data-css-toggle-id="header-toggle-side-nav-main">
                                                {/* <svg aria-label="Go back" role="img"
                                        focusable="false" className="ud-icon ud-icon-small
                                    ud-block-list-item-icon">
                                        <use xlink:to="#icon-previous"></use>
                                    </svg> */}
                                                <div className="ud-block-list-item-content">Menu</div>
                                            </button>
                                        </li>
                                    </ul>
                                    <ul className="ud-unstyled-list ud-block-list mobile-nav-module--nav-section--hUO4e">
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large
                                ud-block-list-item-neutral"
                                                to="/udemy-teach-hub/?ref=teach_header"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <div className="ud-block-list-item-content">Teaching Center</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" to="https://community.udemy.com" target="_blank" rel="noopener noreferrer">
                                                <div className="ud-block-list-item-content">Instructor Community</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                mobile-nav-module--nav-item--y8jFC
                                ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                                                to="/support/?type=instructor" target="_blank" rel="noopener noreferrer">
                                                <div className="ud-block-list-item-content">Help and Support</div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div-l1>




                            <span id="header-toggle-side-nav-instructor-tools" className="js-drawer-radio side-drawer-module--drawer-radio--Yvt1r" data-type="radio" data-name="header-toggle-side-nav-radio-group" data-checked=""
                                style={{ display: `none` }}

                            ></span>
                            <div-l1 className="mobile-nav-module--nav--JtHq- js-drawer side-drawer-module--side-drawer--P2LMq"
                                style={{ zIndex: `1` }}

                            >
                                <div className="side-drawer-module--drawer-content--Kws-9">
                                    <ul className="ud-unstyled-list ud-block-list">
                                        <li>
                                            <button type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC mobile-nav-module--highlighted--IOLIz ud-block-list-item ud-block-list-item-large ud-block-list-item-loose ud-block-list-item-neutral" data-css-toggle-id="header-toggle-side-nav-main">
                                                {/* <svg aria-label="Go back" role="img"
                                        focusable="false" className="ud-icon ud-icon-small
                                ud-block-list-item-icon">
                                        <use xlink:to="#icon-previous"></use>
                                    </svg> */}
                                                <div className="ud-block-list-item-content">Menu</div>
                                            </button>
                                        </li>
                                    </ul>
                                    <ul className="ud-unstyled-list ud-block-list
                        mobile-nav-module--nav-section--hUO4e">
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                mobile-nav-module--nav-item--y8jFC ud-block-list-item
                                ud-block-list-item-large ud-block-list-item-neutral" to="/home/teaching/test-video/">
                                                <div className="ud-block-list-item-content">Test Video</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                    mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large
                                    ud-block-list-item-neutral" to="/instructor/marketplace-insights/">
                                                <div className="ud-block-list-item-content">Marketplace Insights</div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div-l1>
                            <span id="header-toggle-side-nav-instructor-performance"
                                className="js-drawer-radio side-drawer-module--drawer-radio--Yvt1r"
                                data-type="radio"
                                data-name="header-toggle-side-nav-radio-group"
                                data-checked="checked"
                                style={{ display: 'none' }}
                            >

                            </span>
                            <div-l1 className="mobile-nav-module--nav--JtHq-
                js-drawer side-drawer-module--side-drawer--P2LMq"

                                style={{ zIndex: `1` }}

                            >
                                <div className="side-drawer-module--drawer-content--Kws-9">
                                    <ul className="ud-unstyled-list ud-block-list">
                                        <li>
                                            <button
                                                type="button" className="ud-btn ud-btn-large
                                ud-btn-ghost ud-text-md
                                mobile-nav-module--nav-item--y8jFC
                                mobile-nav-module--highlighted--IOLIz
                                ud-block-list-item ud-block-list-item-large
                                ud-block-list-item-loose ud-block-list-item-neutral"
                                                data-css-toggle-id="header-toggle-side-nav-main">
                                                {/* <svg aria-label="Go back" role="img" focusable="false"
                                        className="ud-icon ud-icon-small ud-block-list-item-icon">
                                        <use xlink:to="#icon-previous"></use></svg> */}
                                                <div className="ud-block-list-item-content">Menu</div>
                                            </button>
                                        </li>
                                    </ul>
                                    <ul className="ud-unstyled-list ud-block-list mobile-nav-module--nav-section--hUO4e">
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" to="/instructor/performance/overview/revenue/?date_filter=year&amp;course_id=">
                                                <div className="ud-block-list-item-content">Overview</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC
                                ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                                                to="/instructor/performance/students/?course_id=">
                                                <div className="ud-block-list-item-content">Students</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                        mobile-nav-module--nav-item--y8jFC ud-block-list-item
                                        ud-block-list-item-large ud-block-list-item-neutral"
                                                to="/instructor/performance/reviews/?course_id=">
                                                <div className="ud-block-list-item-content">Reviews</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                                                mobile-nav-module--nav-item--y8jFC ud-block-list-item
                                                ud-block-list-item-large ud-block-list-item-neutral"
                                                to="/instructor/performance/engagement/?date_filter=year&amp;course_id=">
                                                <div className="ud-block-list-item-content">Course engagement</div></Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost
                                                        ud-text-md mobile-nav-module--nav-item--y8jFC
                                                        ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                                                to="/instructor/performance/conversion/visitors?date_filter=year&amp;course_id=">
                                                <div className="ud-block-list-item-content">Traffic &amp; conversion</div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div-l1>


                            <span id="header-toggle-side-nav-instructor-communication"
                                className="js-drawer-radio side-drawer-module--drawer-radio--Yvt1r"
                                data-type="radio" data-name="header-toggle-side-nav-radio-group"
                                data-checked=""
                                style={{ display: 'none' }}

                            >

                            </span>
                            <div-l1 className="mobile-nav-module--nav--JtHq- js-drawer side-drawer-module--side-drawer--P2LMq"

                                style={{ zIndex: '1' }}

                            >
                                <div className="side-drawer-module--drawer-content--Kws-9">
                                    <ul className="ud-unstyled-list ud-block-list">
                                        <li>
                                            <button type="button" className="ud-btn ud-btn-large ud-btn-ghost ud-text-md
                         mobile-nav-module--nav-item--y8jFC mobile-nav-module--highlighted--IOLIz
                 ud-block-list-item ud-block-list-item-large ud-block-list-item-loose
                                ud-block-list-item-neutral"
                                                data-css-toggle-id="header-toggle-side-nav-main">
                                                {/* <svg aria-label="Go back" role="img" focusable="false"
                                        className="ud-icon ud-icon-small ud-block-list-item-icon">
                                        <use xlink:to="#icon-previous">

                                        </use>
                                    </svg> */}
                                                <div className="ud-block-list-item-content">Menu</div>
                                            </button>
                                        </li>
                                    </ul>
                                    <ul
                                        className="ud-unstyled-list ud-block-list mobile-nav-module--nav-section--hUO4e">
                                        <li>
                                            <Link
                                                className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                                                to="/instructor/communication/qa/">
                                                <div className="ud-block-list-item-content">Q&amp;A</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" to="/instructor/communication/messages/">
                                                <div className="ud-block-list-item-content">Messages</div>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" to="/instructor/communication/assignments/">
                                                <div className="ud-block-list-item-content">Assignments</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                                                to="/instructor/communication/announcements/">
                                                <div className="ud-block-list-item-content">Announcements</div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div-l1>
                            <span id="header-toggle-side-nav-instructor-profile" className="js-drawer-radio side-drawer-module--drawer-radio--Yvt1r" data-type="radio" data-name="header-toggle-side-nav-radio-group" data-checked=""
                                style={{ display: 'none' }}

                            >
                            </span>
                            <div-l1
                                className="mobile-nav-module--nav--JtHq- js-drawer side-drawer-module--side-drawer--P2LMq"
                                style={{ zIndex: 1 }}>
                                <div className="side-drawer-module--drawer-content--Kws-9">
                                    <ul className="ud-unstyled-list ud-block-list">
                                        <li>
                                            <button type="button" className="ud-btn
                                ud-btn-large ud-btn-ghost
                                ud-text-md mobile-nav-module--nav-item--y8jFC
                                mobile-nav-module--highlighted--IOLIz
                                ud-block-list-item ud-block-list-item-large
                                ud-block-list-item-loose ud-block-list-item-neutral"
                                                data-css-toggle-id="header-toggle-side-nav-main">
                                                {/* <svg aria-label="Go back" role="img"
                                        focusable="false" className="ud-icon ud-icon-small
                                    ud-block-list-item-icon">
                                        <use xlink:to="#icon-previous"></use>
                                    </svg> */}
                                                <div className="ud-block-list-item-content">Menu</div>
                                            </button>
                                        </li>
                                    </ul>
                                    <ul className="ud-unstyled-list ud-block-list
                         mobile-nav-module--nav-section--hUO4e">
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost
                                ud-text-md mobile-nav-module--nav-item--y8jFC
                                ud-block-list-item ud-block-list-item-large
                                ud-block-list-item-neutral"
                                                to="/user/sarfaraz-hussain-28/"
                                                target="_blank" rel="nofollow noopener noreferrer">
                                                <div className="ud-block-list-item-content">Public profile</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral"
                                                to="/instructor/profile/basic-information/">
                                                <div className="ud-block-list-item-content">Udemy profile</div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost ud-text-md mobile-nav-module--nav-item--y8jFC ud-block-list-item ud-block-list-item-large ud-block-list-item-neutral" to="/instructor/profile/photo/">
                                                <div className="ud-block-list-item-content">Profile picture</div></Link>
                                        </li>
                                        <li>
                                            <Link className="ud-btn ud-btn-large ud-btn-ghost
                                ud-text-md mobile-nav-module--nav-item--y8jFC
                                ud-block-list-item ud-block-list-item-large
                                ud-block-list-item-neutral"
                                                to="/instructor/profile/privacy/">
                                                <div className="ud-block-list-item-content">Privacy settings</div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div-l1>
                        </nav>
                        <button type="button" className="ud-btn ud-btn-large ud-btn-white-solid
            ud-btn-round ud-heading-md ud-btn-icon ud-btn-icon-large
            ud-btn-icon-round
            dialog-close-button-module--close-btn--KgmlW
            side-drawer-module--close-btn--nyYl0"
                            data-purpose="close-btn" aria-label="Close side drawer"
                            data-css-toggle-id="header-toggle-side-nav">
                            {/* <svg aria-hidden="true" focusable="false"
                    className="ud-icon ud-icon-medium ud-icon-color-neutral">
                    <use xlink:to="#icon-close"></use>
                </svg> */}
                        </button>
                    </div>
                </div>
            </div>



            {/* instructor top header */}
            <div className="ud-text-sm desktop-header-module--header--a5wri desktop-instructor-header-module--header--JdaD8" data-purpose="header" aria-hidden="false">
                <div
                    style={{ flex: '1 1 0%' }}

                >
                </div>


                <div
                    className={`popper-module--popper--mM5Ie ${isHovered === 'student' ? "ud-popper-open" : ""} desktop-header-module--gap-button--4drhI`}>
                    <Link onMouseOver={() => handleMouseOver('student')}
                        onMouseOut={handleMouseOut} to="/" data-testid="instructor-dropdown" id="popper-trigger--11"
                        aria-describedby="popper-content--12"
                        tabIndex="0"
                        className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md
            js-header-button desktop-header-module--dropdown-button--ipas9">
                        <span className="ud-text-sm desktop-header-module--dropdown-button-text--Sq73l">Student</span>
                    </Link>
                    <div className={`popper-module--popper-content--XE9z5 ${isHovered === 'student' ? "ud-popper-open" : ''}
                desktop-header-module--dropdown--pZls3" id="popper-content--12`}

                        style={{ top: '100%', right: '0px' }}

                    >
                        <div className="popper-module--animation-wrapper--tM0UK">
                            <div className="popover-module--popover--HrSFz popover-module--popover-bottom--1ghkR">
                                <div className="popover-module--inner--yQvGI">
                                    <div className="ud-header-menu desktop-header-module--menu--oIYSV"
                                        data-testid="header-menu">
                                        <div className="panel-menu-module--panel--FrCW7"
                                            data-testid="switch-view-panel">
                                            Switch to the student view here - get back to the courses you’re taking.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`popper-module--popper--mM5Ie ${isHovered === 'notifications' ? 'ud-popper-open' : ''}`}>
                    <Link onMouseOver={() => handleMouseOver('notifications')}
                        onMouseOut={handleMouseOut} to="/instructor/user/view-notifications/"
                        id="popper-trigger--13" aria-expanded="false"
                        tabIndex="0" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md
        ud-btn-icon ud-btn-icon-large js-header-button
        desktop-header-module--dropdown-button--ipas9">
                        <MdNotifications aria-label="Notifications" role="img"
                            focusable="false" className="ud-icon ud-icon-medium
            ud-icon-color-neutral"/>

                        {/* <svg stroke="currentColor" fill="currentColor" stroke-width="0"
            viewBox="0 0 24 24" aria-label="Notifications"
            role="img" focusable="false"
            class="ud-icon ud-icon-medium ud-icon-color-neutral"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22c1.1 0 2-.9 2-2h-4a2 2 0 002 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"></path>
        </svg> */}

                    </Link>
                    <div className={`popper-module--popper-content--XE9z5 ${isHovered === 'notifications' ? 'ud-popper-open' : ''} desktop-header-module--dropdown--pZls3`}
                        id="popper-content--14" aria-labelledby="popper-trigger--13"

                        style={{ top: '100%', right: '0px' }}

                    >
                        <div className="popper-module--animation-wrapper--tM0UK">
                            <div className="popover-module--popover--HrSFz
                popover-module--popover-bottom--1ghkR">
                                <div className="popover-module--inner--yQvGI">
                                    <div className="ud-header-menu desktop-header-module--menu--oIYSV"
                                        data-testid="header-menu">
                                        <div className="notification-items-module--notification-items--Mfzqz">
                                            <div className="panel-menu-module--section-heading--rus-R panel-menu-module--gap-bottom---DDBJ">
                                                <div className="ud-heading-lg panel-menu-module--section-heading-title--DpCwA">Notifications</div>
                                                <Link className="ud-heading-sm panel-menu-module--section-heading-link--x-Yyg"
                                                    to="/instructor/account/notifications/">Settings</Link>
                                            </div>
                                            <div className="panel-menu-module--panel--FrCW7 notification-items-module--panel--xkfl9"
                                                data-testid="notification-items-skeleton">
                                                <div className="course-card-skeleton-group-module--skeleton--Y4zI0">
                                                    <div className="course-card-skeleton-group-module--row--Q-1LW">
                                                        <div data-purpose="course-card-skeleton"
                                                            className="skeleton-module--skeleton--7T3hc
                                                course-card-skeleton-module--skeleton-flexible--tcvBn
                                                course-card-skeleton-module--skeleton-small--ZOtHj"


                                                            style={{ width: '32.8rem', maxWidth: '32.8rem', minWidth: '32.8rem' }}

                                                        >
                                                            <span className="block-module--block--Tqs0a course-card-skeleton-module--image--b607E"

                                                                style={{ width: '6.4rem', height: '6.4rem', borderRadius: '50%' }}
                                                            >

                                                            </span>
                                                            <div
                                                                style={{ flex: '1 1 0%' }}

                                                            >
                                                                <span className="block-module--block--Tqs0a course-card-skeleton-module--title--nO73n">

                                                                </span>
                                                                <span className="block-module--block--Tqs0a course-card-skeleton-module--line--xEQ8Z">
                                                                </span><span className="block-module--block--Tqs0a course-card-skeleton-module--line--xEQ8Z">
                                                                </span><span className="block-module--block--Tqs0a course-card-skeleton-module--line--xEQ8Z">

                                                                </span>
                                                            </div>
                                                            <div className="skeleton-module--shine--rhamL">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="course-card-skeleton-group-module--row--Q-1LW">
                                                        <div data-purpose="course-card-skeleton"
                                                            className="skeleton-module--skeleton--7T3hc
                                            course-card-skeleton-module--skeleton-flexible--tcvBn
                                            course-card-skeleton-module--skeleton-small--ZOtHj"
                                                            style={{ width: '32.8rem', maxWidth: '32.8rem', minWidth: '32.8rem' }}

                                                        >
                                                            <span className="block-module--block--Tqs0a course-card-skeleton-module--image--b607E"


                                                                style={{ width: '6.4rem', height: '6.4rem', borderRadius: '50%' }}
                                                            >

                                                            </span>
                                                            <div
                                                                style={{ flex: '1 1 0%' }}
                                                            >
                                                                <span className="block-module--block--Tqs0a course-card-skeleton-module--title--nO73n">
                                                                </span>
                                                                <span className="block-module--block--Tqs0a course-card-skeleton-module--line--xEQ8Z">
                                                                </span>
                                                                <span className="block-module--block--Tqs0a course-card-skeleton-module--line--xEQ8Z">
                                                                </span><span className="block-module--block--Tqs0a course-card-skeleton-module--line--xEQ8Z">
                                                                </span>
                                                            </div>
                                                            <div className="skeleton-module--shine--rhamL"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div onMouseOver={() => handleMouseOver('basic-information')}
                    onMouseOut={handleMouseOut}
                    className={`popper-module--popper--mM5Ie  list-menu-module--list-menu-container--8oKTW ${isHovered === 'basic-information' ? 'ud-popper-open' : ''}`}>
                    <Link to="/instructor/profile/basic-information/"
                        aria-label="My profile"
                        data-purpose="user-dropdown"
                        id="popper-trigger--15"
                        aria-expanded="false"
                        tabIndex="0"
                        className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md js-header-button desktop-header-module--dropdown-button--ipas9">
                        <img aria-hidden="true" src="https://img-c.udemycdn.com/user/75x75/181456018_32b7.jpg"
                            alt="" className="user-profile-dropdown-module--dropdown-button-avatar--CffDQ ud-avatar ud-avatar-image"
                            width="32" height="32" loading="eager"
                            style={{ width: '3.2rem', height: '3.2rem' }}

                        />
                    </Link>

                    {/* pop over for basic-information of user */}
                    <div className={`popper-module--popper-content--XE9z5 ${isHovered === 'basic-information' ? 'ud-popper-open' : ''} desktop-header-module--dropdown--pZls3`}
                        id="popper-content--16" aria-labelledby="popper-trigger--15"
                        style={{ top: '100%', right: '0px' }}

                    >
                        <div className="popper-module--animation-wrapper--tM0UK">
                            <div className="popover-module--popover--HrSFz popover-module--popover-bottom--1ghkR">
                                <div className="popover-module--inner--yQvGI">
                                    <div className="ud-header-menu desktop-header-module--menu--oIYSV" data-testid="header-menu">
                                        <Link to="/instructor/profile/basic-information/" className="user-profile-dropdown-module--user-section--WncyJ">
                                            <img src="https://img-c.udemycdn.com/user/75x75/181456018_32b7.jpg" alt=""
                                                className="ud-avatar ud-avatar-image" width="64" height="64" loading="eager"


                                                style={{ width: '6.4rem', height: '6.4rem' }}
                                            />
                                            <div className="user-profile-dropdown-module--user-details--gQuWe">
                                                <div>
                                                    <div className="ud-heading-md">Sarfaraz Hussain</div>
                                                    <div className="ud-text-xs user-profile-dropdown-module--email--eLeEE">sfzalig@gmail.com</div>
                                                </div>
                                            </div>
                                        </Link>
                                        <ul className="ud-unstyled-list ud-block-list list-menu-module--section--l6I-M">
                                            <li>
                                                <Link to="/" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral">
                                                    <div className="ud-block-list-item-content">Student</div></Link>
                                            </li>
                                        </ul>
                                        <ul className="ud-unstyled-list ud-block-list list-menu-module--section--l6I-M">
                                            <li>
                                                <Link to="/instructor/user/view-notifications/" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral">
                                                    <div className="ud-block-list-item-content">Notifications</div>
                                                </Link>
                                            </li>
                                        </ul>
                                        <ul className="ud-unstyled-list ud-block-list list-menu-module--section--l6I-M">
                                            <li>
                                                <Link to="/instructor/account/security/" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral">
                                                    <div className="ud-block-list-item-content">Account settings</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/instructor/user/edit-instructor-info/" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral">
                                                    <div className="ud-block-list-item-content">Payout &amp; tax settings</div>
                                                </Link>
                                            </li>
                                        </ul>
                                        <ul className="ud-unstyled-list ud-block-list list-menu-module--section--l6I-M">
                                            <li>
                                                <Link to="/user/sarfaraz-hussain-28/" target="_blank" rel="nofollow noopener noreferrer" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral">
                                                    <div className="ud-block-list-item-content">Public profile</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/instructor/profile/basic-information/" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral">
                                                    <div className="ud-block-list-item-content">Edit profile</div>
                                                </Link>
                                            </li>
                                        </ul>
                                        <ul className="ud-unstyled-list ud-block-list list-menu-module--section--l6I-M">
                                            <li>
                                                <Link to="/support/?type=instructor" target="_blank" rel="noopener noreferrer" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral">
                                                    <div className="ud-block-list-item-content">Help</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <button 
                                                onClick={handleLogout}
                                                // to="/user/logout/?h=3%40g_oUyLYKVxxD6nA0oW-TvBcyNdqmC_ESOvUKWhppQAzT0pjSbQmRnyY4MO_rqJQ7WbVs" 
                                                className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral"
                                                >
                                                    <div className="ud-block-list-item-content">Log out</div>
                                                </button>
                                            </li>
                                        </ul>
                                        {/* <Link className="user-profile-dropdown-module--try-ufb-section--5QPnu" to="/udemy-business/request-demo-in-mx/?ref=account-menu&amp;locale=en_US" target="_blank" rel="noopener">
                            <div>
                                <div className="ud-heading-md">Udemy Business</div>
                                <div className="ud-text-sm user-profile-dropdown-module--try-ufb-subtitle--NUCjg">Bring learning to your company</div>
                            </div>
                            <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-medium ud-icon-color-neutral"><use xlink:to="#icon-open-in-new"></use></svg>
                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </div>
    )
}

export default TeacherTopNavBar;