// import axios from "../API/axios";

import axios from "../API/axios";

import useAuth from "./useAuth";
const useLogout=()=>{
    const {setAuth}=useAuth();

    const logout=async()=>{
        try {
            const response=await axios.post('http://localhost:30001/api/auth/logout',{ withCredentials:true});
            return response.data
        } catch (error) {
         console.log('the errir is :',error)   ;
        }
    }

    return logout
}

export default useLogout;