
const SubHeader = ({pageTitle}) => {
    return (
        <div className='sub-header--flex-align-center--1_RER sub-header--container--1pQeg'>
            <div className="sub-header--flex-align-center--1_RER sub-header--content--1H7YJ">
                <h2 data-purpose="page-title" className="ud-heading-serif-xl">{pageTitle}</h2>
            </div>
        </div>
    )
}

export default SubHeader