
import SubHeader from '../../SubHeader'
const CourseLandingPage = () => {
  return (
    <>
      <SubHeader />




      <div className='main-content--content--1Myl4'></div>

    </>
  )
}

export default CourseLandingPage