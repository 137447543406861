import { useRef, useState } from 'react';
import useCharCounter from '../../../hooks/useCharCounter';
import { MdPublish } from 'react-icons/md';


const EditPageForm = ({ onClose }) => {
  const inputEditedPageTitleRef = useRef(null);

  const maxTitleLength = inputEditedPageTitleRef.current?.maxLength || null;

  const [editedPageTitle, setEditedPageTitle] = useState('');

  const handleEditedPageTitle = (e) => {
    setEditedPageTitle(e.target.value);
  }

  const remainingEditedPageChars = useCharCounter(editedPageTitle, maxTitleLength);

  return (
    <form data-purpose="page-form" className="item-form">
      <div className="item-form-title">
        <MdPublish aria-hidden="true" focusable="false" data-purpose="published-icon"
          className="ud-icon ud-icon-xsmall ud-icon-color-neutral" />
        Page 3:
      </div>
      <div className="item-form-flex">
        <div data-purpose="lecture-form-group-title" className="ud-form-group">
          <label className="ud-sr-only ud-form-label ud-heading-sm" htmlFor="form-group--437">Title</label>
          <div className="ud-text-input-container">
            <input aria-describedby="text-area-with-counter--439" aria-invalid="false" maxLength="80"
              placeholder="Enter a Title" data-purpose="lecture-title" id="form-group--437" type="text"
              className="ud-text-input ud-text-input-small ud-text-sm"
              value={editedPageTitle}
              ref={inputEditedPageTitleRef}

              onChange={handleEditedPageTitle}

            />
            <span>
              <span aria-hidden="true" className="text-input-with-counter-module--counter--F7OMr">{remainingEditedPageChars.remainingChars}</span>
              <span className="ud-sr-only" aria-live="polite" id="text-area-with-counter--439">You have 72 characters
                of 80
                remaining.</span>
            </span>
            <div className="ud-text-input-box"></div>
          </div>
        </div>
        <div className="item-form-submit-row">
          <button onClick={onClose} type="button" className="ud-btn ud-btn-small ud-btn-ghost ud-heading-sm ud-link-neutral"
            data-purpose="cancel-lecture-form">
            <span>Cancel</span>
          </button>
          <button type="submit" data-purpose="submit-page-form"
            className="ud-btn ud-btn-small ud-btn-primary ud-heading-sm">
            <span>Save Page</span>
          </button>
        </div>
      </div>
    </form>
  )
}

export default EditPageForm