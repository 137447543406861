import React from 'react'
import SubHeader from '../SubHeader';
import { MdDelete } from 'react-icons/md';
import Button from '../Button';
const Settings = () => {
    return (
        <div className='main-content--content--1Myl4 main-content--wide--yPj7d'>

            <div>
                <SubHeader pageTitle='Settings' />
                <div>
                    <Button classname='' type='button'>
                        <MdDelete />
                        <span>delete</span>
                    </Button>

                </div>
                <div></div>
            </div>
        </div>
    )
}

export default Settings