import SubHeader from '../../SubHeader'
const Setup = () => {
  return (
    <div className='main-content--content--1Myl4 main-content--wide--yPj7d'>

        <div>
            <SubHeader pageTitle='Setup &amp; test video' />
            <div></div>
            <div></div>
        </div>
    </div>
  )
}

export default Setup