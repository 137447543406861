import { useState, useEffect, useRef } from 'react';
import '../form.css'
// import { useForm } from 'react-hook-form';
import useCharCounter from '../../../hooks/useCharCounter';
import useAuth from '../../../hooks/useAuth';
import { useDispatch } from 'react-redux';
// import { createPageAction } from '../../../appSlices/pageSlice';
import { useParams } from 'react-router-dom';
import { addPageInCourseCurriculam } from '../../../appSlices/courseSlice';
const NewPageForm = ({ chapterId, onClose }) => {
    console.log('the chapter id in New Page form component', chapterId)
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const { courseId } = useParams()
    const inputNewPageTitleRef = useRef(null);
    const maxTitleLength = inputNewPageTitleRef.current?.maxLength || null;
    const [newPageTitle, setNewPageTitle] = useState('');
    const handleNewPageTitle = (e) => {
        setNewPageTitle(e.target.value);


    }

    const addPage = (e) => {
        e.preventDefault();
        const pageData = {
            title: newPageTitle,
            chapterId: chapterId
        }

        dispatch(addPageInCourseCurriculam({ pageData, accessToken: auth.accessToken, courseId }))

        setTimeout(() => {
            onClose();
        }, 1000);

    }
    const remainingNewPageChars = useCharCounter(newPageTitle, maxTitleLength);
    return (
        <form className='item-form' data-role='page-form' onSubmit={addPage}>
            <div className='item-form-title'>New Page:</div>


            <div className='item-form-flex'>

                <div data-role='page-form-group-title' className='lp-form-group'>

                    <label className="lp-sr-only lp-heading-sm lp-form-label" htmlFor="form-group--157">Title</label>

                    <div className="lp-text-input-container">
                        {/* first */}
                        <input

                            aria-describedby="text-area-with-counter--159" aria-invalid="false"
                            placeholder="Enter a Title"
                            data-purpose="lecture-title" maxLength='80' id="form-group--157" type="text"
                            className="lp-text-input lp-text-input-small lp-text-sm" value={newPageTitle}
                            onChange={handleNewPageTitle}
                            ref={inputNewPageTitleRef}
                        />
                        {/* second */}
                        <span>
                            <span aria-hidden="true" className="text-input-char-counter">{remainingNewPageChars.remainingChars}</span>


                            <span className="lp-sr-only" aria-live="polite" id="text-area-with-counter--159">You have {remainingNewPageChars.remainingChars} characters
                                of 80
                                remaining.
                            </span>


                        </span>

                        {/* third */}
                        <div className="lp-text-input-box"></div>
                    </div>
                </div>


                <div className='item-form-submit-row'>
                    <button onClick={onClose} type='button' data-role='cancel-page-form'
                        className='lp-btn lp-btn-small lp-btn-ghost lp-link-neutral lp-heading-sm '>
                        <span>Cancel</span>
                    </button>
                    <button type='submit' data-role='submit-page-form'
                        className='lp-btn lp-btn-small lp-btn-primary lp-heading-sm'>
                        <span>Add Page</span>
                    </button>
                </div>
            </div>

        </form>
    )
}

export default NewPageForm