import React from 'react'
import useToggle from '../../hooks/useToggle';
import NewChapterForm from '../form/chapter/NewChapterForm';
import { MdAdd } from 'react-icons/md';
const NewChapterButton = () => {
    const [toggle,setToggle]=useToggle(false)
    const handleToggle=()=>{
        setToggle(prev=>!prev)
    }
  return (
    <div>
   {/* new section or chapter addition button  only on last page iteem */}
   <div className="curriculum-list--inline-insert curriculum-list--inline-insert-section">
                    <div className="curriculum-list--inline-insert-button-row">
                        <button onClick={handleToggle} type="button"
                            data-role="add-item-inline-last"
                            className={`ud-btn ud-btn-small ud-heading-sm add-item--add-item-toggler-last-item add-item--section--J21gT ${toggle ? 'ud-btn-ghost add-item--open--NpnAX' : 'ud-btn-secondary'}`}>
                            <MdAdd aria-label="New curriculum item" role="img" focusable="false" className={`ud-icon ud-icon-small add-item--add-item-toggler-last-item add-item--expand-icon--9X-PA add-item--section--J21gT ${toggle ? '' : 'add-item--open--NpnAX'}`} />
                            {!toggle && <span>Chapter</span>}
                        </button>
                    </div>
                    {/* new section created will appear here */}
                    {toggle && <NewChapterForm onClose={handleToggle} />}
                </div>


    </div>
  )
}

export default NewChapterButton

