



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import assetService from "../apiServices/asset";


// async action definition || server communication

export const createAssetByUserAction=createAsyncThunk('assets/addAsset',async({assetData,type,accessToken})=>{
    console.log('the assets in action  :',assetData)
    for (let [key, value] of assetData.entries()) {
        console.log(`${key}:`, value);
      }
    try {
        const response=await assetService.createAsset(assetData,type,accessToken);
        return response
        
    } catch (error) {
        console.log('error is:',error);
    }
})



const assetSlice=createSlice({
    name:'assets',
    initialState:{
        newAsset:null,
        status:'idle',
        error:null

    },

    extraReducers:(builder)=>{
        builder
        .addCase(createAssetByUserAction.pending,(state)=>{
            state.status='pending'
        })
        .addCase(createAssetByUserAction.fulfilled,(state,action)=>{
            state.status='succeeded'
            state.newAsset=action.payload
        })
        .addCase(createAssetByUserAction.rejected,(state,action)=>{
            state.status='failed';
            state.error=action.payload
        })

    }

})

export default assetSlice.reducer;