import axios from "./axios";



export const login=async(credentials)=>{
    
    const response=await axios.post('/auth/signin',credentials,{
        headers:{
            'Content-Type':'application/json',
            withCredentials:true
        }
    });
    return response.data;
}