import { useEffect } from "react";
import { bgIlm } from "../../assets";
const OrgName = () => {

  useEffect(() => {
    // here add background image to the body
    document.body.style.backgroundImage = `url(${bgIlm})`;
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';
    // Cleanup function to reset the background when the component unmounts
    return () => {
      document.body.style.backgroundImage = '';
    };
  }, []);
  return (
    <div className='flex'>


      <div></div>


      <div className='flex flex-col p-2 m-2 '>
        <h2>login with organization</h2>
        <label />
        <input placeholder='username' />
        <button onClick={``} className='p-2 font-mono'>login</button>
      </div>


    </div>
  )
}

export default OrgName