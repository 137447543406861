// create a custom hook for toggle the boolean state

import { useState } from "react";

function useToggle(initialValue = false) {
    const [value, setValue] = useState(initialValue);

    const toggle = () => {
        setValue((prev) => !prev);
    };
    return [value, toggle];
};

export default useToggle;