import React from 'react'
import { CgPlayButtonO, CgPlayPauseO } from 'react-icons/cg';
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { formatTime } from '../utils/util';
const CustomControls = ({ isPlaying,
  isMute,
  onPlay,
  onMute,
  currentTime,
  duration,
  onSeek }) => {

  return (


    <div className='absolute bottom-0 left-0 flex flex-col p-1 gap-2 w-full'>
      <div className='flex w-full'>
        <input 
          type='range'
          min='0'
          max={duration}
          value={currentTime}
          onChange={(e) => onSeek(e.target.value)} className='w-full' />
      </div>
      <div className='gap-8 p-2 flex items-center'>
        <button onClick={onPlay} className=''>
          {isPlaying ? <CgPlayPauseO className='w-12 h-12 text-white' /> : <CgPlayButtonO className='w-12 h-12 text-white' />}
        </button>

        <button onClick={onMute} className=''>
          {isMute ? <GiSpeakerOff className='w-12 h-12 text-white' /> : <GiSpeaker className='w-12 h-12 text-white' />}
        </button>
        <span className='font-thin text-white text-[12px]'>{formatTime(currentTime)} / {formatTime(duration)}</span>
      </div>

    </div>


  )
}

export default CustomControls