import React from 'react'
import Carousel from '../carousels/Carousel';
import {VideoPlayer,PageTemplate,TextEditor} from '../../components'
const Home2 = () => {
    return (
        <div>
            <div className='ud-component--logged-out-home--logged-out-home'>
                <span></span>
                <main className='logged-out-home-page'>
                <Carousel/>
                <TextEditor/>
                <VideoPlayer/>
                <PageTemplate/>
                    <div></div>
                    <div></div>
                    <div></div>
                    <section></section>
                    <div></div>
                    <div></div>
                    <section></section>
                </main>
            </div>
        </div>
    )
}

export default Home2