import React from 'react'
import cn from 'classnames'
const Button = ({children,classname,type,...rest}) => {
  return (
    <button  type={type} className={classname} {...rest}>
        {children}
    </button>
  )
}

export default Button