
import SubHeader from '../../SubHeader'
const CourseStructure = () => {
    return (
        <div className='main-content--content--1Myl4 main-content--wide--yPj7d'>
            <div>
                <SubHeader pageTitle='Course structure' />
                <div></div>
                <div></div>
            </div>

        </div>
    )
}

export default CourseStructure