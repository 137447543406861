import React from 'react'
import styles from './PasswordInfo.module.css';
import { GiCrossFlare } from 'react-icons/gi';
import {RxCross2} from 'react-icons/rx'
const PasswordInfo = ({ onClose }) => {
    return (
        <div className={styles.modal}>
            <div className={styles.modal_content}>
                <div className={styles.close_btn_wrapper}>
                    <button className={styles.pass_gen_info} onClick={onClose}>
                        <RxCross2 style={{ color: 'red' }} />
                    </button>
                </div>

                <div>
                    <img src="https://cdn.statcdn.com/Infographic/images/normal/26298.jpeg" alt="Infographic: How Safe Is Your Password? | Statista" width="100%" height="auto"
                        style={{ width: '100%', height: 'auto !important', maxWidth: '460px' }} />
                </div>
            </div>

        </div>
    )
}

export default PasswordInfo