






import chapterService from "../apiServices/chapter";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const deleteChapterAction=createAsyncThunk('chapters/deleteChapter',async({accessToken,chapterId,courseId})=>{
    try {
        const response=await chapterService.deleteChapter(accessToken,chapterId,courseId);
        console.log('the response for delete in action',response)
        return response;
        
    } catch (error) {
        
    }
})
export const createChapterAction = createAsyncThunk('chapters/addChapter', async ({chapterData,accessToken,courseId}) => {

    console.log('the course id is:',courseId)
    try {
        const response = await chapterService.createChapter(chapterData,accessToken,courseId);
        console.log('the response from server:',response)
        return response.data;
    } catch (error) {
        console.log(error)
    }

})



const chapterSlice = createSlice({
    name: 'chapters',
    initialState: {
        chapters: [],
        newChapterCreated: false,
        status: 'idle',
        error: null,
    },
    

    extraReducers: (builder) => {
        builder
            .addCase(createChapterAction.pending, (state) => {
                state.status='loading'
             })
            .addCase(createChapterAction.fulfilled, (state,action) => {
                state.status='succeeded';
                state.newChapterCreated = true
                state.chapters.push(action.payload);

            }).addCase(createChapterAction.rejected, (state,action) => {
                state.status='failed';
                state.error=action.payload

            })
            .addCase(deleteChapterAction.pending, (state) => {
                state.status='loading'
             })
            .addCase(deleteChapterAction.fulfilled, (state,action) => {
         
      
                state.status='succeeded';

            }).addCase(deleteChapterAction.rejected, (state,action) => {
                state.status='failed';
                state.error=action.payload

            })


    }

})

export default chapterSlice.reducer;