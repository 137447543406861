
import SubHeader from '../../SubHeader'
import MainContent from '../MainContent';

const Curriculam = () => {
  return (
    <div className='full-width-lines'>

      <SubHeader pageTitle='Curriculam' />
      <MainContent/>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
    </div>
  )
}

export default Curriculam                                                                                                                         