import React from 'react'
import './footer.css';
import { a, b, c, d } from '../../assets'
import { Link } from 'react-router-dom';
import '../../common.css'
import { ImSphere } from 'react-icons/im';
import ilm from '../../ilm.png'
const Footer = () => {
    return (
        <footer className='im_footer'>
            <div className='footer_section notice_row'>
                <div className='im_heading_lg notice'>
                    <span>Top organization to live scientific life </span>
                </div>
                <div className='notice_partner'>
                    <img src={a} alt='a' />
                    <img src={b} alt='b' />
                    <img src={c} alt='c' />
                    <img src={d} alt='d' />
                </div>
            </div>


            <div className='footer_section footer_section_main'>
                <div className='links_and_language_selector'>
                    <div className='language_selector_container'>
                        <button type="button" className="im_btn im_btn_medium ud-btn-secondary ud-text-md language_selector_button">
                            <ImSphere aria-hidden='true' focusable='false' className='ud-icon ud-icon-small ud-icon-color-neutral' />
                            <span>English</span>
                        </button>
                    </div>
                    <ul className="ilm_unstyled_list link-column">
                        <li>
                            <a href="#" className="link white-link im_text_sm" target="_blank" rel="noopener">ilm premium</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="/teaching/?ref=teach_footer">Teach on im</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="/mobile/" target="_blank" rel="noopener noreferrer">Get the app</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="https://about.ilm.com/?locale=en-us">About us</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="https://about.udemy.com/company?locale=en-us#offices">Contact us</a>
                        </li>
                    </ul>
                    <ul className="ilm_unstyled_list link-column">
                        <li>
                            <a href="#" className="link white-link im_text_sm" target="_blank" rel="noopener">ilm premium</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="/teaching/?ref=teach_footer">Teach on ilm</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="/mobile/" target="_blank" rel="noopener noreferrer">Get the app</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="https://about.udemy.com/?locale=en-us">About us</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="https://about.udemy.com/company?locale=en-us#offices">Contact us</a>
                        </li>
                    </ul>
                    <ul className="ilm_unstyled_list link-column">
                        <li>
                            <a href="#" className="link white-link im_text_sm" target="_blank" rel="noopener">ilm premium</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="/teaching/?ref=teach_footer">Teach on Ilm</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="/mobile/" target="_blank" rel="noopener noreferrer">Get the app</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="https://about.ilm.com/?locale=en-us">About us</a>
                        </li>
                        <li>
                            <a className="link white-link im_text_sm" href="https://about.ilm.com/company?locale=en-us#offices">Contact us</a>
                        </li>
                    </ul>



                </div>
                <div className='logo_and_copyright'>
                    <div className='logo_container'>
                        <a>
                            <img src={ilm} alt='logo' width={40} height={100}/>
                        </a>
                    </div>
                    <div className='copyright_container im_text_xs'>&copy;2023 ilm,inc</div>
                </div>
            </div>

        </footer>
    )
}

export default Footer