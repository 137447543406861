import { useState, useRef } from "react";
import useCharCounter from "../../../hooks/useCharCounter";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { createChapterAction } from "../../../appSlices/chapterSlice";
import { addChapterInCourseCurriculam } from "../../../appSlices/courseSlice";
import useAuth from "../../../hooks/useAuth";
const NewChapterForm = ({ onClose }) => {
    const {auth}=useAuth();
    const dispatch = useDispatch();
    const { courseId } = useParams();
    console.log('the course id is:',courseId)
    const [chapterTitle, setChapterTitle] = useState('');
    const [chapterDescription, setChapterDescription] = useState('')

    const inputNewChapterTitleRef = useRef(null);
    const inputNewChapterDescriptionRef = useRef(null);
    const maxTitleLength = inputNewChapterTitleRef.current?.maxLength || null;
    const maxDescriptionLength = inputNewChapterDescriptionRef.current?.maxLength || null;


  


    const handleChapterDescription = (e) => {
        setChapterDescription(e.target.value);
    }
    const handleChapterTitle = (e) => {
        setChapterTitle(e.target.value);
    }
    const remainingTitleChars = useCharCounter(chapterTitle, maxTitleLength);
    const remainingDescriptionChars = useCharCounter(chapterDescription, maxDescriptionLength);
   
    const addChapter = (e) => {
        e.preventDefault();
        const chapterData = {
            title:chapterTitle,
            description:chapterDescription,
        }
        //  dispatch(createChapterAction({chapterData,accessToken:auth.accessToken,courseId}));
         dispatch(addChapterInCourseCurriculam({chapterData,accessToken:auth.accessToken,courseId}));



    }
    return (
        <div className='curriculum-list--inline-insert-form--f-pCL'>

            <form data-purpose="section-form" 
            className="item-form--item-form" 
            onSubmit={addChapter}>
                <div className="ud-text-bold item-form--title--TA39i">New Chapter:</div>
                <div className="item-form--flex">
                    <div data-purpose="section-form-group-title" className="ud-form-group">
                        <label className="ud-sr-only ud-form-label ud-heading-sm" htmlFor="form-group--226">Title</label>
                        <div className="ud-text-input-container">
                            <input aria-describedby="text-area-with-counter--228" aria-invalid="false" maxLength="80" placeholder="Enter a Title" data-purpose="section-title" id="form-group--226" type="text" className="ud-text-input ud-text-input-small ud-text-sm"
                                value={chapterTitle}
                                onChange={handleChapterTitle}
                                ref={inputNewChapterTitleRef}
                            />
                            <span>
                                <span aria-hidden="true" className="text-input-with-counter-module--counter--F7OMr">{remainingTitleChars.remainingChars}</span>
                                <span className="ud-sr-only" aria-live="polite" id="text-area-with-counter--228">You have 80 characters of 80 remaining.</span>
                            </span>
                            <div className="ud-text-input-box"></div>
                        </div>
                    </div>

                    <div data-purpose="section-form-group-description" className="ud-form-group">
                        <label htmlFor="form-group--229" className="ud-form-label ud-heading-sm">What will students be able to do at the end of this section?</label><div className="ud-text-input-container">
                            <input aria-describedby="text-area-with-counter--231" aria-invalid="false" maxLength="200" placeholder="Enter a Learning Objective" data-purpose="section-objective" id="form-group--229" type="text" className="ud-text-input ud-text-input-small ud-text-sm"
                                value={chapterDescription}
                                onChange={handleChapterDescription}
                                ref={inputNewChapterDescriptionRef}
                            />
                            <span>
                                <span aria-hidden="true" className="text-input-with-counter-module--counter--F7OMr">{remainingDescriptionChars.remainingChars}</span>
                                <span className="ud-sr-only" aria-live="polite" id="text-area-with-counter--231">You have 200 characters of 200 remaining.</span></span>
                            <div className="ud-text-input-box">
                            </div>
                        </div>
                    </div>
                    <div className="item-form--submit-row">
                        <button onClick={onClose} type="button" className="ud-btn ud-btn-small ud-btn-ghost ud-heading-sm ud-link-neutral" data-purpose="cancel-section-form">
                            <span>Cancel</span>
                        </button>
                        <button type="submit" data-purpose="submit-section-form" className="ud-btn ud-btn-small ud-btn-primary ud-heading-sm">
                            <span>Add Chapter</span>
                        </button>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default NewChapterForm;