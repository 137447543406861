
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedCourse } from '../../appSlices/courseSlice';
const CourseCard = ({ onDelete, course }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleCourseClick = (course) => {
        dispatch(setSelectedCourse(course))
        // navigate to your designated route were u wan to go  
        navigate(`/instructor/course/${course._id}/manage/curriculum`)
    }


    return (
        <div >
            <div onClick={() => handleCourseClick(course)} className="courses--card--pk1Fv" data-purpose="course-row">
                <div className="courses--card-image-wrapper--IcNnC">
                    <img className="courses--card-image--X1moW" src="https://s.udemycdn.com/course/200_H/placeholder.jpg"
                        alt="" width="118" height="118" loading="lazy" />
                </div>

                <div className="courses--card-body--eNaAj">
                    <div className="courses--card-details--8lC8R">
                        <div className="ud-heading-md courses--ellipsis-2-lines--QBhR7">{course.title}</div>
                        <div className="ud-text-xs courses--card-details-bottom--ThOs1">
                            <div className="ud-text-bold courses--course-status--oPpHw">{course.status_label}</div>
                            <div className="courses--card-price-privacy-label--eD-pz">{course.privacy_label}</div>
                        </div>
                    </div>
                    <div className="courses--card-tile--TJfx2 courses--card-col--eA5Po">

                        <div className="courses--course-progress-wrapper--dVrc4">
                            <div className="ud-heading-md">Finish your course</div>
                            <div className="courses--course-progress--S9UwH ud-meter-wrapper meter-module--meter-wrapper--lGwu-">
                                <div className="ud-meter meter-module--meter--9-BwT" aria-label="2% complete" data-purpose="meter"


                                // style={{transform:'scale(0.02)'}}

                                >

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="courses--two-card-link-container--NnVHs">
                        <div className="courses--two-card-link-wrapper--ljxmj">
                            <p
                                // to={`/instructor/course/${course._id}/manage/curriculum`}
                                className="ud-heading-lg courses--two-card-link--BtScS"
                                data-purpose="course-manage-link-text">Edit / manage course</p>
                        </div>
                    </div>
                </div>
            </div>
            <button
                onClick={() => onDelete(course._id)}
                className="ud-btn ud-btn-large ud-btn-brand ud-heading-md instructor-dashboard--create-cta-button--cn3Rp"
                data-purpose="click-delete-course"
            >
                <span>Delete</span>
            </button>
        </div>

    )
}

export default CourseCard;