
import SubHeader from '../../SubHeader'
const Captions = () => {
  return (
    <div>
        <SubHeader pageTitle='Captions'/>
        <div className='main-content--content--1Myl4'></div>
    </div>
  )
}

export default Captions