import axios from '../API/axios'



const getAllAssetByMediaType=async()=>{

    try {
        const response=await axios.get(`/users/me/assets?`)
        
    } catch (error) {
        
    }

}

const createVideoAsset=async({accessToken,payload})=>{
    try {
        const response=await axios.get(`/users/me/video-assets`,payload,{

        });
        
    } catch (error) {
        
    }
}
const createImageAsset=async({accessToken,payload})=>{
    try {
        const response=await axios.get(`/users/me/image-asset`,payload,{
            headers:{
                'Content-Type':'application/json',
                'authorization':`Bearer ${accessToken}`
            }

        });
        console.log('response form :',response.data);
        
    } catch (error) {
        
    }
}
const createTextAsset=async({accessToken,payload})=>{
    try {
        const response=await axios.get(`/users/me/video-assets`,payload,{

        });
        
    } catch (error) {
        
    }
}


// asset create by user
const createAsset=async(assetData,type,accessToken)=>{
    console.log('the assets  in api hit:',assetData)

    for (let [key, value] of assetData.entries()) {
        console.log(`${key}:`, value);
      }
      
    try {
        const response=await axios.post(`/users/me/${type}-assets`,assetData,{
            headers:{
                'Content-Type':'multipart/form-data',
                'authorization':`Bearer ${accessToken}`
            }

        });
        console.log(response);
        return response.data.response;
        
    } catch (error) {
        console.log(error)
        
    }
}


const addAssetToPage=async(assetData,accessToken,courseId)=>{
    const {pageId}=assetData;
    console.log('page id is:',pageId)
    console.log('courseId id is:',courseId);
    try {
        // 1 version not returning any data 
        const response=await axios.post(`users/me/taught-courses/${courseId}/pages/${pageId}/assets/`,{asset_id:assetData.id},{
            headers:{
                'Content-Type':'application/json',
                'authorization':`Bearer ${accessToken}`
            }

        });
        return response.data
    } catch (error) {
        console.log(error)
    }
}
const assetService={
    getAllAssetByMediaType,createVideoAsset,createImageAsset,createTextAsset,
    createAsset,addAssetToPage
}

export default assetService;