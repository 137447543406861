


import { useState, useEffect } from "react";


function useCharCounter(currentChars, maxLength) {
    const [remainingChars, setRemainingChars] = useState(maxLength);
    useEffect(() => {
        // effect
        setRemainingChars(maxLength - currentChars.length)
        // return () => {
        //     // cleanup
        // };
    }, [currentChars,maxLength]);

    return{
        remainingChars,
    }

}

export default useCharCounter;