import TopNavBar from '../components/navbar/TopNavBar'
import Footer from '../components/footer/Footer';
import { Outlet } from 'react-router-dom';
const GuestLayout = () => {
    return (
        <div className='ud-main-content-wrapper'>
            <TopNavBar />
            {/* <nav aria-hidden='true'>some content</nav> */}
            <div className='main-content'>
                {/* some content  may be home login logout */}
                {/* <Home2 /> */}
                <Outlet/>
            </div>
            <Footer />
        </div>
    )
}
export default GuestLayout