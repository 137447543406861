
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { rp } from '../../assets/index';
import { RiInformationLine } from 'react-icons/ri';
import { createPortal } from 'react-dom';
import PasswordInfo from '../modals/PasswordInfo';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
const ResetPassword = () => {
    const { width, height } = useWindowSize();
    const [showModal, setShowModal] = useState(false);
    // Access query parameters using useSearchParams
    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get('resetToken');
    return (
        <div className={''}>

            <div className={''}>
                <img width={400} height={400} src={rp} alt='reset-password' />
            </div>

            <div className={''}>
                <div className={''}>
                    <h3>ResetPassword</h3>

                    <button onClick={() => setShowModal(true)} className={''}>
                        <RiInformationLine />
                    </button>
                    {
                        showModal && createPortal(<PasswordInfo onClose={() => setShowModal(false)} />, document.body)
                    }
                </div>
                {/* <Confetti
                    width={width}
                    height={height}
                /> */}
                <form className={''}>
                    <div className={''}>
                        <input placeholder='new password' />
                    </div>

                    <div className={''}>
                        <input placeholder='confirm password' />
                    </div>
                    <div className={''}>

                        <button className={''}>
                            reset password
                        </button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default ResetPassword