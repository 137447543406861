import React from 'react';
import { useState, useEffect } from 'react';
import styles from './Carousel.module.css';
import { carouselImages } from '../../constants'
const Slider = () => {
    const [currentCarouselImageIndex, setCurrentCarouselImageIndex] = useState(0);
    const [isCarouselImageHovered, setIsCarouselImageHovered] = useState(false)
    const handleDotClick = (index) => {
        setCurrentCarouselImageIndex(index)
    }
    const handleMouseEnter = () => {
        setIsCarouselImageHovered(true)
    }
    const handleMouseLeave = () => {
        setIsCarouselImageHovered(false)
    }
    useEffect(() => {
        const timer = setInterval(() => {
            if (!isCarouselImageHovered || isCarouselImageHovered) {
                const nextIndex = (currentCarouselImageIndex + 1) % carouselImages.length;
                setCurrentCarouselImageIndex(nextIndex);
            }
        }, 5000); // Adjust the interval time as needed (e.g., 5000ms = 5 seconds)

        return () => {
            clearInterval(timer); // Clean up the timer on component unmount
        };
    }, [currentCarouselImageIndex, carouselImages.length, isCarouselImageHovered]);
    return (
        <div className={styles.carousel}>
            <div className={styles.carousel_container} style={{ transform: `translateX(-${currentCarouselImageIndex * 100}%)` }}>
                {
                    carouselImages.map((image, index) => (
                        <div className={styles.carousel_item} key={index} onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            <img src={image.src} alt={image.alt} />
                            {/* <div className={styles.caption}>
                                <b>{image.captionHeading}</b>
                                <p>{image.caption}</p>
                            </div> */}
                        </div>
                    ))
                }
            </div>



            <div className={styles.carousel_dot}>
                {
                    carouselImages.map((image, index) => (
                        <span key={index}
                            className={`${styles.dot} ${currentCarouselImageIndex === index ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)} ></span>
                    ))
                }
            </div>

        </div>
    )
}

export default Slider




// import { carouselImages } from '../../constants';
// const Carousel = () => {
//     // manage carousel state here
//     const [currentCarouselImageIndex, setCurrentCarouselImageIndex] = useState(0);
//     const [isCarouselImageHovered, setIsCarouselImageHovered] = useState(false)
//     const handleDotClick = (index) => {
//         setCurrentCarouselImageIndex(index)
//     }
//     const handleMouseEnter = () => {
//         setIsCarouselImageHovered(true)
//     }
//     const handleMouseLeave = () => {
//         setIsCarouselImageHovered(false)
//     }
//     useEffect(() => {
//         const timer = setInterval(() => {
//             if (!isCarouselImageHovered || isCarouselImageHovered) {
//                 const nextIndex = (currentCarouselImageIndex + 1) % carouselImages.length;
//                 // setCurrentCarouselImageIndex(nextIndex);
//             }
//         }, 1000); // Adjust the interval time as needed (e.g., 5000ms = 5 seconds)

//         return () => {
//             clearInterval(timer); // Clean up the timer on component unmount
//         };
//     }, [currentCarouselImageIndex, carouselImages.length, isCarouselImageHovered]);

//     return (
//         <div className='carousel'>
//             <div className='carousel-container' style={{ transform: `translateX(-${currentCarouselImageIndex * 100}%)` }}>
//                 {
//                     carouselImages.map((image, index) => (
//                         <div className='carousel-item' key={index} onMouseEnter={handleMouseEnter}
//                             onMouseLeave={handleMouseLeave}>
//                             <img src={image.src} alt={image.alt} />
//                             <div className='caption'><b>{image.captionHeading}</b> <p>{image.caption}</p></div>
//                         </div>
//                     ))
//                 }
//             </div>
//             <div className='carousel-dot'>
//                 {
//                     carouselImages.map((image, index) => (
//                         <span key={index}
//                             className={`dot ${currentCarouselImageIndex === index ? 'active' : ''}`}
//                             onClick={() => handleDotClick(index)} ></span>
//                     ))
//                 }
//             </div>
//         </div>
//     )
// }

// export default Carousel