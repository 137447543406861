import { useState } from 'react';
import styles from './cc.module.css';
import { TagsInput } from "react-tag-input-component";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createCourseAction } from '../../appSlices/courseSlice';
import { useDispatch, useSelector } from 'react-redux'
import { setToken } from '../../utils/util';

import useAuth from '../../hooks/useAuth';
import courseAPI from '../../API/course'
import { setSelectedCourse } from '../../appSlices/courseSlice';
const CourseCreator = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [selected, setSelected] = useState([]);
    const { register, handleSubmit } = useForm();
    // const { currentUser } = useSelector(state => state.auth);
    const { auth } = useAuth();
  
    const accessToken = auth.accessToken
    console.log('the aut value is in Course creater :', accessToken)


    const submitData = async (data) => {
        const courseData = { ...data, tags: selected,  };
        console.log(courseData);



        try {
            const createData = await courseAPI.createCourse(courseData,accessToken)
            dispatch(setSelectedCourse(createData.data))
            console.log(createData.data)
            navigate(`/instructor/course/${createData.data._id}/manage/curriculum`)
    
        } catch (error) {
            console.log(error)
        }




    }







    return (<div className=''>
        <div className=''>

            <div className=''>
                <h1>welcome create the course</h1>
            </div>

            <form onSubmit={handleSubmit(submitData)} data-role='new-course-form'>

                <div className={''}>
                    <input {...register('title')} className=''
                        placeholder='eg: physics for 11' />
                </div>
                <div className=''>

                    <select {...register('category_id')} className='' name="cars" id="cars">
                        <optgroup label="Swedish Cars">
                            <option value="1">Volvo</option>
                            <option value="2">Saab</option>
                        </optgroup>
                        <optgroup label="German Cars">
                            <option value="3">Mercedes</option>
                            <option value="4">Audi</option>
                        </optgroup>
                    </select>

                </div>
                <div className=''>
                    <TagsInput   {...register('tags')}
                        value={selected}
                        onChange={setSelected}
                        name="courseTags"
                        placeHolder="course tags"
                    />
                    <em>press enter to a add new tag</em>
                </div>
                <div className=''>
                    <button type='submit' className='lp-btn lp-btn-md'>create course</button>
                </div>
            </form>

        </div>
    </div>
    )
}


export default CourseCreator