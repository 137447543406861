
import { MdNotifications, MdMessage, MdHelpOutline, MdOndemandVideo, MdBarChart, MdExpandMore, MdSearch } from "react-icons/md";
import { FiTool } from "react-icons/fi";
import { Link } from "react-router-dom"
const TeacherSideNavBar = () => {
    return (

        <div className="instructor-side-nav" >
            <div data-purpose="side-nav" className="side-nav--side-nav--kWuu2">
                <div className="side-nav--side-nav-inner--TrfVn">
                    <div className="side-nav--primary-menu--gihQ-">
                        <ul className="ud-unstyled-list side-nav--menu--kRZ7l">
                            <li>
                                <Link to="/instructor" className="side-nav--nav-button--H6cSp side-nav--logo-nav-button--pAYC5">
                                    {/* <img src="https://img-c.udemycdn.com/user/75x75/181456018_32b7.jp"
                         alt="Udemy" width="91" height="34"
                            data-purpose="udemy-brand-logo" loading="lazy" /> */}
                                    <span>ilm</span>
                                </Link>
                            </li>



                            <li data-purpose="side-nav-item">
                                <Link to="/instructor/courses"
                                    className="ud-btn ud-btn-large ud-btn-link ud-heading-md side-nav--nav-button--H6cSp
                            side-nav--active--M2Mw4">
                                    <MdOndemandVideo aria-hidden="true" focusable="false" className="ud-icon ud-icon-medium" />

                                    <span className="side-nav--title--P8jur">Courses</span>
                                </Link>
                            </li>
                            <li data-purpose="side-nav-item">
                                <Link to="/instructor/communication/qa/"
                                    className="ud-btn ud-btn-large ud-btn-link ud-heading-md side-nav--nav-button--H6cSp">
                                    <MdMessage aria-hidden="true" focusable="false" className="ud-icon ud-icon-medium" />

                                    <span className="side-nav--title--P8jur">Communication</span>
                                </Link></li><li data-purpose="side-nav-item">
                                <Link to="/instructor/performance/overview"
                                    className="ud-btn ud-btn-large ud-btn-link ud-heading-md side-nav--nav-button--H6cSp">
                                    <MdBarChart aria-hidden="true" focusable="false" className="ud-icon ud-icon-medium" />

                                    <span
                                        className="side-nav--title--P8jur">Performance</span></Link></li>
                            <li data-purpose="side-nav-item">
                                <Link to="/instructor/tools"
                                    className="ud-btn ud-btn-large ud-btn-link ud-heading-md side-nav--nav-button--H6cSp">
                                    <FiTool aria-hidden="true" focusable="false" className="ud-icon ud-icon-medium" />

                                    <span className="side-nav--title--P8jur">Tools</span>
                                </Link>
                            </li>
                            <li data-purpose="side-nav-item">
                                <Link to="/instructor/help"
                                    className="ud-btn ud-btn-large ud-btn-link ud-heading-md side-nav--nav-button--H6cSp">
                                    <MdHelpOutline aria-hidden="true" focusable="false" className="ud-icon ud-icon-medium" />

                                    <span className="side-nav--title--P8jur">Resources</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default TeacherSideNavBar