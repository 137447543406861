
import SubHeader from '../../SubHeader'
const Film = () => {
  return (
    <div className='main-content--content--1Myl4 main-content--wide--yPj7d'>
        <div>
            <SubHeader pageTitle='Film & edit'/>
            <div></div>
            <div></div>
        </div>
    </div>
  )
}

export default Film