
import SubHeader from '../../SubHeader'
const Pricing = () => {
  return (
    <div>
      <SubHeader pageTitle='Pricing' />
      <div className='main-content--content--1Myl4'></div>
    </div>
  )
}

export default Pricing