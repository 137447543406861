import { Link } from 'react-router-dom'
const TopNavBar = () => {
    return (
        <div className='ud-app-loader ud-component--header-v6--header ud-header ud-app-loaded'>
            {/* <div class="smart-bar-spacer smart-bar-module--smart-bar-spacer--JTyvD" data-testid="smart-bar-spacer"></div> */}

            {/* <div class="desktop-header-module--mobile-header--EaZjO">
                <div data-testid="mobile-header-placeholder" class="desktop-header-module--mobile-header-placeholder--X9vRI"></div>
            </div> */}

            {/* <span className='desktop-header-module--mark--adaV7'></span> */}

            <div className='ud-header ud-text-sm desktop-header-module--header--a5wri desktop-header-module--flex-middle--k0Teb'>

                {/* <div className="skip-to-content-button-module--skip-to-content--MnPe4">
                    <Link className="ud-btn ud-btn-large ud-btn-primary ud-heading-md skip-to-content-button-module--skip-to-content-btn--iM4H1" href="/#main-content-anchor"><span aria-hidden="true" className="skip-to-content-button-module--skip-to-content-shadow--eue1a">
                    </span>
                        <span style={{ margin: 0 }}>Skip to content</span>
                    </Link>
                </div> */}

                <Link to='/' className="desktop-header-module--flex-middle--k0Teb desktop-header-module--logo--AwnFF">

                    <img src=""
                     alt="ilm" width="91" height="34" loading="lazy" />

                </Link>


                <nav className="popper-module--popper--mM5Ie desktop-header-module--gap-button--4drhI">
                    <button type="button" id="u185-popper-trigger--1" aria-expanded="true" tabIndex="0" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md js-header-button desktop-header-module--dropdown-button--ipas9">
                        <span className="ud-text-sm desktop-header-module--dropdown-button-text--Sq73l">Categories</span>
                    </button>

                    <div className="popper-module--popper-content--XE9z5  desktop-header-module--dropdown--pZls3" id="u185-popper-content--2" aria-labelledby="u185-popper-trigger--1" style={{ top: '100%', left: 0 }}>

                        <div className="popper-module--animation-wrapper--tM0UK">

                            <div className="popover-module--popover--HrSFz popover-module--popover-bottom--1ghkR">

                                <div className="popover-module--inner--yQvGI">
                                    <div className="list-menu-module--list-menu-container--8oKTW browse-nav-module--nav-container--JbVc5" data-testid="browse-nav">

                                        <div className="js-browse-nav-level-one browse-nav-module--nav--0Iif6" data-testid="browse-nav-list">
                                            <ul className="ud-unstyled-list ud-block-list list-menu-module--section--l6I-M">
                                                <li>
                                                    <Link href="/courses/development/" data-testid="browse-nav-item" id="header-browse-nav-category-288" data-id="288" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Development</div>

                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>


                                                <li>
                                                    <Link to="/courses/business/" data-testid="browse-nav-item" id="header-browse-nav-category-268" data-id="268" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Business</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>



                                                <li>
                                                    <Link href="/courses/finance-and-accounting/" data-testid="browse-nav-item" id="header-browse-nav-category-328" data-id="328" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Finance &amp; Accounting</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/it-and-software/" data-testid="browse-nav-item" id="header-browse-nav-category-294" data-id="294" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">IT &amp; Software</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/office-productivity/" data-testid="browse-nav-item" id="header-browse-nav-category-292" data-id="292" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Office Productivity</div>
                                                        {/* <svg aria-hidden="true" focusable="false"
                          className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>


                                                <li>
                                                    <Link href="/courses/personal-development/" data-testid="browse-nav-item" id="header-browse-nav-category-296" data-id="296" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-link" aria-expanded="true">
                                                        <div className="ud-block-list-item-content">Personal Development</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next">
                          </use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/design/" data-testid="browse-nav-item"
                                                        id="header-browse-nav-category-269" data-id="269" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral"
                                                        aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Design</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next">
                          </use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/marketing/" data-testid="browse-nav-item"
                                                        id="header-browse-nav-category-290" data-id="290"
                                                        className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Marketing</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next">
                          </use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/lifestyle/" data-testid="browse-nav-item" id="header-browse-nav-category-274" data-id="274" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Lifestyle</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>



                                                <li>
                                                    <Link href="/courses/photography-and-video/"
                                                        data-testid="browse-nav-item" id="header-browse-nav-category-273"
                                                        data-id="273" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Photography &amp; Video</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/health-and-fitness/"
                                                        data-testid="browse-nav-item" id="header-browse-nav-category-276" data-id="276" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div
                                                            className="ud-block-list-item-content">Health &amp; Fitness</div>
                                                        {/* <svg aria-hidden="true" focusable="false"
                          className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use></svg> */}
                                                    </Link>
                                                </li>


                                                <li>
                                                    <Link href="/courses/music/" data-testid="browse-nav-item"
                                                        id="header-browse-nav-category-278" data-id="278" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Music</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon 
                        ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/teaching-and-academics/"
                                                        data-testid="browse-nav-item"
                                                        id="header-browse-nav-category-300" data-id="300"
                                                        className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Teaching &amp; Academics</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                            </ul>

                                        </div>


                                        <div id="header-browse-nav-level-two" className="js-browse-nav-level-two browse-nav-module--nav--0Iif6" style={{ display: 'block' }} data-testid="browse-nav-list" aria-labelledby="header-browse-nav-category-296">
                                            <ul className="ud-unstyled-list ud-block-list list-menu-module--section--l6I-M">
                                                <li>
                                                    <Link href="/courses/personal-development/personal-transformation/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-142" data-id="142" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Personal Transformation</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link href="/courses/personal-development/productivity/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-144" data-id="144" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Personal Productivity</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>


                                                <li>
                                                    <Link href="/courses/personal-development/leadership/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-146" data-id="146" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Leadership</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next">
                          </use>
                        </svg> */}
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link href="/courses/personal-development/career-development/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-150" data-id="150" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Career Development</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link href="/courses/personal-development/parenting-and-relationships/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-152" data-id="152" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Parenting &amp; Relationships</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link href="/courses/personal-development/happiness/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-156" data-id="156" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Happiness</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use></svg> */}
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link href="/courses/lifestyle/esoteric-practices/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-577" data-id="577" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Esoteric Practices</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/personal-development/religion-and-spirituality/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-158" data-id="158" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Religion &amp; Spirituality</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link href="/courses/personal-development/personal-brand-building/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-160" data-id="160" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Personal Brand Building</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/personal-development/creativity/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-164" data-id="164" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false"><div className="ud-block-list-item-content">Creativity</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/personal-development/influence/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-166" data-id="166" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Influence</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link href="/courses/personal-development/self-esteem-and-confidence/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-168"
                                                        data-id="168" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Self Esteem &amp; Confidence</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/personal-development/stress-management/"
                                                        data-testid="browse-nav-item" id="header-browse-nav-subcategory-170" data-id="170"
                                                        className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Stress Management</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/personal-development/memory/"
                                                        data-testid="browse-nav-item" id="header-browse-nav-subcategory-172"
                                                        data-id="172" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm 
                      list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small 
                      ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Memory &amp; Study Skills</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall
                         ud-block-list-item-icon"><use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/personal-development/motivation/"
                                                        data-testid="browse-nav-item" id="header-browse-nav-subcategory-176"
                                                        data-id="176" className="ud-btn ud-btn-large 
                          ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item 
                          ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Motivation</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon 
                            ud-icon-xsmall ud-block-list-item-icon"><use xlink:href="#icon-next">
                          </use>
                        </svg> */}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link href="/courses/personal-development/other-personal-development/" data-testid="browse-nav-item" id="header-browse-nav-subcategory-178" data-id="178" className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm list-menu-module--item--OY-R6 ud-block-list-item ud-block-list-item-small ud-block-list-item-neutral" aria-expanded="false">
                                                        <div className="ud-block-list-item-content">Other Personal Development</div>
                                                        {/* <svg aria-hidden="true" focusable="false" className="ud-icon ud-icon-xsmall ud-block-list-item-icon">
                          <use xlink:href="#icon-next"></use>
                        </svg> */}
                                                    </Link>
                                                </li>

                                            </ul>
                                        </div>
                                        <div id="header-browse-nav-level-three" className="js-browse-nav-level-three 
                browse-nav-module--nav--0Iif6" style={{ display: "none" }} data-testid="browse-nav-list">
                                        </div>

                                    </div>
                                    {/* <svg aria-hidden="true" focusable="false" width="170" height="72" style="pointer-events: none; position: absolute; bottom: 100%; right: 0px;">
                <path d="M0 0V72H170Z" style="pointer-events: auto; cursor: pointer; fill: transparent;"></path>
              </svg> */}

                                </div>
                            </div>

                        </div>

                    </div>
                </nav>


                <div locale="en_US" lang="en" className="ud-search-form-autocomplete desktop-header-module--search-bar--49122 ud-form-group">
                    <label className="ud-sr-only ud-form-label ud-heading-sm" htmlFor="u185-search-form-autocomplete--3">Search for anything</label>
                    <form action="/courses/search/" className="ud-search-form-autocomplete-input-group ud-search-form-autocomplete-input-group-reversed">
                        <input type="hidden" name="src" value="ukw"  />
                        <input onChange={()=>{}} type="text" aria-invalid="false" name="q" data-testid="search-input" placeholder="Search for anything" autoComplete="off" value="" role="combobox" aria-autocomplete="both" aria-haspopup="true" aria-expanded="false" aria-controls="u185-search-form-autocomplete--3-menu-content-items" id="u185-search-form-autocomplete--3" className="ud-text-input ud-text-input-small ud-text-sm ud-search-form-autocomplete-input js-header-search-field" />
                        <button type="submit" disabled="" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md ud-btn-disabled ud-btn-icon ud-btn-icon-large" tabIndex="-1">
                            {/* <svg aria-label="Submit search" role="img" focusable="false" className="ud-icon ud-icon-medium ud-icon-color-neutral">
            <use xlink:href="#icon-search">
            </use></svg> */}
                        </button>
                    </form>
                </div>


                <div className="popper-module--popper--mM5Ie desktop-header-module--gap-button--4drhI desktop-header-module--group-a--N6xgx">
                    <Link data-purpose="try-ufb-dropdown-trigger"
                        href="/udemy-business/?locale=en_US&amp;mx_pg=httpcachecontextsme-list&amp;path=request-demo-in-mx%2F&amp;ref=ufb_header" target="_blank" rel="noopener" id="u145-popper-trigger--8" aria-describedby="u145-popper-content--9" tabIndex="0" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md js-header-button desktop-header-module--dropdown-button--ipas9">
                        <span className="ud-text-sm desktop-header-module--dropdown-button-text--Sq73l">premium</span>
                    </Link>


                    <div className="popper-module--popper-content--XE9z5 desktop-header-module--dropdown--pZls3" id="u145-popper-content--9"
                        style={{ top: '100%', right: '0px' }}>
                        <div className="popper-module--animation-wrapper--tM0UK">

                            <div className="popover-module--popover--HrSFz popover-module--popover-bottom--1ghkR">
                                <div className="popover-module--inner--yQvGI">
                                    <div className="ud-header-menu desktop-header-module--menu--oIYSV" data-testid="header-menu">

                                        <div className="panel-menu-module--panel--FrCW7">
                                            <div className="ud-heading-lg panel-menu-module--gap-bottom---DDBJ" data-purpose="hook-text">Get your team access to over 25,000 top Udemy courses, anytime, anywhere.</div>
                                            <Link data-purpose="try-ufb-button" href="/udemy-business/?locale=en_US&amp;mx_pg=httpcachecontextsme-list&amp;path=request-demo-in-mx%2F&amp;ref=ufb_header" className="ud-btn ud-btn-large ud-btn-primary ud-heading-md panel-menu-module--cta--G-aTQ" target="_blank" rel="noopener">
                                                <span>Try pr</span>
                                            </Link>
                                        </div>
                                        {/* <svg aria-hidden="true" focusable="false" width="162"
                  height="72" style="pointer-events: none; position: absolute; bottom: 100%; left: 0px;">
                  <path d="M162 0V72H0Z" style="pointer-events: auto; cursor: pointer; fill: transparent;"></path>
                </svg> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="popper-module--popper--mM5Ie desktop-header-module--gap-button--4drhI desktop-header-module--group-b--Zka1Y">
                    <Link href="/teaching/?ref=teach_header" data-purpose="instructor-dropdown" data-testid="teach-on-udemy-link" id="u145-popper-trigger--10" aria-describedby="u145-popper-content--11" tabIndex="0" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md js-header-button desktop-header-module--dropdown-button--ipas9">
                        <span className="ud-text-sm desktop-header-module--dropdown-button-text--Sq73l">Teach </span>
                    </Link>
                    <div className="popper-module--popper-content--XE9z5 desktop-header-module--dropdown--pZls3" id="u145-popper-content--11"
                        style={{ top: '100%', right: '0px' }}>
                        <div className="popper-module--animation-wrapper--tM0UK">
                            <div className="popover-module--popover--HrSFz popover-module--popover-bottom--1ghkR">
                                <div className="popover-module--inner--yQvGI">
                                    <div className="ud-header-menu desktop-header-module--menu--oIYSV" data-testid="header-menu">
                                        <div className="panel-menu-module--panel--FrCW7">
                                            <div className="ud-heading-lg panel-menu-module--gap-bottom---DDBJ">Turn what you know into an opportunity and reach millions around the world.</div>
                                            <Link href="/teaching/?ref=teach_header" className="ud-btn ud-btn-large ud-btn-primary ud-heading-md panel-menu-module--cta--G-aTQ" data-testid="learn-more-link">
                                                <span>Learn more</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="popper-module--popper--mM5Ie">
                    <Link href="/cart/" data-testid="cart-icon" id="u145-popper-trigger--12" aria-expanded="false" tabIndex="0" className="ud-btn ud-btn-large ud-btn-ghost ud-heading-md ud-btn-icon ud-btn-icon-large js-header-button desktop-header-module--dropdown-button--ipas9">
                        {/* <svg aria-label="Shopping cart with 0 items" role="img" focusable="false" className="ud-icon ud-icon-medium ud-icon-color-neutral">
          <use xlink:href="#icon-cart"></use>
        </svg> */}
                    </Link>
                    <div className="popper-module--popper-content--XE9z5 desktop-header-module--dropdown--pZls3" id="u145-popper-content--13" aria-labelledby="u145-popper-trigger--12"
                        style={{ top: '100%', right: '0px' }}>
                        <div className="popper-module--animation-wrapper--tM0UK">
                            <div className="popover-module--popover--HrSFz popover-module--popover-bottom--1ghkR">
                                <div className="popover-module--inner--yQvGI">
                                    <div className="ud-header-menu desktop-header-module--menu--oIYSV" data-testid="header-menu">
                                        <div className="panel-menu-module--panel--FrCW7">
                                            <div className="ud-text-md panel-menu-module--gap-bottom---DDBJ panel-menu-module--no-items--O75Tt">Your cart is empty.</div>
                                            <Link className="ud-heading-sm" data-testid="header-shopping-cta" href="/">Keep shopping</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="desktop-header-module--gap-auth-button--f25sS">
                    <Link data-purpose="header-login" 
                    // href="https://www.udemy.com/join/login-popup/?locale=en_US&amp;response_type=html&amp;next=https%3A%2F%2Fwww.udemy.com%2F" 
                    to='/login'
                    className="ud-btn ud-btn-medium ud-btn-secondary ud-heading-sm">
                        <span>Log in</span>
                    </Link>
                </div>


                <div className="desktop-header-module--gap-auth-button--f25sS">
                    <Link data-purpose="header-signup"
                        // href="https://www.udemy.com/join/signup-popup/?locale=en_US&amp;response_type=html&amp;next=https%3A%2F%2Fwww.udemy.com%2F"
                        to='/register'
                        className="ud-btn ud-btn-medium ud-btn-primary ud-heading-sm">
                        <span>Sign up</span>
                    </Link>
                </div>



                <div className="desktop-header-module--gap-auth-button--f25sS">
                    <button type="button" className="ud-btn ud-btn-medium ud-btn-secondary ud-heading-sm ud-btn-icon ud-btn-icon-medium">
                        {/* <svg aria-label="Choose a language" role="img" focusable="false" className="ud-icon ud-icon-small ud-icon-color-neutral">
          <use xlink:href="#icon-language"></use>
        </svg> */}
                    </button>
                    <div className="ud-sr-only" aria-hidden="true" data-testid="seo-link-container">
                        <Link href="/" tabIndex="-1">English</Link><Link href="/de/" tabIndex="-1">Deutsch</Link>
                        <Link href="/es/" tabIndex="-1">Español</Link><Link href="/fr/" tabIndex="-1">Français</Link>
                        <Link href="/id/" tabIndex="-1">Bahasa Indonesia</Link><Link href="/it/" tabIndex="-1">Italiano</Link>
                        <Link href="/ja/" tabIndex="-1">日本語</Link><Link href="/ko/" tabIndex="-1">한국어</Link>
                        <Link href="/nl/" tabIndex="-1">Nederlands</Link><Link href="/pl/" tabIndex="-1">Polski</Link>
                        <Link href="/pt/" tabIndex="-1">Português</Link><Link href="/ro/" tabIndex="-1">Română</Link>
                        <Link href="/ru/" tabIndex="-1">Русский</Link><Link href="/th/" tabIndex="-1">ภาษาไทย</Link>
                        <Link href="/tr/" tabIndex="-1">Türkçe</Link><Link href="/vi/" tabIndex="-1">Tiếng Việt</Link>
                        <Link href="/zh-cn/" tabIndex="-1">中文(简体)</Link><Link href="/zh-tw/" tabIndex="-1">中文(繁體)</Link>
                    </div>
                </div>

            </div>



        </div>
    )
}
export default TopNavBar