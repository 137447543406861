import { FaGear } from "react-icons/fa6";
import { FaAngleLeft, FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
const FullPageHeader = () => {
  const selectedCourse=useSelector(state=>state.course.selectedCourse);

  return (
    <div className="cm-full-page-takeover-header full-page-takeover-header--row--22wJg full-page-takeover-header--header--yZv70">
      <div>
        <Link
          to="/instructor/courses"
          className="ud-btn ud-btn-small ud-btn-ghost ud-text-md full-page-takeover-header--back-btn--1vdpz"
          aria-label="Back to courses"
        >
          <FaAngleLeft
            aria-hidden="true"
            focusable="false"
            className="ud-icon ud-icon-medium"
          />
          <span
            aria-hidden="true"
            className="full-page-takeover-header--back-to-courses--nsJ3P"
          >
            Back to courses
          </span>
        </Link>
      </div>

      <div className="full-page-takeover-header--flex--2wYRF full-page-takeover-header--row--22wJg full-page-takeover-header--content--3dgJE">
        <div className="full-page-takeover-header--flex--2wYRF full-page-takeover-header--row--22wJg full-page-takeover-header--left--2LtYt">
          <h1
            className="ud-heading-lg full-page-takeover-header--title--x4F1h"
            data-purpose="course-title"
          >
            {selectedCourse.title}
          </h1>
          <div className="ud-badge ud-heading-xs full-page-takeover-header--status--2cjmy">
            {selectedCourse.status_label}
          </div>
          <span>
            <span>1min</span> of video content uploaded
          </span>
        </div>
        <div className="popper-module--popper--2BpLn">
          <button
            type="button"
            id="dropdown-trigger--715"
            aria-expanded="false"
            tabIndex="0"
            className="ud-btn ud-btn-small ud-btn-white-outline ud-heading-sm"
          >
            <span>Preview</span>
            {/* <svg  class="ud-icon ud-icon-xsmall"><use xlink:href="#icon-expand"></use></svg> */}
            <FaAngleDown
              aria-hidden="true"
              focusable="false"
              className="ud-icon ud-icon-xsmall"
            />
          </button>
          <div
            id="popper-content--717"
            aria-labelledby="dropdown-trigger--715"
            className="popper-module--popper-content--3cLBV"
            style={{ top: "100%", right: "0px", width: "20rem" }}
          >
            <div className="popper-module--animation-wrapper--2ogNt">
              <div
                className="dropdown-module--menu--2O7pL dropdown-module--dropdown-menu--3HbSn"
                style={{ maxHeight: "28rem" }}
              >
                <ul className="ud-unstyled-list ud-block-list">
                  <li>
                    <Link
                      to="/course/draft/5808754/learn/?instructorPreviewMode=instructor_v4"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-link"
                    >
                      <div className="ud-block-list-item-content">
                        As Instructor
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/course/draft/5808754/learn/?instructorPreviewMode=student_v4"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ud-btn ud-btn-large ud-btn-ghost ud-text-sm ud-block-list-item ud-block-list-item-small ud-block-list-item-link"
                    >
                      <div className="ud-block-list-item-content">
                        As Student
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Link
          className="ud-btn ud-btn-small ud-btn-ghost ud-heading-sm ud-btn-icon ud-btn-icon-small full-page-takeover-header--settings-btn--2HIwO"
          to="/instructor/course/5808754/manage/settings"
        >
          <FaGear
            aria-label="Course Settings"
            role="img"
            focusable="false"
            className="ud-icon ud-icon-medium"
          />
        </Link>
      </div>
    </div>
  );
};

export default FullPageHeader;
