import React from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';

import { registerLogo } from '../../assets';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { registerUserAction } from '../../appSlices/authSlice';
import { useEffect } from 'react';
import Button from '../Button';


import { FaEyeSlash, FaEye } from "react-icons/fa";

const RegistrationForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();


    const submitRegistrationForm = (data) => {
        dispatch(registerUserAction({ fullname: data.fullname, email: data.email, password: data.password })).then(res => {
            console.log('the resp is', res.data)
            navigate('/login');

        }).catch(error => {
            console.log(error)
        });



    }

    return (
        <div className=''>

            <div className='' style={{display:'flex'}}>
                <div className=''>
                    <div>
                        <h3>welcome to ILM</h3>
                    </div>
                    <img alt='registration-logo' src={registerLogo} width={400} height={400} />
                </div>

                <div className=''>

                    <div>
                        <div>
                            <div>
                                <h2 >create free account</h2>
                            </div>
                            <div>
                                <form onSubmit={handleSubmit(submitRegistrationForm)} className=''>
                                    <div className=''>
                                        <input {...register('fullname')} className='' placeholder='fullname' />
                                    </div>
                                    <div className=''>
                                        <input {...register('email')} className='' placeholder='email' type='email' />

                                    </div>
                                    <div className=''>
                                        <input {...register('password')} className='' placeholder='password' type='password' />

                                    </div>
                                    <div className=''>
                                        <input {...register('confirmPassword')} className='' type='password' placeholder='confirmPassword' />

                                    </div>
                                    <div>
                                        <button type='submit' className=''>register</button>
                                        <Link to='/login'>alredy have an account?</Link>
                                    </div>
                                </form>

                            </div>



                        </div>
                        <div>OR
                            <p>sign up with social media </p>
                            <button>gogle</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}




// function RegistrationForm() {

//     return (
//         <>
//             <div className='ud-app-loader ud-component--auth--app ud-app-loaded'>




//                 <div className="ud-container helpers--auth-container">
//                     <h2 id="auth-to-udemy-title" className="lp-heading-md helpers--auth-title---6vwg">Log in to your ilm account</h2>

//                     <form data-disable-loader="true" >
//                         <input type="hidden" name="locale" value="en_US" />
//                         <div className="helpers--auth-form-row">
//                             <div>
//                                 <div className="lp-compact-form-group lp-form-group">

//                                     <div data-testid="lp-compact-form-control-container"
//                                         className={`lp-compact-form-control-container ${false ? '' : 'lp-compact-form-control-container-active lp-compact-form-control-container-focus'}`}
//                                     >

//                                         <input aria-invalid="false"
//                                             name="email" minLength="7"
//                                             maxLength="64"
//                                             required=""
//                                             id="form-group--1"
//                                             type="email"
//                                             onFocus={()=>{}}
//                                             onBlur={()=>{}}
//                                             className="lp-text-input lp-text-input-large lp-text-md lp-compact-form-control"
//                                             value={false}
//                                             onChange={()=>{}}
//                                         />

//                                         <label htmlFor="form-group--1" className="lp-form-label lp-heading-sm">
//                                             <span className="lp-compact-form-label-content">
//                                                 <span className="lp-compact-form-label-text">Email</span>
//                                             </span>
//                                         </label>

//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="helpers--auth-form-row">
//                             <div>
//                                 <div className="lp-compact-form-group lp-form-group">

//                                     <div data-testid="lp-compact-form-control-container"
//                                         className={`lp-compact-form-control-container ${false? '' : 'lp-compact-form-control-container-active lp-compact-form-control-container-focus'}`}
//                                     >

//                                         <input aria-invalid="false"
//                                             name="email" minLength="7"
//                                             maxLength="64"
//                                             required=""
//                                             id="form-group--1"
//                                             type="email"
//                                             onFocus={()=>{}}
//                                             onBlur={()=>{}}
//                                             className="lp-text-input lp-text-input-large lp-text-md lp-compact-form-control"
//                                             value={false}
//                                             onChange={()=>{}}
//                                         />

//                                         <label htmlFor="form-group--1" className="lp-form-label lp-heading-sm">
//                                             <span className="lp-compact-form-label-content">
//                                                 <span className="lp-compact-form-label-text">Email</span>
//                                             </span>
//                                         </label>

//                                     </div>
//                                 </div>
//                             </div>
//                         </div>


//                         <div className="helpers--auth-form-row">
//                             <div className="lp-compact-form-group lp-form-group">

//                                 <div data-testid="lp-compact-form-control-container"
//                                     className={`lp-compact-form-control-container ${false ? '' : 'lp-compact-form-control-container-active lp-compact-form-control-container-focus'}`}

//                                 >
//                                     <input
//                                         onFocus={()=>{}}
//                                         onBlur={()=>{}}

//                                         aria-invalid="false" minLength="6" maxLength="64" name="password" required=""
//                                         id="form-group--3"
//                                         // type={passVisible ? 'text' : 'password'}
//                                         className="ud-text-input ud-text-input-large ud-text-md ud-compact-form-control password-form-group--password-input--yB71S "
//                                         value={''}
//                                         onChange={()=>{}}
//                                     />
//                                     {/* {password.length > 0 ? <Button type='button' onClick={() => setPasswordVisibility(!passVisible)} classname='lp-btn lp-btn-large lp-btn-link lp-heading-md password-form-group--icon--tZsyr'>
//                                         {passVisible ? <FaEyeSlash className='lp-icon lp-icon-small lp-icon-color-neutral' /> : <FaEye className='lp-icon lp-icon-small lp-icon-color-neutral' />}
//                                     </Button> : ''} */}

//                                     <label htmlFor="form-group--3" className="lp-form-label lp-heading-sm">
//                                         <span className="lp-compact-form-label-content">
//                                             <span className="lp-compact-form-label-text">Password</span>
//                                         </span>
//                                     </label>
//                                 </div>
//                             </div>
//                         </div>



//                         <button type="submit"
//                             className="lp-btn lp-btn-large lp-btn-brand lp-heading-md helpers--auth-submit-button">
//                             <span>Log in</span>
//                         </button>
//                     </form>
//                     <div className="helpers--auth-footer">
//                         <div className="lp-text-md">
//                             <span> or
//                                 <Link className="lp-text-bold lp-link-underline" href="/user/forgot-password/">Forgot Password</Link>
//                             </span>
//                         </div>
//                         <div className="helpers--auth-separator--oswiI"></div>
//                         <div className="ud-text-sm">
//                             <span>Don't have an account?
//                                 <Link className="ud-text-bold ud-link-underline"
//                                     to="/join/signup-popup/?locale=en_US&amp;next=https%3A%2F%2Fwww.udemy.com%2Flogout%2F&amp;response_type=html&amp;skip_suggest=1">Sign
//                                     up</Link>
//                             </span>
//                         </div>
//                         <div className="lp-text-sm"><a className="ud-text-bold ud-link-underline" href="/organization/global-login/"
//                             data-disable-loader="true">Log in with your organization</a>
//                         </div>
//                     </div>
//                 </div>
//             </div>


//             {/* <div className=''>
//       <div className=''>
//         <img className='' src={ilm} alt='logo' />
//       </div>
//       <div className=''>
//         <div className=''>
//           <form onSubmit={handleSubmit(submitLoginForm)}>
//             <p className=''>Login with learningtool</p>

//             <input
//               {...register('email')}
//               type="email"
//               placeholder="email"
//               className=''
//             />
//             <input
//               {...register('password')}
//               type="password"
//               placeholder="password"
//               className=''
//             />
//             <button type="submit" className=''>Login</button>
//             <Link to='/forgetpassword'>forget password</Link>
//             <div style={{ textAlign: 'center' }} className='' >
//               OR
//             </div>
//             <div className=''>
//               <Link to={getGoogleOAuthURL}>
//                 <img height={84} src={google} alt='google-logo' />
//               </Link>

//             </div>
//           </form>
//         </div>
//       </div>
//     </div> */}
//         </>
//     )
// }
export default RegistrationForm